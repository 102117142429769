export default {
  CheckoutCustomIcons2: require('../assets/images/CheckoutCustomIcons2.png'),
  CheckoutCustomIcons3: require('../assets/images/CheckoutCustomIcons3.png'),
  CheckoutCustomIcons1: require('../assets/images/CheckoutCustomIcons1.png'),
  JulianWanWNoLnJo7tS8Unsplash: require('../assets/images/JulianWanWNoLnJo7tS8Unsplash.jpg'),
  Rectangle2899: require('../assets/images/Rectangle2899.png'),
  DirectUp: require('../assets/images/DirectUp.png'),
  Group: require('../assets/images/Group.png'),
  UploadAvatar: require('../assets/images/UploadAvatar.png'),
  EmptyProfilePicture: require('../assets/images/EmptyProfilePicture.png'),
  Frame347: require('../assets/images/Frame347.png'),
  HomeCityOutline: require('../assets/images/HomeCityOutline.png'),
  CheckDecagramOutline: require('../assets/images/CheckDecagramOutline.png'),
  CogOutline: require('../assets/images/CogOutline.png'),
  GoogleAnalytics: require('../assets/images/GoogleAnalytics.png'),
  MessageBadgeOutline: require('../assets/images/MessageBadgeOutline.png'),
  LaptopAccount: require('../assets/images/LaptopAccount.png'),
  HomeOutline: require('../assets/images/HomeOutline.png'),
};
