import React from 'react';
import * as SupabaseRESTAPIAuthOptionalApi from '../apis/SupabaseRESTAPIAuthOptionalApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import * as CustomPackages from '../custom-files/CustomPackages';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  CircleImage,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Text, View, useWindowDimensions } from 'react-native';

const PublicProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const checkRes = res => {
    if (res?.length) return false;
    else CustomPackages.Linking.openURL('https://app.cityasaschool.com/');
  };

  const { theme } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const profiles =
          await SupabaseRESTAPIAuthOptionalApi.getProfileByHandleGET(
            Constants,
            { handel: props.route?.params?.handle ?? 'dbmarufpro' }
          );
        if (checkRes(profiles)) {
          return;
        }

        const valuehCc58CCb = profiles && profiles[0];
        setProfileData(valuehCc58CCb);
        const profile = valuehCc58CCb;
        setProfileImage(profileData?.profile_picture);
        if ((profiles && profiles[0])?.profile_picture) {
          return;
        }
        setProfileImage(getNameAvatarUrl(profiles && profiles[0]));
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [profileData, setProfileData] = React.useState({});
  const [profileImage, setProfileImage] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer scrollable={true} hasSafeArea={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 40, marginTop: 40 },
            dimensions.width
          )}
        >
          {/* Public Profile */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 0, marginRight: 16, marginTop: 40, width: '50%' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 24,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Public Profile'}
            </Text>
            {/* Upload Image */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 64, height: 84, marginTop: 16, width: 84 },
                dimensions.width
              )}
            >
              {/* Uploaded Data */}
              <>
                {!profileImage ? null : (
                  <CircleImage
                    style={StyleSheet.applyWidth(
                      { height: 84, width: 84 },
                      dimensions.width
                    )}
                    source={{ uri: `${profileImage}` }}
                    size={64}
                  />
                )}
              </>
            </View>
            {/* Calendly Url */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', marginTop: 16 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '600',
                  },
                  dimensions.width
                )}
              >
                {'Calendly URL:'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                  },
                  dimensions.width
                )}
              >
                {' '}
                {profileData?.calendly_url}
              </Text>
            </View>
            {/* Name */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* First Name */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'First Name: '}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '400',
                    },
                    dimensions.width
                  )}
                >
                  {profileData?.firstName}
                </Text>
              </View>
              {/* Last Name */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Last Name: '}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '400',
                    },
                    dimensions.width
                  )}
                >
                  {profileData?.lastName}
                </Text>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Location: '}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '400',
                    },
                    dimensions.width
                  )}
                >
                  {profileData?.location}
                </Text>
              </View>
              {/* handle */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Handle: '}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 16,
                      fontWeight: '400',
                    },
                    dimensions.width
                  )}
                >
                  {profileData?.handle}
                </Text>
              </View>
            </View>
            {/* Short Bio */}
            <View
              style={StyleSheet.applyWidth(
                { marginTop: 16, width: '100%' },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '600',
                  },
                  dimensions.width
                )}
              >
                {'Short Bio:'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                  },
                  dimensions.width
                )}
              >
                {profileData?.short_bio}
              </Text>
            </View>
            {/* About me */}
            <View
              style={StyleSheet.applyWidth(
                { marginTop: 16, width: '100%' },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '600',
                  },
                  dimensions.width
                )}
              >
                {'About me'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                  },
                  dimensions.width
                )}
              >
                {profileData?.about_me}
              </Text>
            </View>
          </View>
          {/* Help */}
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: 16, marginTop: 16, paddingLeft: 0 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 24,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Help'}
            </Text>
            {/* Contact Us Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://go.crisp.chat/chat/embed/?website_id=5da7a8de-200b-43f5-80df-d44a951658f2'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Contact Us'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
            {/* Report Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://airtable.com/shrBGs9tyPUfrRGLX'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Report Inappropriate or Concerning Behaviour'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
            {/* Request Features Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Request New Features'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PublicProfileScreen);
