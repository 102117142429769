import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseEdgeFunctionsApi from '../apis/SupabaseEdgeFunctionsApi.js';
import * as SupabaseRESTAPIAuthOptionalApi from '../apis/SupabaseRESTAPIAuthOptionalApi.js';
import * as SupabaseRestAPIApi from '../apis/SupabaseRestAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import * as DebouncedInput from '../custom-files/DebouncedInput';
import arrayItemsJoin from '../global-functions/arrayItemsJoin';
import copyToClipboard from '../global-functions/copyToClipboard';
import createSubjectList from '../global-functions/createSubjectList';
import nullify from '../global-functions/nullify';
import searchLearningCenters from '../global-functions/searchLearningCenters';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import {
  Button,
  DatePicker,
  Divider,
  Icon,
  IconButton,
  NumberInput,
  Picker,
  ScreenContainer,
  Spacer,
  StarRating,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CreateUpdateALearningExperienceScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const isUpcomingSeries = series => {
    return (
      series?.sessions?.length === 0 ||
      series?.sessions?.some(sess => !isPastSession(sess))
    );
  };

  const toggleIField = field => {
    setFormObj(oldObj => {
      const oldVal = oldObj[`${field}`];
      // console.log(oldObj)
      // return JSON.parse(JSON.stringify(oldObj))
      return { ...oldObj, [field]: !oldVal };
    });
  };

  const getNow = () => {
    return new Date();
  };

  const addMultidaySession = seriesId => {
    setFormObj(old => {
      // find the series
      // let serI= seriesId-1
      const oldSeries = old.series.find(
        ({ series_no }) => series_no === seriesId
      );
      // console.log(old, seriesId)
      const id = CustomCode.uuidv4();
      oldSeries.sessions.push({
        id,
        start_time: new Date(),
        lxp_id: formObj.id.value,
      });

      setEditableOneTimeSession(id);
      console.log(oldSeries.sessions);

      // console.log(old)
      const newObj = {
        ...old,
        series: old.series.map(ser => {
          if (ser.series_no === seriesId) return { ...oldSeries };
          return { ...ser };
        }),
      };
      console.log(newObj.series);
      return newObj;
    });
    // add a new session
  };

  const checkForChange = async payload => {
    try {
      await SupabaseEdgeFunctionsApi.checkToSendEmailAboutUpdateLXPPOST(
        Constants,
        {
          payload,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  const showShareableLink = (formObj, mode) => {
    return (
      mode !== 'Create' &&
      !formObj.archived &&
      formObj.status?.value === 'active'
    );
  };

  const getStatusForArchive = item => {
    if (item.status.value) return '';
  };

  // prepare the picker label
  const getLXPStatus = () => {
    return formObj.archived ? 'archived' : formObj.status?.value;
  };

  const validateSettingTab = () => {
    var hasError = false;

    setFormObj(oldObj => {
      // console.log(oldObj)
      // let newObj = {}
      for (let [key, obj] of Object.entries(oldObj)) {
        let val = obj.value;
        // console.log(key)

        switch (key) {
          case 'ageRangeMin': {
            if (val < 5) {
              oldObj[key].error = 'Min age should be at least 5 year.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }

          case 'ageRangeMax': {
            if (val < oldObj.ageRangeMin.value) {
              oldObj[key].error = 'Max age should be greater than min age.';
              hasError = true;
            } else if (val - oldObj.ageRangeMin.value > 4) {
              oldObj[key].error = 'Age difference should be less than 5 year.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }

          case 'capacityRangeMin': {
            if (val < 1) {
              oldObj[key].error = 'Min can not be zero.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }

          case 'capacityRangeMax': {
            // console.log(val, oldObj.capacityRangeMin.value, val <= oldObj.capacityRangeMin.value)
            if (val <= oldObj.capacityRangeMin.value) {
              oldObj[key].error = 'Max should be larger than the min.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }

            break;
          }
        }
        // if (key !== 'series')
        // newObj = {...newObj, [key]: {...obj}}
      }
      console.log(oldObj);
      return { ...oldObj };
      // setFormObj(newObj)
    });

    return hasError;
    // return newObj
  };

  const parseIdsToCheckSeriesChange = sessions => {
    console.log({ lxp_id: sessions[0].lxp_id, sessions });
    return { lxp_id: sessions[0].lxp_id, sessions };
  };

  const validateDetailsTab = () => {
    var hasError = false;

    setFormObj(oldObj => {
      // let newObj = {}
      for (let [key, obj] of Object.entries(oldObj)) {
        let val = obj.value;

        switch (key) {
          case 'subject': {
            if (val.length < 1) {
              oldObj[key].error = 'Subject is a required field.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }

          case 'description': {
            if (val.length < 50) {
              oldObj[key].error =
                'Description should be at least 50 characters long.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }

          case 'explainVideo': {
            // validation for explain video
          }
        }
      }
      console.log(oldObj);

      return { ...oldObj };

      // setFormObj(newObj)
    });

    return hasError;
    // return newObj
  };

  const updateSeriesDuration = (seriesId, mins) => {
    setFormObj(old => {
      return {
        ...old,
        series: old.series.map(ser => {
          if (ser.series_no === (seriesId || 1))
            return { ...ser, durationPerSession: mins };
          return { ...ser };
        }),
      };
    });
  };

  const stopCreatingOneTimeSession = () => {
    // console.log(formObj?.series[0]?.sessions.length > 0, !!editableOneTimeSession)
    return formObj?.series[0]?.sessions.length > 0 && !!editableOneTimeSession;
  };

  const editOneTimeSession = (session_id, field, value) => {
    setFormObj(formObj => {
      let editableSession = getEditableOneTimeSession();
      // console.log(editableSession)
      const newSession = { ...editableSession, [field]: value };

      const newSeries = {
        ...formObj.series[0],
        sessions: [
          ...formObj.series[0].sessions.filter(({ id }) => id !== session_id),
          newSession,
        ],
      };

      const newForm = { ...formObj, series: [newSeries] };
      // console.log(newForm)
      return newForm;
    });
  };

  const isPastSeries = series => {
    return series?.sessions.some(sess => isPastSession(sess));
  };

  const setFieldValue = (value, field, form) => {
    // console.log({...form, [field]: {error: "", value}})
    return { ...form, [field]: { error: '', value } };
  };

  const getActualPricing = (Variables, value) => {
    return value * Variables.FEE_WITH_CAAS_CUT;
  };

  const isInvalidTabIndex = currTabI => {
    // console.log(currTabI, topTabI)
    return mode == 'Create' && currTabI > topTabI;
  };

  const addNewOneTimeSession = () => {
    setFormObj(formObj => {
      const id = CustomCode.uuidv4();
      setEditableOneTimeSession(id);
      // console.log({
      //     ...formObj,
      //     series: [{...formObj.series[0],
      //     sessions: [
      //         ...formObj.series[0].sessions,
      //         {
      //             id,
      //             start_time: new Date(), error: "", duration: 0,
      //             lxp_id: formObj?.id?.value
      //         }
      //     ]

      // }]})
      return {
        ...formObj,
        series: [
          {
            ...formObj.series[0],
            sessions: [
              ...formObj.series[0].sessions,
              {
                id,
                start_time: new Date(),
                error: '',
                duration: 0,
                lxp_id: formObj?.id?.value,
              },
            ],
          },
        ],
      };
      // let existingSessions = formObj.series[0].sessions
      // // existingSessions.push()
      // // console.log('existing sessions', existingSessions)
      // let id = CustomCode.uuidv4()
      // let newSeries
      // if (existingSessions.length){
      //     newSeries = {...formObj.series[0], sessions: [...formObj.series[0].sessions, {
      //     id,
      //     start_time: new Date(), error: "", duration: 0,
      //     lxp_id: formObj.id.value
      //      } ] }
      // }else{
      //     existingSessions.push( {
      //     id,
      //     start_time: new Date(), error: "", duration: 0,
      //     lxp_id: formObj.id.value
      //      })
      //     newSeries = {...formObj.series[0], sessions: [...existingSessions]}
      // }

      // const newForm =  {...formObj, series: [newSeries] }
      // // console.log(newForm)
      // setEditableOneTimeSession(id)
      // return newForm
    });
  };

  const isLXPDraftable = () => {
    return (
      mode === 'Create' &&
      formObj.title?.value.length > 0 &&
      formObj.coverImage?.value.length > 0
    );
  };

  const validateScheduleTab = () => {
    let hasError = false;
    console.log(formObj);

    // if (formObj.series.some((ser)=>!ser.sessions.length)){
    //     setScheduleTabError("No Session found.")
    //     hasError = true
    // }
    // else
    //     setScheduleTabError("")
    if (!hasError) {
      if (formObj.pricing_per_session.value >= 0) {
        setScheduleTabError('');
      } else {
        setScheduleTabError('Session price can not be negative.');
        hasError = true;
      }
    }

    if (!formObj.isOneTime) {
      // validate multiday
      if (!hasError) {
        if (formObj.pricing_per_series.value >= 0) {
          setScheduleTabError('');
        } else {
          setScheduleTabError('Series price can not be negative.');
          hasError = true;
        }
      }
      // if (!hasError){
      //     // if (formObj.pricing_per_series.value > formObj.pricing_per_session.value){
      //     //     setScheduleTabError("")
      //     // }else{
      //     //     setScheduleTabError("Series price can not be less than session price.")
      //     //     hasError = true
      //     // }
      // }
    }
    // if (!hasError){

    //     // remove the error key from the sessions
    //     setFormObj(oldObj=>{

    //         oldObj.series[0].sessions = oldObj.series[0].sessions.map(({id, start_time, duration})=> ({id, lxp_id: oldObj.id.value, start_time, duration}))
    //         // console.log(oldObj)
    //         return {...oldObj}
    //     })

    // }
    // console.log('hasError', hasError)
    return hasError;
  };

  const isPastSession = item => {
    // console.log(
    //     item?.start_time,
    //     CustomCode.moment(item?.start_time).diff(CustomCode.moment(), 'seconds'),
    //     CustomCode.moment(item?.start_time).diff(CustomCode.moment(), 'seconds') < 0
    //     )

    return (
      item.id !== editableOneTimeSession &&
      CustomCode.moment(item?.start_time).diff(CustomCode.moment(), 'seconds') <
        0
    );
  };

  const isEditing = () => {
    return mode === 'Edit' || !isLXPDraftable();
  };

  // sort the sessions by start_time.
  const sortMultidaySessions = series_id => {
    try {
      setFormObj(oldObj => {
        // let seriesToSort = oldObj.series.find(ser=> ser.series_no == series_id)
        // console.log("sort multiday", oldObj.series)
        oldObj.series = oldObj.series?.map(ser => {
          if (ser.series_no == series_id) {
            ser.sessions.sort(
              (a, b) =>
                new Date(a.start_time).getTime() -
                new Date(b.start_time).getTime()
            );
          }
          return ser;
        });
        return { ...oldObj };
      });
    } catch (e) {
      console.log('error in sort multiday');
    }
  };

  const getActualValue = (Variables, price) => {
    return (parseFloat(price) * Variables.FEE_WITH_CAAS_CUT).toFixed(2);
  };

  const stopCreatingSession = () => {
    return formObj.series.some(ser => ser.sessions.length === 0);
  };

  const getFormattedDate = item => {
    // const newDateObj = new Date(item.start_time)
    return CustomCode.moment(item.start_time).format('Do MMM YYYY');
    // return `${newDateObj.getDate()}/${newDateObj.getMonth()+1}/${newDateObj.getFullYear()}`
  };

  const validateSeries = () => {
    let foundError = false;

    setFormObj(oldForm => {
      console.log({
        ...oldForm,
        series: oldForm.series.map(ser => {
          if (!isOneTime) {
            if (!ser.sessions.length) {
              ser.error = "Can't have series with no sessions.";
              foundError = true;
            } else if (ser.durationPerSession < 1) {
              ser.error = 'Class time should be at least 1 minute.';
              foundError = true;
            } else ser.error = '';
          }

          return {
            ...ser,
            sessions: ser.sessions.map(ses => {
              if (!ses.start_time) {
                ses.error = "Class time can't be empty.";
                foundError = true;
              } else if (ses.duration < 1) {
                ses.error = 'Class time should be at least 1 minutes.';
                foundError = true;
              } else {
                ses.error = '';
              }

              return { ...ses };
            }),
          };
        }),
      });
      return {
        ...oldForm,
        series: oldForm.series.map(ser => {
          if (!isOneTime) {
            if (!ser.sessions.length) {
              ser.error = "Can't have series with no sessions.";
              foundError = true;
            } else if (ser.durationPerSession < 1) {
              ser.error = 'Class time should be at least 1 minute.';
              foundError = true;
            } else ser.error = '';
          }

          return {
            ...ser,
            sessions: ser.sessions.map(ses => {
              if (!ses.start_time) {
                ses.error = "Class time can't be empty.";
                foundError = true;
              } else if (ses.duration < 1) {
                ses.error = 'Class time should be at least 1 minutes.';
                foundError = true;
              } else {
                ses.error = '';
              }

              return { ...ses };
            }),
          };
        }),
      };
    });

    console.log('found error', foundError);

    return foundError;
  };

  const deleteAnOneTimeSession = id => {
    setFormObj(formObj => {
      const newSeries = {
        ...formObj.series[0],
        sessions: [
          ...formObj.series[0].sessions.filter(({ id: iId }) => id != iId),
        ],
      };
      const newForm = { ...formObj, series: [newSeries] };
      // console.log(newForm)
      return newForm;
    });
  };

  const uploadImage = async string => {
    // console.log(string)
    if (string.includes('https') || !string.trim().length) return string.trim();
    const byteNumbers = new Array(string.length);

    const type = string.split(';')[0].split(':')[1];

    function _base64ToArrayBuffer(base64) {
      var binary_string = CustomCode.decode(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    const { data, error } = await CustomCode.supabase.storage
      .from('lxpcovers')
      .upload(
        'lxpcovers' + Date.now() + '.' + type.split('/')[1],
        _base64ToArrayBuffer(string.split(';')[1].split(',')[1]),
        {
          contentType: type,
        }
      );

    console.log(data, error);

    return (
      'https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/' +
      data.Key
    );
  };

  const createShareableLxpLink = (Variables, lxpId) => {
    return `${Variables.LEARNER_APP_URL}/lxp_details/${lxpId}`;
  };

  const getRangeError = field => {
    if (field == 'age') {
      return formObj.ageRangeMin?.error || formObj.ageRangeMax?.error;
    } else if ((field = 'load')) {
      return formObj.capacityRangeMin?.error || formObj.capacityRangeMax?.error;
    }
  };

  const logSeries = () => {
    return JSON.stringify(formObj.series);
  };

  const deleteASeries = seriesId => {
    let deletingSessionIds = formObj.series
      .find(({ series_no }) => series_no === seriesId)
      .sessions.map(item => item.id);

    setFormObj(old => {
      return {
        ...old,
        series: old.series.filter(({ series_no }) => series_no !== seriesId),
      };
    });

    return deletingSessionIds;
  };

  const updateFormField = (field, value) => {
    console.log(field, value);

    setFormObj(old => ({
      ...old,
      [field]: { error: '', value },
    }));
  };

  const updateMultidaySessionStartTime = (seriesId, sessionId, value) => {
    console.log(seriesId, sessionId, value);
    setFormObj(old => {
      // find the series

      let series = old.series.map(ser => {
        if (ser.series_no === (seriesId || 1)) {
          console.log('changed series');
          let newSessions = ser.sessions.map(ses => {
            if (ses.id === sessionId) {
              ses.start_time = new Date(value);
              console.log('changed session time');
            }
            return { ...ses };
          });
          console.log('new session', newSessions);
          return { ...ser, sessions: [...newSessions] };
        }
        return { ...ser };
      });

      console.log('final series', series);

      console.log({ ...old, series: [...series] });
      return { ...old, series: [...series] };
    });
  };

  const getEditableOneTimeSession = () => {
    // console.log('get', formObj.series, editableOneTimeSession)
    return formObj.series[0].sessions.find(
      ({ id }) => id === editableOneTimeSession
    );
  };

  const addNewSeries = () => {
    let lastSeries = {};

    setFormObj(oldObj => {
      // console.log({...oldObj, series: [...oldObj.series, {id: oldObj.series.length+1, sessions: [], durationPerSession: 0, error: ""}]})
      lastSeries = oldObj.series.slice(-1)[0];
      // console.log(lastSeries)
      return {
        ...oldObj,
        series: [
          ...oldObj.series,
          {
            series_no: lastSeries?.series_no + 1 || 1,
            sessions: [],
            durationPerSession: 0,
            error: '',
          },
        ],
      };
    });

    setEditableSeries(lastSeries?.series_no + 1 || 1);
  };

  const showDraftButton = () => {
    // console.log(!isSubmitting)
    // console.log(mode, formObj.title, formObj.coverImage)

    // let ret = ((mode === 'Create') && formObj.title.value.length > 0 && formObj.coverImage.value.length > 0)
    // console.log(ret)

    return !isSubmitting && isLXPDraftable();
  };

  const deleteMultidaySession = (seriesId, sessionId) => {
    setFormObj(old => {
      // const serI = seriesId - 1
      // let oldSeries = old.series.find(({series_no})=> series_no === seriesId)
      // console.log(seriesId, old.series, oldSeries)

      // oldSeries.sessions = oldSeries.sessions.filter(({id})=> id !== sessionId)

      console.log(seriesId, sessionId);

      console.log({
        ...old,
        series: old.series.map(ser => {
          if (ser.series_no === seriesId)
            return {
              ...ser,
              sessions: ser.sessions.filter(({ id }) => id !== sessionId),
            };
          return { ...ser };
        }),
      });

      return {
        ...old,
        series: old.series.map(ser => {
          if (ser.series_no === seriesId)
            return {
              ...ser,
              sessions: ser.sessions.filter(({ id }) => id !== sessionId),
            };
          return { ...ser };
        }),
      };
    });
  };

  // reset series when the LXP type changes.
  const resetSeries = () => {
    setFormObj(old => ({
      ...old,
      series: [
        { series_no: 1, sessions: [], error: '', durationPerSession: 0 },
      ],
    }));

    setEditableSeries(1);
  };

  const validateGeneralTab = () => {
    var hasError = false;

    setFormObj(oldObj => {
      // console.log(oldObj)
      // let newObj = {}
      for (let [key, obj] of Object.entries(oldObj)) {
        let val = obj?.value || '';
        // if (!val) val = ''

        switch (key) {
          case 'title': {
            if (val.length < 3) {
              oldObj[key].error = 'Title must be at least 3 charecters long.';
              hasError = true;
            } else {
              oldObj[key].error = '';
            }
            break;
          }
          case 'zoom_url': {
            console.log('checking zoom_url');
            if (oldObj?.location_type?.value == 'zoom') {
              let zoomLinkRegex =
                /^https?:\/\/(?:www\.)?(?:zoom\.(?:us|com|gov)|[\w\d]+.zoom\.us)\/(?:j\/)?\d{9,}(?:\?[\w=-]+)?(?:#\w+)?$/;
              if (zoomLinkRegex.test(val)) {
                console.log('valid zoom link!');
                oldObj[key].error = '';
              } else {
                console.log('invalid zoom link!');
                hasError = true;
                oldObj[key].error = 'This is not a valid zoom meeting link.';
              }
            }

            break;
          }
          case 'zoom_id':
          case 'coverImage': {
            if (oldObj?.location_type?.value == 'zoom') {
              if (val.length < 1) {
                oldObj[key].error = 'It is a required field.';
                hasError = true;
              } else {
                oldObj[key].error = '';
              }
            }
            break;
          }
        }
        // if (key !== 'series')
        // newObj = {...newObj, [key]: {...obj}}
      }
      console.log(oldObj);
      return { ...oldObj };
      // setFormObj(newObj)
    });

    return hasError;
    // return newObj
  };

  // it is able to change the status field or the archived field depending on the value selected
  const setLXPStatus = status => {
    if (status === 'archived')
      setFormObj(prev => {
        console.log('new form obj', { ...prev, archived: true });
        return { ...prev, archived: true };
      });
    else
      setFormObj(prev => {
        console.log('new form obj', {
          ...prev,
          status: { value: status, error: '' },
        });
        return {
          ...prev,
          status: { value: status, error: '' },
          archived: false,
        };
      });
  };

  const stopForApiRes = res => {
    console.log('stopForApiRes', res);
    return res && (res?.msg || res?.message);
  };

  const getFormattedTime = item => {
    // const newDateObj = new Date(item.start_time)
    return CustomCode.moment(item.start_time).format('hh:mm A');
    // return `${String(newDateObj.getHours()).padStart(2, '0')}:${String(newDateObj.getMinutes()).padStart(2, '0')}`
  };

  const showArchiveUnarchiveButton = () => {
    return mode === 'Edit' && !isSubmitting;
  };

  const parseSessionsForApiCall = () => {
    const ret = [];

    formObj.series.map(ser => {
      ser.sessions.map(({ id, start_time, duration }) => {
        // console.log(duration)
        ret.push({
          id,
          series_no: ser.series_no,
          start_time,
          duration: isOneTime ? duration : ser.durationPerSession,
          lxp_id: formObj.id?.value,
          updated_at: CustomCode.moment(),
        });
      });
    });

    console.log('parse sessions', ret);

    return ret;
  };

  const { theme } = props;
  const { navigation } = props;

  const supabaseRestAPIRequest$UpdateALXPForListingPOST =
    SupabaseRestAPIApi.useRequest$UpdateALXPForListingPOST();
  const supabaseRestAPIAddCoverImageToLXPPATCH =
    SupabaseRestAPIApi.useAddCoverImageToLXPPATCH();
  const supabaseRestAPIAddSessionsPOST =
    SupabaseRestAPIApi.useAddSessionsPOST();
  const supabaseRestAPIDeleteSessionDELETE =
    SupabaseRestAPIApi.useDeleteSessionDELETE();
  const supabaseRestAPIDeleteMultipleSessionsDELETE =
    SupabaseRestAPIApi.useDeleteMultipleSessionsDELETE();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setFetchingSubjects(true);
        const subjects = await SupabaseRESTAPIAuthOptionalApi.getSubjectsGET(
          Constants
        );
        setGlobalVariableValue({
          key: 'SUBJECTS',
          value: createSubjectList(subjects),
        });
        setFetchingSubjects(false);
        console.log(props.route?.params?.initialFormObj ?? {});
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [actPrice, setActPrice] = React.useState(0);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [deleteSeriesIntent, setDeleteSeriesIntent] = React.useState(false);
  const [deletingSeriesId, setDeletingSeriesId] = React.useState('');
  const [editableOneTimeSession, setEditableOneTimeSession] =
    React.useState('');
  const [editableSeries, setEditableSeries] = React.useState('');
  const [fetchingSubjects, setFetchingSubjects] = React.useState(false);
  const [formObj, setFormObj] = React.useState(
    props.route?.params?.initialFormObj ?? {}
  );
  const [isDeletingSeries, setIsDeletingSeries] = React.useState(false);
  const [isOneTime, setIsOneTime] = React.useState(
    (props.route?.params?.initialFormObj ?? {})?.isOneTime
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isVirtual, setIsVirtual] = React.useState(true);
  const [learning_centers, setLearning_centers] = React.useState([
    {
      id: '80055706-58935045-132919-186341-33890457605',
      URL: 'https://www.google.com/maps/place/Us%26Co+Monument+Flexible+Workspaces+%26+Meeting+Rooms/data=!4m6!3m5!1s0x4876034e05eda067:0x20288154adead31d!8m2!3d51.5093232!4d-0.0814818!16s%2Fg%2F11cs6bm80y?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Us%26Co+Monument+Flexible+Workspaces+%26+Meeting+Rooms/data=!4m6!3m5!1s0x4876034e05eda067:0x20288154adead31d!8m2!3d51.5093232!4d-0.0814818!16s%2Fg%2F11cs6bm80y?authuser=0&hl=en&rclk=1',
      name: 'Us&Co Monument Flexible Workspaces & Meeting Rooms',
      phone: '+44 20 3031 3535',
      rating: 5,
      address: '7 Harp Ln, London EC3R 6DP, United Kingdom',
      reviews: '26',
      website: 'usandco.com',
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 9AM to 6PM; Tuesday, 9AM to 6PM; Wednesday, 9AM to 6PM; Thursday, 9AM to 6PM; Friday, 9AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '69018124-58339854-258156-550793-60132677409',
      URL: 'https://www.google.com/maps/place/Co-Work+Moorgate/data=!4m6!3m5!1s0x48761cae8b7ea695:0x2021520943a2daa!8m2!3d51.5215003!4d-0.0854328!16s%2Fg%2F11b5yly71v?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Co-Work+Moorgate/data=!4m6!3m5!1s0x48761cae8b7ea695:0x2021520943a2daa!8m2!3d51.5215003!4d-0.0854328!16s%2Fg%2F11b5yly71v?authuser=0&hl=en&rclk=1',
      name: 'Co-Work Moorgate',
      phone: '+44 20 7183 1389',
      rating: 5,
      address: '3rd Floor, 26 Finsbury Square, London EC2A 1DS, United Kingdom',
      reviews: '1',
      website: 'co-work.co',
      latitude: 51.5215003,
      longitude: -0.0854328,
      is_claimed: false,
      opening_hours:
        'Sunday, Open 24 hours; Monday, Open 24 hours; Tuesday, Open 24 hours; Wednesday, Open 24 hours; Thursday, Open 24 hours; Friday, Open 24 hours; Saturday, Open 24 hours. Hide open hours for the week',
    },
    {
      id: '29685474-53071797-844909-682797-26202468343',
      URL: 'https://www.google.com/maps/place/Us%26Co+Stratford+Flexible+Workspaces+%26+Meeting+Rooms/data=!4m6!3m5!1s0x48761dfe0d3e3c6f:0x6fb801fa93a46690!8m2!3d51.5374753!4d-0.0005083!16s%2Fg%2F11j087x_x_?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Us%26Co+Stratford+Flexible+Workspaces+%26+Meeting+Rooms/data=!4m6!3m5!1s0x48761dfe0d3e3c6f:0x6fb801fa93a46690!8m2!3d51.5374753!4d-0.0005083!16s%2Fg%2F11j087x_x_?authuser=0&hl=en&rclk=1',
      name: 'Us&Co Stratford Flexible Workspaces & Meeting Rooms',
      phone: '+44 20 3827 7650',
      rating: 4.9,
      address: '11 Burford Rd, London E15 2ST, United Kingdom',
      reviews: '26',
      website: 'usandco.com',
      latitude: 41.1845415,
      longitude: -73.1331651,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 9AM to 6PM; Tuesday, 9AM to 6PM; Wednesday, 9AM to 6PM; Thursday, 9AM to 6PM; Friday, 9AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '87772199-16076761-375277-335175-65006626118',
      URL: 'https://www.google.com/maps/place/Co-Work+Cannon+Street/data=!4m6!3m5!1s0x487604aa88d8a58d:0x24aa133f0f475f7a!8m2!3d51.5128459!4d-0.0947674!16s%2Fg%2F11btwshv6n?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Co-Work+Cannon+Street/data=!4m6!3m5!1s0x487604aa88d8a58d:0x24aa133f0f475f7a!8m2!3d51.5128459!4d-0.0947674!16s%2Fg%2F11btwshv6n?authuser=0&hl=en&rclk=1',
      name: 'Co-Work Cannon Street',
      phone: '+44 20 7183 1389',
      rating: 4,
      address: '4th Floor, 33 Cannon St, London EC4M 5SB, United Kingdom',
      reviews: '2',
      website: 'co-work.co',
      latitude: 51.5128459,
      longitude: -0.0947674,
      is_claimed: false,
      opening_hours:
        'Sunday, Open 24 hours; Monday, Open 24 hours; Tuesday, Open 24 hours; Wednesday, Open 24 hours; Thursday, Open 24 hours; Friday, Open 24 hours; Saturday, Open 24 hours. Hide open hours for the week',
    },
    {
      id: '51059123-59477591-738075-236571-99862102312',
      URL: 'https://www.google.com/maps/place/The+Co-Dalston/data=!4m6!3m5!1s0x48761ddbc30e1953:0x72686d6232117e55!8m2!3d51.5457321!4d-0.0755658!16s%2Fg%2F11g4c2jf4m?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/The+Co-Dalston/data=!4m6!3m5!1s0x48761ddbc30e1953:0x72686d6232117e55!8m2!3d51.5457321!4d-0.0755658!16s%2Fg%2F11g4c2jf4m?authuser=0&hl=en&rclk=1',
      name: 'The Co-Dalston',
      phone: '+44 20 7684 2972',
      rating: 4.9,
      address: '584 Kingsland Rd, London E8 4AH, United Kingdom',
      reviews: '32',
      website: 'thecodalston.com',
      latitude: 51.5457321,
      longitude: -0.0755658,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 9AM to 6PM; Tuesday, 9AM to 6PM; Wednesday, 9AM to 6PM; Thursday, 9AM to 6PM; Friday, 9AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '75741850-21836419-247821-741689-52628410489',
      URL: 'https://www.google.com/maps/place/Co-Work/data=!4m6!3m5!1s0x48761b2ac9181bb1:0x3f253300b8bfc7a6!8m2!3d51.5153883!4d-0.1391085!16s%2Fg%2F11c6q85st0?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Co-Work/data=!4m6!3m5!1s0x48761b2ac9181bb1:0x3f253300b8bfc7a6!8m2!3d51.5153883!4d-0.1391085!16s%2Fg%2F11c6q85st0?authuser=0&hl=en&rclk=1',
      name: 'Co-Work',
      phone: '+44 20 7183 1389',
      rating: 4.7,
      address: '6 Ramillies St, London W1F 7TY, United Kingdom',
      reviews: '3',
      website: 'co-work.co',
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours:
        'Sunday, Open 24 hours; Monday, Open 24 hours; Tuesday, Open 24 hours; Wednesday, Open 24 hours; Thursday, Open 24 hours; Friday, Open 24 hours; Saturday, Open 24 hours. Hide open hours for the week',
    },
    {
      id: '43774942-22920152-977587-407084-44489770276',
      URL: "https://www.google.com/maps/place/Meeson's+Wharf+-+Co-working+Space/data=!4m6!3m5!1s0x48761d54ec9d98c3:0x8d594e0b413d5625!8m2!3d51.5297821!4d-0.0128497!16s%2Fg%2F11grprckwd?authuser=0&hl=en&rclk=1",
      City: 'London',
      link: "https://www.google.com/maps/place/Meeson's+Wharf+-+Co-working+Space/data=!4m6!3m5!1s0x48761d54ec9d98c3:0x8d594e0b413d5625!8m2!3d51.5297821!4d-0.0128497!16s%2Fg%2F11grprckwd?authuser=0&hl=en&rclk=1",
      name: "Meeson's Wharf - Co-working Space",
      phone: null,
      rating: 5,
      address: 'Meesons Wharf, 1 High St, London E15 2NA, United Kingdom',
      reviews: '1',
      website: null,
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours: null,
    },
    {
      id: '64038834-62727682-143297-355148-76160959883',
      URL: 'https://www.google.com/maps/place/Forge+%26+Co./data=!4m6!3m5!1s0x48761cb0a0f1d423:0x64e2c0f74706ebb0!8m2!3d51.5254558!4d-0.0778223!16s%2Fg%2F1q5bwd6yx?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Forge+%26+Co./data=!4m6!3m5!1s0x48761cb0a0f1d423:0x64e2c0f74706ebb0!8m2!3d51.5254558!4d-0.0778223!16s%2Fg%2F1q5bwd6yx?authuser=0&hl=en&rclk=1',
      name: 'Forge & Co.',
      phone: '+44 20 7729 0007',
      rating: 4,
      address: '154-158 Shoreditch High St, London E1 6HU, United Kingdom',
      reviews: '360',
      website: 'forgeandco.co.uk',
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 9AM to 5PM; Tuesday, 9AM to 5PM; Wednesday, 9AM to 5PM; Thursday, 9AM to 5PM; Friday, 9AM to 5PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '35427414-61436604-448546-748653-54492750435',
      URL: 'https://www.google.com/maps/place/Your+Space+Co-Working+%26+Events/data=!4m6!3m5!1s0x487603f7f73bd0a5:0x66752ca33bbb467b!8m2!3d51.4719457!4d-0.0220561!16s%2Fg%2F11f7p2cl1g?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Your+Space+Co-Working+%26+Events/data=!4m6!3m5!1s0x487603f7f73bd0a5:0x66752ca33bbb467b!8m2!3d51.4719457!4d-0.0220561!16s%2Fg%2F11f7p2cl1g?authuser=0&hl=en&rclk=1',
      name: 'Your Space Co-Working & Events',
      phone: '+44 20 8100 2800',
      rating: 5,
      address:
        'The Stephen Lawrence Centre, 39 Brookmill Rd, London SE8 4HU, United Kingdom',
      reviews: '1',
      website: 'blueprintforall.org',
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 9AM to 6PM; Tuesday, 9AM to 6PM; Wednesday, 9AM to 6PM; Thursday, 9AM to 6PM; Friday, 9AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '49241583-10094498-898227-137342-78561938888',
      URL: 'https://www.google.com/maps/place/Mill+Co.+Rose+Lipman+Building/data=!4m6!3m5!1s0x48761c9771ac188b:0x21e40438fedb82eb!8m2!3d51.5389125!4d-0.0814069!16s%2Fg%2F1ptzy_shz?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Mill+Co.+Rose+Lipman+Building/data=!4m6!3m5!1s0x48761c9771ac188b:0x21e40438fedb82eb!8m2!3d51.5389125!4d-0.0814069!16s%2Fg%2F1ptzy_shz?authuser=0&hl=en&rclk=1',
      name: 'Mill Co. Rose Lipman Building',
      phone: '+44 20 7923 3565',
      rating: 4,
      address: '43 De Beauvoir Rd, London N1 5SQ, United Kingdom',
      reviews: '38',
      website: 'millco.co.uk',
      latitude: 51.5389125,
      longitude: -0.0814069,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 10AM to 6PM; Tuesday, 10AM to 6PM; Wednesday, 10AM to 6PM; Thursday, 10AM to 6PM; Friday, 10AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
    {
      id: '19124276-34239418-476412-311269-20351140902',
      URL: 'https://www.google.com/maps/place/The+Paradise+Co-operative/data=!4m6!3m5!1s0x487605f29e6f53df:0x2a146eb1a45c9783!8m2!3d51.4502591!4d-0.1762892!16s%2Fg%2F11fxw0yd3q?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/The+Paradise+Co-operative/data=!4m6!3m5!1s0x487605f29e6f53df:0x2a146eb1a45c9783!8m2!3d51.4502591!4d-0.1762892!16s%2Fg%2F11fxw0yd3q?authuser=0&hl=en&rclk=1',
      name: 'The Paradise Co-operative',
      phone: null,
      rating: null,
      address:
        'Dobbins Field, 19 Heathfield Rd, London SW18 3JE, United Kingdom',
      reviews: null,
      website: 'paradisecooperative.org',
      latitude: 51.4502644,
      longitude: -0.1762972,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, Closed; Tuesday, Closed; Wednesday, Closed; Thursday, Closed; Friday, Closed; Saturday, 9AM to 1PM. Hide open hours for the week',
    },
    {
      id: '29182750-53430943-154681-297799-72016681883',
      URL: 'https://www.google.com/maps/place/Friern+Barnet+Guide+Co+(The+Scout+Hut)/data=!4m6!3m5!1s0x4876199749d21117:0xae5d738abcaa0a9d!8m2!3d51.6156763!4d-0.1533511!16s%2Fg%2F11f1s59v2c?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Friern+Barnet+Guide+Co+(The+Scout+Hut)/data=!4m6!3m5!1s0x4876199749d21117:0xae5d738abcaa0a9d!8m2!3d51.6156763!4d-0.1533511!16s%2Fg%2F11f1s59v2c?authuser=0&hl=en&rclk=1',
      name: 'Friern Barnet Guide Co (The Scout Hut)',
      phone: null,
      rating: 4,
      address: '3JP, Stanford Rd, London, United Kingdom',
      reviews: '4',
      website: null,
      latitude: 51.6156814,
      longitude: -0.1532572,
      is_claimed: false,
      opening_hours: null,
    },
    {
      id: '13723102-15671924-267952-837606-31662502290',
      URL: 'https://www.google.com/maps/place/The+Business+Hub+%26+Co/data=!4m6!3m5!1s0x487602cc7ffc8aa3:0xe2ba59ae6ae77570!8m2!3d51.5123602!4d-0.0293658!16s%2Fg%2F11j30lwsjv?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/The+Business+Hub+%26+Co/data=!4m6!3m5!1s0x487602cc7ffc8aa3:0xe2ba59ae6ae77570!8m2!3d51.5123602!4d-0.0293658!16s%2Fg%2F11j30lwsjv?authuser=0&hl=en&rclk=1',
      name: 'The Business Hub & Co',
      phone: null,
      rating: null,
      address: 'London E14 7HG, United Kingdom',
      reviews: null,
      website: null,
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours: null,
    },
    {
      id: '31113733-91829172-374020-830270-83727483935',
      URL: 'https://www.google.com/maps/place/Mara+Children%E2%80%99s+Nursery+%26+Co+Working+Hub/data=!4m6!3m5!1s0x487619bb50b48245:0x2bd43ab09ec77320!8m2!3d51.635335!4d-0.1748538!16s%2Fg%2F11gy1rq97k?authuser=0&hl=en&rclk=1',
      City: 'London',
      link: 'https://www.google.com/maps/place/Mara+Children%E2%80%99s+Nursery+%26+Co+Working+Hub/data=!4m6!3m5!1s0x487619bb50b48245:0x2bd43ab09ec77320!8m2!3d51.635335!4d-0.1748538!16s%2Fg%2F11gy1rq97k?authuser=0&hl=en&rclk=1',
      name: 'Mara Children’s Nursery & Co Working Hub',
      phone: '+44 20 8446 4433',
      rating: 5,
      address: '2a Well Grv, London N20 9BN, United Kingdom',
      reviews: '17',
      website: 'maranursery.co.uk',
      latitude: 34.7468887,
      longitude: -86.4627065,
      is_claimed: false,
      opening_hours:
        'Sunday, Closed; Monday, 8AM to 6PM; Tuesday, 8AM to 6PM; Wednesday, 8AM to 6PM; Thursday, 8AM to 6PM; Friday, 8AM to 6PM; Saturday, Closed. Hide open hours for the week',
    },
  ]);
  const [mode, setMode] = React.useState(props.route?.params?.mode ?? 'Create');
  const [oneTimeTabI, setOneTimeTabI] = React.useState(1);
  const [pickerValue, setPickerValue] = React.useState('');
  const [priceValue, setPriceValue] = React.useState(
    (props.route?.params?.initialFormObj ?? {})?.pricing_per_session?.value
  );
  const [scheduleTabError, setScheduleTabError] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState(
    (props.route?.params?.initialFormObj ?? {})?.learning_center?.value?.name
  );
  const [selectedLearningCenter, setSelectedLearningCenter] =
    React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showingLearningCenters, setShowingLearningCenters] =
    React.useState(false);
  const [starRatingValue, setStarRatingValue] = React.useState(
    parseInt((props.route?.params?.initialFormObj ?? {})?.difficulty?.value, 10)
  );
  const [statusValue, setStatusValue] = React.useState(getLXPStatus());
  const [subjectOptions, setSubjectOptions] = React.useState([
    'Arts',
    'Coding & Tech',
    'English',
    'Health & Wellness',
    'Life skills',
    'Math',
    'Music',
    'Science & Nature',
    'Social Studies',
    'World Languages',
  ]);
  const [topTabI, setTopTabI] = React.useState(0);
  const [wholeActPriceValue, setWholeActPriceValue] = React.useState(0);
  const [wholePriceValue, setWholePriceValue] = React.useState(
    (props.route?.params?.initialFormObj ?? {})?.pricing_per_series?.value
  );

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
      hasSafeArea={false}
      scrollable={false}
    >
      {/* modal */}
      <>
        {!showModal ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Medium'],
                bottom: 0,
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100%',
                zIndex: 12,
              },
              dimensions.width
            )}
          >
            {/* Body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.colors['Background'],
                  borderRadius: 8,
                  justifyContent: 'center',
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                },
                dimensions.width
              )}
            >
              {/* Header editable */}
              <>
                {!isEditing() ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontSize: 18,
                        marginBottom: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Cancel without saving?'}
                  </Text>
                )}
              </>
              {/* Body editable */}
              <>
                {!isEditing() ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors.strong, marginBottom: 30 },
                      dimensions.width
                    )}
                  >
                    {'Cancel without saving your listing?'}
                  </Text>
                )}
              </>
              {/* Header draftable */}
              <>
                {!isLXPDraftable() ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontSize: 18,
                        marginBottom: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Save Your Progress?'}
                  </Text>
                )}
              </>
              {/* Body Draftable */}
              <>
                {!isLXPDraftable() ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors.strong, marginBottom: 30 },
                      dimensions.width
                    )}
                  >
                    {'Go back and save your current listing?'}
                  </Text>
                )}
              </>
              {/* Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 40,
                    justifyContent: 'space-between',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Back and Save */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Grey Lines'],
                      flex: 1,
                      height: '80%',
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  {/* Saving */}
                  <>
                    {!isSubmitting ? null : (
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIsSubmitting(true);
                              await supabaseRestAPIRequest$UpdateALXPForListingPOST.mutateAsync(
                                {
                                  author_id: Constants['UUID'],
                                  desc: formObj?.description.value,
                                  difficulty: formObj?.difficulty.value,
                                  imageURL: '',
                                  isOneTime: isOneTime,
                                  max_age: formObj?.ageRangeMax.value,
                                  max_part: formObj?.capacityRangeMax.value,
                                  min_age: formObj?.ageRangeMin.value,
                                  min_part: formObj?.capacityRangeMin.value,
                                  price_ser: formObj?.pricing_per_series.value,
                                  price_sess:
                                    formObj?.pricing_per_session.value,
                                  req_resources:
                                    formObj?.requiredResources.value,
                                  status: isLXPDraftable(),
                                  subject: formObj?.subject.value,
                                  title: formObj?.title.value,
                                  uuid: formObj?.id.value,
                                  zoom_id: formObj?.zoom_id.value,
                                  zoom_password: formObj?.zoom_password.value,
                                  zoom_url: formObj?.zoom_url.value,
                                }
                              );
                              const imgPath = await uploadImage(
                                formObj?.coverImage.value
                              );
                              await supabaseRestAPIAddCoverImageToLXPPATCH.mutateAsync(
                                { imageURL: imgPath, lxp_id: formObj?.id.value }
                              );
                              await supabaseRestAPIAddSessionsPOST.mutateAsync({
                                arr: formObj?.series[0].sessions,
                              });
                              setIsSubmitting(false);
                              setGlobalVariableValue({
                                key: 'CURRENT_SCREEN',
                                value: 'HOME',
                              });
                              navigation.navigate('HomeScreen_0FrGgFCx');
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Primary'],
                            borderRadius: 8,
                            color: theme.colors['Background'],
                            fontFamily: 'System',
                            fontSize: 10,
                            fontWeight: '700',
                            paddingBottom: 20,
                            paddingTop: 20,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        disabled={true}
                        loading={true}
                        title={'Continue Editing'}
                      />
                    )}
                  </>
                  {/* Active */}
                  <>
                    {isSubmitting ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Save and back */}
                        <>
                          {!showDraftButton() ? null : (
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  console.log('Save and back ON_PRESS Start');
                                  let error = null;
                                  try {
                                    console.log(
                                      'Start ON_PRESS:0 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setIsSubmitting(true);
                                    console.log(
                                      'Complete ON_PRESS:0 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:1 FETCH_REQUEST'
                                    );
                                    const lxpRes =
                                      await supabaseRestAPIRequest$UpdateALXPForListingPOST.mutateAsync(
                                        {
                                          author_id: Constants['UUID'],
                                          desc: formObj?.description.value,
                                          difficulty: formObj?.difficulty.value,
                                          imageURL: '',
                                          isOneTime: isOneTime,
                                          max_age: formObj?.ageRangeMax.value,
                                          max_part:
                                            formObj?.capacityRangeMax.value,
                                          min_age: formObj?.ageRangeMin.value,
                                          min_part:
                                            formObj?.capacityRangeMin.value,
                                          price_ser:
                                            formObj?.pricing_per_series.value,
                                          price_sess:
                                            formObj?.pricing_per_session.value,
                                          req_resources:
                                            formObj?.requiredResources.value,
                                          status: 'draft',
                                          subject: formObj?.subject.value,
                                          title: formObj?.title.value,
                                          uuid: formObj?.id.value,
                                          zoom_id: formObj?.zoom_id.value,
                                          zoom_password:
                                            formObj?.zoom_password.value,
                                          zoom_url: formObj?.zoom_url.value,
                                        }
                                      );
                                    console.log(
                                      'Complete ON_PRESS:1 FETCH_REQUEST',
                                      { lxpRes }
                                    );
                                    console.log(
                                      'Start ON_PRESS:3 TERMINATION_CHECK'
                                    );
                                    if (lxpRes) {
                                      return;
                                    }
                                    console.log(
                                      'Complete ON_PRESS:3 TERMINATION_CHECK'
                                    );
                                    console.log(
                                      'Start ON_PRESS:4 FETCH_REQUEST'
                                    );
                                    await supabaseRestAPIAddCoverImageToLXPPATCH.mutateAsync(
                                      {}
                                    );
                                    console.log(
                                      'Complete ON_PRESS:4 FETCH_REQUEST'
                                    );
                                    console.log(
                                      'Start ON_PRESS:5 FETCH_REQUEST'
                                    );
                                    await SupabaseRESTAPIAuthOptionalApi.upsertSessionPOST(
                                      Constants,
                                      { arr: parseSessionsForApiCall() }
                                    );
                                    console.log(
                                      'Complete ON_PRESS:5 FETCH_REQUEST'
                                    );
                                    console.log(
                                      'Start ON_PRESS:6 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setIsSubmitting(false);
                                    console.log(
                                      'Complete ON_PRESS:6 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:7 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setTopTabI(0);
                                    console.log(
                                      'Complete ON_PRESS:7 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:8 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setEditableOneTimeSession('');
                                    console.log(
                                      'Complete ON_PRESS:8 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:9 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setEditableSeries('');
                                    console.log(
                                      'Complete ON_PRESS:9 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:10 SET_SCREEN_LOCAL_STATE'
                                    );
                                    setOneTimeTabI(1);
                                    console.log(
                                      'Complete ON_PRESS:10 SET_SCREEN_LOCAL_STATE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:11 SET_GLOBAL_VARIABLE'
                                    );
                                    setGlobalVariableValue({
                                      key: 'CURRENT_SCREEN',
                                      value: 'HOME',
                                    });
                                    console.log(
                                      'Complete ON_PRESS:11 SET_GLOBAL_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:12 NAVIGATE_SCREEN'
                                    );
                                    navigation.navigate('HomeScreen_0FrGgFCx');
                                    console.log(
                                      'Complete ON_PRESS:12 NAVIGATE_SCREEN'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                    error = err.message ?? err;
                                  }
                                  console.log(
                                    'Save and back ON_PRESS Complete',
                                    error ? { error } : 'no error'
                                  );
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderColor: theme.colors['Inactive Grey'],
                                  borderRadius: 8,
                                  color: theme.colors['Inactive Grey'],
                                  fontFamily: 'System',
                                  fontSize: 14,
                                  fontWeight: '700',
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={'Back and Save'}
                            />
                          )}
                        </>
                        {/* Cancel */}
                        <>
                          {showDraftButton() ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  setShowModal(false);
                                  setFormObj({});
                                  setTopTabI(0);
                                  setOneTimeTabI(1);
                                  setEditableSeries('');
                                  setEditableOneTimeSession('');
                                  setGlobalVariableValue({
                                    key: 'CURRENT_SCREEN',
                                    value: 'HOME',
                                  });
                                  navigation.navigate('HomeScreen_0FrGgFCx');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderColor: theme.colors['Inactive Grey'],
                                  borderRadius: 8,
                                  color: theme.colors['Inactive Grey'],
                                  fontFamily: 'System',
                                  fontSize: 14,
                                  fontWeight: '700',
                                  height: '100%',
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Cancel'}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                </View>
                <Spacer top={8} right={8} bottom={8} left={8} />
                {/* Continue */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.colors['Primary'],
                      flex: 1,
                      height: '80%',
                      justifyContent: 'center',
                      paddingBottom: 20,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 20,
                      width: 140,
                    },
                    dimensions.width
                  )}
                >
                  {/* active */}
                  <>
                    {isSubmitting ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            setShowModal(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors['Background'] },
                            dimensions.width
                          )}
                        >
                          {'Continue Editing'}
                        </Text>
                      </Touchable>
                    )}
                  </>
                  {/* Inactive */}
                  <>
                    {!isSubmitting ? null : (
                      <Touchable>
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors['Inactive Grey'] },
                            dimensions.width
                          )}
                        >
                          {'Continue Editing\n'}
                        </Text>
                      </Touchable>
                    )}
                  </>
                </View>
              </View>
            </View>
          </View>
        )}
      </>
      <>
        {fetchingSubjects ? null : (
          <KeyboardAwareScrollView
            style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
            contentContainerStyle={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Background'] },
              dimensions.width
            )}
            showsVerticalScrollIndicator={true}
            keyboardShouldPersistTaps={'never'}
          >
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                {
                  marginBottom: 40,
                  marginLeft: 40,
                  marginRight: 40,
                  marginTop: 40,
                },
                dimensions.width
              )}
            >
              {/* back Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                  },
                  dimensions.width
                )}
              >
                {/* Save draft */}
                <Touchable
                  onPress={() => {
                    try {
                      setShowModal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Icon size={30} name={'MaterialIcons/chevron-left'} />
                </Touchable>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontSize: 24,
                      fontWeight: '700',
                      marginLeft: 16,
                    },
                    dimensions.width
                  )}
                >
                  {props.route?.params?.mode ?? 'Create'}
                  {' Learning Experience'}
                </Text>
              </View>
              {/* Shareable */}
              <>
                {!showShareableLink(
                  formObj,
                  props.route?.params?.mode ?? 'Create'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong },
                        dimensions.width
                      )}
                    >
                      {'Shareable Link'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors.greyLines,
                          borderLeftWidth: 1,
                          borderRadius: 8,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                          paddingBottom: 8,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 8,
                          width: 350,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                            marginRight: 8,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          name={'MaterialCommunityIcons/web'}
                          color={theme.colors['Inactive Grey']}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              marginLeft: 8,
                              overflow: 'hidden',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                            numberOfLines={1}
                            ellipsizeMode={'head'}
                          >
                            {createShareableLxpLink(
                              Variables,
                              formObj?.id?.value
                            )}
                          </Text>
                        </View>
                      </View>

                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await copyToClipboard(
                                createShareableLxpLink(
                                  Variables,
                                  formObj?.id?.value
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <Icon
                          size={24}
                          name={'Feather/copy'}
                          color={theme.colors['Inactive Grey']}
                        />
                      </Touchable>
                    </View>
                  </View>
                )}
              </>
              <Divider
                style={StyleSheet.applyWidth(
                  { height: 1, marginBottom: 16, marginTop: 16 },
                  dimensions.width
                )}
                color={theme.colors.divider}
              />
              {/* tabsFrame */}
              <View>
                {/* Row */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* General */}
                  <View>
                    {/* General button Active */}
                    <>
                      {!(topTabI === 0) ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderBottomWidth: 3,
                              borderColor: theme.colors.primary,
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'General'}
                        />
                      )}
                    </>
                    {/* General Button Inactive */}
                    <>
                      {!(topTabI !== 0) ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setTopTabI(0);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderColor: 'rgba(0, 0, 0, 0)',
                              borderRadius: 0,
                              borderWidth: 1,
                              color: theme.colors.inactiveGrey,
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'General'}
                        />
                      )}
                    </>
                  </View>
                  {/* Detail */}
                  <View>
                    {/* Active */}
                    <>
                      {!(topTabI === 1) ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderBottomWidth: 3,
                              borderColor: theme.colors.primary,
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Details'}
                        />
                      )}
                    </>
                    {/* Inactive */}
                    <>
                      {!(topTabI !== 1) ? null : (
                        <Button
                          onPress={() => {
                            try {
                              if (isInvalidTabIndex(1)) {
                                return;
                              }
                              setTopTabI(1);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderColor: 'rgba(0, 0, 0, 0)',
                              borderWidth: 1,
                              color: theme.colors.inactiveGrey,
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Details'}
                        />
                      )}
                    </>
                  </View>
                  {/* Schedule */}
                  <View>
                    {/* Active */}
                    <>
                      {!(topTabI === 2) ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderBottomWidth: 3,
                              borderColor: theme.colors.primary,
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Schedule'}
                        />
                      )}
                    </>
                    {/* Inactive */}
                    <>
                      {!(topTabI !== 2) ? null : (
                        <Button
                          onPress={() => {
                            try {
                              if (isInvalidTabIndex(2)) {
                                return;
                              }
                              setTopTabI(2);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderColor: 'rgba(0, 0, 0, 0)',
                              borderWidth: 1,
                              color: theme.colors.inactiveGrey,
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Schedule'}
                        />
                      )}
                    </>
                  </View>
                  {/* Setting */}
                  <View>
                    {/* Active */}
                    <>
                      {!(topTabI === 3) ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderBottomWidth: 3,
                              borderColor: theme.colors.primary,
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Settings'}
                        />
                      )}
                    </>
                    {/* Inactive */}
                    <>
                      {!(topTabI !== 3) ? null : (
                        <Button
                          onPress={() => {
                            try {
                              if (isInvalidTabIndex(3)) {
                                return;
                              }
                              setTopTabI(3);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderColor: 'rgba(0, 0, 0, 0)',
                              borderWidth: 1,
                              color: theme.colors.inactiveGrey,
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Settings'}
                        />
                      )}
                    </>
                  </View>
                </View>
                <Divider
                  style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
                  color={theme.colors.divider}
                />
              </View>
              {/* General Tab Frame */}
              <>
                {!(topTabI === 0) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { maxWidth: '70%' },
                      dimensions.width
                    )}
                  >
                    {/* Cover image upload */}
                    <View>
                      {/* Image */}
                      <View>
                        <>
                          {!formObj?.coverImage?.value ? null : (
                            <Image
                              style={StyleSheet.applyWidth(
                                { height: 230, marginTop: 16, width: 390 },
                                dimensions.width
                              )}
                              source={{ uri: `${formObj?.coverImage?.value}` }}
                              resizeMode={'cover'}
                            />
                          )}
                        </>
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontWeight: '400',
                              marginTop: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'390 x 230 px minimum optimal image dimensions'}
                        </Text>
                        {/* Button Solid */}
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const imageUrl = await openImagePickerUtil({});
                                setFormObj(
                                  setFieldValue(imageUrl, 'coverImage', formObj)
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['NFT_TIME_Border'],
                              borderRadius: 30,
                              fontFamily: 'System',
                              fontWeight: '700',
                              marginTop: 16,
                              textAlign: 'center',
                              width: 200,
                            },
                            dimensions.width
                          )}
                          icon={'MaterialCommunityIcons/image-auto-adjust'}
                          title={'Upload Cover Photo'}
                        />
                      </View>
                      {/* Error */}
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error'], fontSize: 12 },
                          dimensions.width
                        )}
                      >
                        {formObj?.coverImage?.error}
                      </Text>
                    </View>
                    {/* Title Input */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16, width: 352 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontSize: 20,
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Learning Experience Title'}
                      </Text>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setFormObj(
                              setFieldValue(newTextInputValue, 'title', formObj)
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Grey Lines'],
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            marginTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        placeholder={'Give your experience a title'}
                        editable={true}
                        placeholderTextColor={theme.colors['Inactive Grey']}
                        defaultValue={formObj?.title?.value}
                      />
                      {/* Error */}
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error'], fontSize: 12 },
                          dimensions.width
                        )}
                      >
                        {formObj?.title?.error}
                      </Text>
                    </View>
                    {/* Location */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Custom Color'],
                            fontFamily: 'System',
                            fontSize: 20,
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Where is the learning experience taking place?'}
                      </Text>
                      {/* tagListFrame */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {/* Zoom */}
                        <View>
                          {/* Zoom touchable active */}
                          <>
                            {!(
                              formObj?.location_type?.value === 'zoom'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Primary'],
                                    borderRadius: 8,
                                    paddingBottom: 4,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors['Background'] },
                                    dimensions.width
                                  )}
                                >
                                  {'Zoom\n'}
                                </Text>
                              </View>
                            )}
                          </>
                          <>
                            {!(
                              formObj?.location_type?.value !== 'zoom'
                            ) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    setFormObj(
                                      setFieldValue(
                                        'zoom',
                                        'location_type',
                                        formObj
                                      )
                                    );
                                    setShowingLearningCenters(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Zoom touchable inactive */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Inactive Grey'],
                                      borderRadius: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Background'],
                                        fontFamily: 'System',
                                        fontWeight: '400',
                                        paddingBottom: 4,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        paddingTop: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Zoom'}
                                  </Text>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                        <Spacer top={8} bottom={8} left={8} right={0} />
                        {/* Learning Center */}
                        <View>
                          {/* active */}
                          <>
                            {!(
                              formObj?.location_type?.value ===
                              'learning center'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Primary'],
                                    borderRadius: 8,
                                    paddingBottom: 4,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors['Background'] },
                                    dimensions.width
                                  )}
                                >
                                  {'Learning Center'}
                                </Text>
                              </View>
                            )}
                          </>
                          <>
                            {!(
                              formObj?.location_type?.value !==
                              'learning center'
                            ) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    setFormObj(
                                      setFieldValue(
                                        'learning center',
                                        'location_type',
                                        formObj
                                      )
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* inactive */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Inactive Grey'],
                                      borderRadius: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Background'],
                                        fontFamily: 'System',
                                        fontWeight: '400',
                                        paddingBottom: 4,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        paddingTop: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Learning Center'}
                                  </Text>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                        <Spacer top={8} bottom={8} left={8} right={0} />
                      </View>
                    </View>
                    <Spacer right={8} bottom={8} left={8} />
                    {/* Zoom meeting detials */}
                    <>
                      {!(formObj?.location_type?.value === 'zoom') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 352 },
                            dimensions.width
                          )}
                        >
                          {/* Field */}
                          <View>
                            {/* Label */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Inactive Grey'],
                                  fontSize: 16,
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Zoom Meeting URL'}
                            </Text>
                            <TextInput
                              onChangeText={newTextInputValue => {
                                try {
                                  setFormObj(
                                    setFieldValue(
                                      newTextInputValue,
                                      'zoom_url',
                                      formObj
                                    )
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Grey Lines'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                              placeholder={'https://zoom.us//j/123456789'}
                              editable={true}
                              placeholderTextColor={
                                theme.colors['Inactive Grey']
                              }
                              defaultValue={formObj?.zoom_url?.value}
                            />
                            {/* Error */}
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors['Error'], fontSize: 12 },
                                dimensions.width
                              )}
                            >
                              {formObj?.zoom_url?.error}
                            </Text>
                          </View>
                          <Spacer right={8} left={8} top={0} bottom={8} />
                          {/* Field */}
                          <View>
                            {/* Label */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Inactive Grey'],
                                  fontSize: 16,
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Zoom Meeting ID'}
                            </Text>
                            <TextInput
                              onChangeText={newTextInputValue => {
                                try {
                                  setFormObj(
                                    setFieldValue(
                                      newTextInputValue,
                                      'zoom_id',
                                      formObj
                                    )
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Grey Lines'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                              placeholder={'012345678'}
                              editable={true}
                              placeholderTextColor={
                                theme.colors['Inactive Grey']
                              }
                              defaultValue={formObj?.zoom_id?.value}
                            />
                            {/* Error */}
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors['Error'], fontSize: 12 },
                                dimensions.width
                              )}
                            >
                              {formObj?.zoom_id?.error}
                            </Text>
                          </View>
                          <Spacer right={8} left={8} top={0} bottom={8} />
                          {/* Field */}
                          <View>
                            {/* Label */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Inactive Grey'],
                                  fontSize: 16,
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Zoom Meeting Password'}
                            </Text>
                            <TextInput
                              onChangeText={newTextInputValue => {
                                try {
                                  setFormObj(
                                    setFieldValue(
                                      newTextInputValue,
                                      'zoom_password',
                                      formObj
                                    )
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Grey Lines'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                              placeholder={'000000'}
                              editable={true}
                              placeholderTextColor={
                                theme.colors['Inactive Grey']
                              }
                              defaultValue={formObj?.zoom_password?.value}
                            />
                            {/* Error */}
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors['Error'], fontSize: 12 },
                                dimensions.width
                              )}
                            >
                              {formObj?.zoom_password?.error}
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Learning Centre details */}
                    <>
                      {!(
                        formObj?.location_type?.value === 'learning center'
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 352 },
                            dimensions.width
                          )}
                        >
                          {/* Field */}
                          <View>
                            {/* Label */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Inactive Grey'],
                                  fontSize: 16,
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Learning Center'}
                            </Text>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  backgroundColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Grey Lines'],
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 8,
                                  },
                                  flexDirection: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 1,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Utils.CustomCodeErrorBoundary>
                                  <DebouncedInput.SearchLearningCenter
                                    theme={props.theme}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    setLearning_centers={setLearning_centers}
                                    setShowingLearningCenters={
                                      setShowingLearningCenters
                                    }
                                    selectedLearningCenter={
                                      selectedLearningCenter
                                    }
                                    setShowingLearningCenters={
                                      setShowingLearningCenters
                                    }
                                  />
                                </Utils.CustomCodeErrorBoundary>
                              </View>

                              <Touchable
                                onPress={() => {
                                  try {
                                    setSearchQuery('');
                                    setSelectedLearningCenter('');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    marginRight: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 4,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Error'],
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'clear'}
                                </Text>
                              </Touchable>
                            </View>
                            {/* Error */}
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors['Error'], fontSize: 12 },
                                dimensions.width
                              )}
                            >
                              {null}
                            </Text>
                            <>
                              {!showingLearningCenters ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: {
                                        minWidth: Breakpoints.Tablet,
                                        value: theme.colors['Grey Lines'],
                                      },
                                      borderRadius: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 8,
                                      },
                                      bottom: {
                                        minWidth: Breakpoints.Tablet,
                                        value: -308,
                                      },
                                      height: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 300,
                                      },
                                      padding: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 12,
                                      },
                                      position: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'absolute',
                                      },
                                      width: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 300,
                                      },
                                      zIndex: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 50,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'flex-end',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setShowingLearningCenters(false);
                                          setLearning_centers([]);
                                          setSearchQuery('');
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color: {
                                                minWidth: Breakpoints.Tablet,
                                                value: theme.colors['Error'],
                                              },
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'cancel'}
                                      </Text>
                                    </Touchable>
                                  </View>
                                  <>
                                    {learning_centers?.length ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 'System',
                                              },
                                              fontSize: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 17,
                                              },
                                              fontWeight: {
                                                minWidth: Breakpoints.Tablet,
                                                value: '600',
                                              },
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'No Results'}
                                      </Text>
                                    )}
                                  </>
                                  {/* Search result */}
                                  <FlatList
                                    renderItem={({ item }) => {
                                      const searchResultData = item;
                                      return (
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              setFormObj(
                                                setFieldValue(
                                                  searchResultData,
                                                  'learning_center',
                                                  formObj
                                                )
                                              );
                                              setFormObj(
                                                setFieldValue(
                                                  searchResultData?.id,
                                                  'learning_center_id',
                                                  formObj
                                                )
                                              );
                                              setSearchQuery('');
                                              setShowingLearningCenters(false);
                                              setLearning_centers([]);
                                              setSelectedLearningCenter(
                                                searchResultData?.name
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                paddingBottom: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 8,
                                                },
                                                paddingLeft: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 8,
                                                },
                                                paddingRight: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 8,
                                                },
                                                paddingTop: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 8,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Title */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                dimensions.width
                                              )}
                                            >
                                              {searchResultData?.name}
                                            </Text>
                                          </View>
                                        </Touchable>
                                      );
                                    }}
                                    data={learning_centers}
                                    listKey={'5nmiSiUJ'}
                                    keyExtractor={searchResultData =>
                                      searchResultData?.id
                                    }
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      {
                                        borderRadius: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 8,
                                        },
                                        marginTop: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 8,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                          <Spacer right={8} left={8} top={0} bottom={8} />
                        </View>
                      )}
                    </>
                    {/* Action container */}
                    <>
                      {showingLearningCenters ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Save General */}
                          <Button
                            onPress={() => {
                              try {
                                if (validateGeneralTab()) {
                                  return;
                                }
                                setTopTabI(1);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                marginTop: 40,
                                textAlign: 'center',
                                width: 250,
                              },
                              dimensions.width
                            )}
                            title={'Continue'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Details Tab Frame */}
              <>
                {!(topTabI === 1) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { maxWidth: '70%' },
                      dimensions.width
                    )}
                  >
                    {/* Main */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      {/* Subject */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 16, width: 352 },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '400',
                            },
                            dimensions.width
                          )}
                        >
                          {'Subject'}
                        </Text>
                        <Spacer right={8} left={8} bottom={4} top={4} />
                        <Picker
                          onValueChange={newPickerValue => {
                            try {
                              setFormObj(
                                setFieldValue(
                                  newPickerValue,
                                  'subject',
                                  formObj
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              height: 30,
                              width: '65%',
                            },
                            dimensions.width
                          )}
                          options={Constants['SUBJECTS']}
                          value={formObj?.subject?.value}
                          leftIconMode={'inset'}
                          type={'solid'}
                          iconSize={16}
                          rightIconName={'AntDesign/down'}
                          iconColor={theme.colors['Custom Color']}
                        />
                      </View>
                      {/* Difficulty */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 16 },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '400',
                            },
                            dimensions.width
                          )}
                        >
                          {'Difficulty'}
                        </Text>
                        <Spacer right={8} left={8} bottom={4} top={4} />
                        <StarRating
                          onPress={newStarRatingValue => {
                            try {
                              updateFormField('difficulty', newStarRatingValue);
                              setStarRatingValue(newStarRatingValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          rating={starRatingValue}
                          maxStars={5}
                          activeColor={theme.colors.primary}
                          inactiveColor={theme.colors.divider}
                          isEditable={true}
                          starSize={32}
                        />
                      </View>
                      {/* Description Text */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 16, width: 704 },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '400',
                            },
                            dimensions.width
                          )}
                        >
                          {'Description'}
                        </Text>

                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.strong, marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          {
                            "Topics to include:\n· Why do you want to teach this class?\n· What will be taught?\n· What topics will you cover?\n· How is your class structured?\n· How will you teach?\n· What's your teaching style?\n· How much will learners get to interact with you and each other (mention specifics like: lecture, games, slides, video clips, discussion)?\n· Any required experience or knowledge learners need?"
                          }
                        </Text>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        <TextInput
                          onChangeText={newTextAreaValue => {
                            try {
                              setFormObj(
                                setFieldValue(
                                  newTextAreaValue,
                                  'description',
                                  formObj
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Grey Lines'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                          placeholder={'Description...'}
                          editable={true}
                          textAlignVertical={'top'}
                          multiline={true}
                          numberOfLines={4}
                          placeholderTextColor={theme.colors['Inactive Grey']}
                          defaultValue={formObj?.description?.value}
                        />
                        {/* Error */}
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors['Error'], fontSize: 12 },
                            dimensions.width
                          )}
                        >
                          {formObj?.description?.error}
                        </Text>
                      </View>
                      {/* Required Resources */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 16, width: 704 },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '400',
                            },
                            dimensions.width
                          )}
                        >
                          {'Required Resources'}
                        </Text>

                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.strong, marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          {
                            'What extra materials, resources, software or tools will learners need?\n· Link to relevant web pages to help learners purchase or download\n· Explicitly state the cost of extra resources might cost '
                          }
                        </Text>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        <TextInput
                          onChangeText={newTextAreaValue => {
                            try {
                              setFormObj(
                                setFieldValue(
                                  newTextAreaValue,
                                  'requiredResources',
                                  formObj
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Grey Lines'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                          placeholder={'Resources...'}
                          editable={true}
                          textAlignVertical={'top'}
                          multiline={true}
                          numberOfLines={4}
                          placeholderTextColor={theme.colors['Inactive Grey']}
                          defaultValue={formObj?.requiredResources?.value}
                        />
                      </View>
                    </View>
                    {/* Action container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Save Details */}
                      <Button
                        onPress={() => {
                          try {
                            if (validateDetailsTab()) {
                              return;
                            }
                            setTopTabI(2);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            marginTop: 40,
                            textAlign: 'center',
                            width: 250,
                          },
                          dimensions.width
                        )}
                        title={'Continue'}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Schedule Tab Frame */}
              <>
                {!(topTabI === 2) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { maxWidth: '70%' },
                      dimensions.width
                    )}
                  >
                    {/* Type */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Custom Color'],
                            fontFamily: 'System',
                            fontSize: 20,
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'When it will happen?'}
                      </Text>
                      {/* tagListFrame */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {/* One Time */}
                        <View>
                          {/* one time active */}
                          <>
                            {!isOneTime ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Primary'],
                                    borderRadius: 8,
                                    paddingBottom: 4,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors['Background'] },
                                    dimensions.width
                                  )}
                                >
                                  {'One Time'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Inactive */}
                          <>
                            {isOneTime ? null : (
                              <View>
                                {/* One Time Touchable */}
                                <>
                                  {!(
                                    (props.route?.params?.mode ?? 'Create') ===
                                    'Create'
                                  ) ? null : (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setIsOneTime(true);
                                          resetSeries();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* inactive */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Inactive Grey'],
                                            borderRadius: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                              fontFamily: 'System',
                                              fontWeight: '400',
                                              paddingBottom: 4,
                                              paddingLeft: 4,
                                              paddingRight: 4,
                                              paddingTop: 4,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'One Time'}
                                        </Text>
                                      </View>
                                    </Touchable>
                                  )}
                                </>
                                {/* inactive */}
                                <>
                                  {!(
                                    (props.route?.params?.mode ?? 'Create') !==
                                    'Create'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Inactive Grey'],
                                          borderRadius: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Background'],
                                            fontFamily: 'System',
                                            fontWeight: '400',
                                            paddingBottom: 4,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            paddingTop: 4,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'One Time'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                        <Spacer top={8} bottom={8} left={8} right={0} />
                        {/* Multiday */}
                        <View>
                          {/* active */}
                          <>
                            {isOneTime ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Primary'],
                                    borderRadius: 8,
                                    paddingBottom: 4,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors['Background'] },
                                    dimensions.width
                                  )}
                                >
                                  {'Multi day'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Inactive */}
                          <>
                            {!isOneTime ? null : (
                              <View>
                                {/* inactive */}
                                <>
                                  {!(
                                    (props.route?.params?.mode ?? 'Create') !==
                                    'Create'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Inactive Grey'],
                                          borderRadius: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Background'],
                                            fontFamily: 'System',
                                            fontWeight: '400',
                                            paddingBottom: 4,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            paddingTop: 4,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Multi day'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                <>
                                  {!(
                                    (props.route?.params?.mode ?? 'Create') ===
                                    'Create'
                                  ) ? null : (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setIsOneTime(false);
                                          resetSeries();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* inactive */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Inactive Grey'],
                                            borderRadius: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                              fontFamily: 'System',
                                              fontWeight: '400',
                                              paddingBottom: 4,
                                              paddingLeft: 4,
                                              paddingRight: 4,
                                              paddingTop: 4,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Multi day'}
                                        </Text>
                                      </View>
                                    </Touchable>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                    {/* Type Tabs */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      {/* One Time Tab */}
                      <>
                        {!isOneTime ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 16 },
                              dimensions.width
                            )}
                          >
                            {/* tabsFrame */}
                            <View>
                              {/* Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Past */}
                                <View>
                                  {/* Active */}
                                  <>
                                    {!(oneTimeTabI === 0) ? null : (
                                      <Button
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderBottomWidth: 3,
                                            borderColor: theme.colors.primary,
                                            borderRadius: 0,
                                            color: theme.colors['Primary'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Past'}
                                      />
                                    )}
                                  </>
                                  {/* Inactive */}
                                  <>
                                    {!(oneTimeTabI !== 0) ? null : (
                                      <Button
                                        onPress={() => {
                                          try {
                                            setOneTimeTabI(0);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            borderRadius: 0,
                                            borderWidth: 1,
                                            color: theme.colors.inactiveGrey,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Past'}
                                      />
                                    )}
                                  </>
                                </View>
                                {/* Upcomming */}
                                <View>
                                  {/* Active */}
                                  <>
                                    {!(oneTimeTabI === 1) ? null : (
                                      <Button
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderBottomWidth: 3,
                                            borderColor: theme.colors.primary,
                                            borderRadius: 0,
                                            color: theme.colors['Primary'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Upcoming'}
                                      />
                                    )}
                                  </>
                                  {/* Inactive */}
                                  <>
                                    {!(oneTimeTabI !== 1) ? null : (
                                      <Button
                                        onPress={() => {
                                          try {
                                            setOneTimeTabI(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            borderWidth: 1,
                                            color: theme.colors.inactiveGrey,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Upcoming'}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                              <Divider
                                style={StyleSheet.applyWidth(
                                  { height: 1 },
                                  dimensions.width
                                )}
                                color={theme.colors.divider}
                              />
                            </View>
                            {/* Upcoming Tab Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  marginTop: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Sessions */}
                              <>
                                {!formObj?.series?.length ? null : (
                                  <FlatList
                                    renderItem={({ item }) => {
                                      const sessionsData = item;
                                      return (
                                        <>
                                          {/* Upcoming */}
                                          <>
                                            {!(oneTimeTabI === 1) ? null : (
                                              <View>
                                                {/* container */}
                                                <>
                                                  {isPastSession(
                                                    sessionsData
                                                  ) ? null : (
                                                    <View>
                                                      {/* Editing Card */}
                                                      <>
                                                        {!(
                                                          sessionsData?.id ===
                                                          editableOneTimeSession
                                                        ) ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'flex-start',
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    'Background'
                                                                  ],
                                                                borderBottomWidth: 1,
                                                                borderColor:
                                                                  theme.colors[
                                                                    'Grey Lines'
                                                                  ],
                                                                borderLeftWidth: 1,
                                                                borderRadius: 8,
                                                                borderRightWidth: 1,
                                                                borderTopWidth: 1,
                                                                marginRight: 10,
                                                                paddingBottom: 20,
                                                                paddingLeft: 12,
                                                                paddingRight: 12,
                                                                paddingTop: 20,
                                                                width: 265,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  justifyContent:
                                                                    'flex-end',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Touchable
                                                                onPress={() => {
                                                                  const handler =
                                                                    async () => {
                                                                      try {
                                                                        setEditableOneTimeSession(
                                                                          ''
                                                                        );
                                                                        deleteAnOneTimeSession(
                                                                          sessionsData?.id
                                                                        );
                                                                        if (
                                                                          !sessionsData?.created_at
                                                                        ) {
                                                                          return;
                                                                        }
                                                                        await supabaseRestAPIDeleteSessionDELETE.mutateAsync(
                                                                          {
                                                                            sess_id:
                                                                              sessionsData?.id,
                                                                          }
                                                                        );
                                                                      } catch (err) {
                                                                        console.error(
                                                                          err
                                                                        );
                                                                      }
                                                                    };
                                                                  handler();
                                                                }}
                                                              >
                                                                <Icon
                                                                  name={
                                                                    'Entypo/circle-with-cross'
                                                                  }
                                                                  size={16}
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      'Inactive Grey'
                                                                    ]
                                                                  }
                                                                />
                                                              </Touchable>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            <DatePicker
                                                              onDateChange={newDatePickerValue => {
                                                                try {
                                                                  const valuerLUcEMvq =
                                                                    newDatePickerValue;
                                                                  setDatePickerValue(
                                                                    valuerLUcEMvq
                                                                  );
                                                                  const newDate =
                                                                    valuerLUcEMvq;
                                                                  const neFormObj =
                                                                    editOneTimeSession(
                                                                      editableOneTimeSession,
                                                                      'start_time',
                                                                      newDatePickerValue
                                                                    );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  fontFamily:
                                                                    'System',
                                                                  fontSize: 14,
                                                                  fontWeight:
                                                                    '400',
                                                                  height: 50,
                                                                  justifyContent:
                                                                    'center',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                              date={
                                                                new Date(
                                                                  datePickerValue
                                                                )
                                                              }
                                                              label={'Date'}
                                                              leftIconMode={
                                                                'inset'
                                                              }
                                                              type={'solid'}
                                                              mode={'datetime'}
                                                            />
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Duration */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'flex-start',
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Label Icon */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'AntDesign/clockcircleo'
                                                                  }
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      'Inactive Grey'
                                                                    ]
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={0}
                                                                />
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          'Light'
                                                                        ],
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 20,
                                                                      fontWeight:
                                                                        '400',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Duration'}
                                                                </Text>
                                                              </View>
                                                              <Spacer
                                                                right={8}
                                                                bottom={8}
                                                                left={8}
                                                                top={0}
                                                              />
                                                              {/* Input */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Minutes */}
                                                                <NumberInput
                                                                  onChangeText={newMinutesValue => {
                                                                    const numberInputValue =
                                                                      newMinutesValue;
                                                                    try {
                                                                      editOneTimeSession(
                                                                        editableOneTimeSession,
                                                                        'duration',
                                                                        newMinutesValue
                                                                      );
                                                                    } catch (err) {
                                                                      console.error(
                                                                        err
                                                                      );
                                                                    }
                                                                  }}
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      backgroundColor:
                                                                        theme
                                                                          .colors[
                                                                          'Grey Lines'
                                                                        ],
                                                                      borderBottomWidth: 1,
                                                                      borderColor:
                                                                        theme
                                                                          .colors
                                                                          .divider,
                                                                      borderLeftWidth: 1,
                                                                      borderRadius: 8,
                                                                      borderRightWidth: 1,
                                                                      borderTopWidth: 1,
                                                                      paddingBottom: 8,
                                                                      paddingLeft: 8,
                                                                      paddingRight: 8,
                                                                      paddingTop: 8,
                                                                      width: 40,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                  maxLength={3}
                                                                  selectTextOnFocus={
                                                                    true
                                                                  }
                                                                  editable={
                                                                    true
                                                                  }
                                                                  placeholder={
                                                                    'Enter a number...'
                                                                  }
                                                                  defaultValue={
                                                                    sessionsData?.duration
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={8}
                                                                />
                                                                {/* Label */}
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Minutes'}
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={0}
                                                            />
                                                            {/* Error */}
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme
                                                                      .colors[
                                                                      'Error'
                                                                    ],
                                                                  fontSize: 12,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                sessionsData?.error
                                                              }
                                                            </Text>
                                                            {/* Save */}
                                                            <Button
                                                              onPress={() => {
                                                                try {
                                                                  if (
                                                                    validateSeries()
                                                                  ) {
                                                                    return;
                                                                  }
                                                                  setEditableOneTimeSession(
                                                                    ''
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    theme.colors
                                                                      .primary,
                                                                  borderRadius: 8,
                                                                  fontFamily:
                                                                    'System',
                                                                  fontWeight:
                                                                    '700',
                                                                  textAlign:
                                                                    'center',
                                                                  width: '90%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                              title={'Save'}
                                                            />
                                                          </View>
                                                        )}
                                                      </>
                                                      {/* Editable Card */}
                                                      <>
                                                        {sessionsData?.id ===
                                                        editableOneTimeSession ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'flex-start',
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    'Background'
                                                                  ],
                                                                borderBottomWidth: 1,
                                                                borderColor:
                                                                  theme.colors[
                                                                    'Grey Lines'
                                                                  ],
                                                                borderLeftWidth: 1,
                                                                borderRadius: 8,
                                                                borderRightWidth: 1,
                                                                borderTopWidth: 1,
                                                                height: 250,
                                                                marginRight: 10,
                                                                paddingBottom: 20,
                                                                paddingLeft: 12,
                                                                paddingRight: 12,
                                                                paddingTop: 20,
                                                                width: 265,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  justifyContent:
                                                                    'flex-end',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Touchable
                                                                onPress={() => {
                                                                  const handler =
                                                                    async () => {
                                                                      try {
                                                                        setEditableOneTimeSession(
                                                                          ''
                                                                        );
                                                                        deleteAnOneTimeSession(
                                                                          sessionsData?.id
                                                                        );
                                                                        if (
                                                                          sessionsData
                                                                            ?.created_at
                                                                            ?.Negate
                                                                        ) {
                                                                          return;
                                                                        }
                                                                        await supabaseRestAPIDeleteSessionDELETE.mutateAsync(
                                                                          {
                                                                            sess_id:
                                                                              sessionsData?.id,
                                                                          }
                                                                        );
                                                                      } catch (err) {
                                                                        console.error(
                                                                          err
                                                                        );
                                                                      }
                                                                    };
                                                                  handler();
                                                                }}
                                                              >
                                                                <Icon
                                                                  name={
                                                                    'Entypo/circle-with-cross'
                                                                  }
                                                                  size={16}
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      'Inactive Grey'
                                                                    ]
                                                                  }
                                                                />
                                                              </Touchable>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Date time container */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignContent:
                                                                    'flex-end',
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Date */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    backgroundColor:
                                                                      theme
                                                                        .colors[
                                                                        'Grey Lines'
                                                                      ],
                                                                    borderRadius: 8,
                                                                    flexDirection:
                                                                      'row',
                                                                    paddingBottom: 3,
                                                                    paddingLeft: 3,
                                                                    paddingRight: 3,
                                                                    paddingTop: 3,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontWeight:
                                                                        '600',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {getFormattedDate(
                                                                    sessionsData
                                                                  )}
                                                                </Text>
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={8}
                                                                />
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'AntDesign/calendar'
                                                                  }
                                                                />
                                                              </View>
                                                              <Spacer
                                                                top={8}
                                                                right={8}
                                                                bottom={8}
                                                                left={8}
                                                              />
                                                              {/* time */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    backgroundColor:
                                                                      theme
                                                                        .colors[
                                                                        'Grey Lines'
                                                                      ],
                                                                    borderRadius: 8,
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 13,
                                                                      fontWeight:
                                                                        '600',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {getFormattedTime(
                                                                    sessionsData
                                                                  )}
                                                                </Text>
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={8}
                                                                />
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'EvilIcons/clock'
                                                                  }
                                                                />
                                                              </View>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Duration */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'flex-start',
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Label Icon */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'AntDesign/clockcircleo'
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={0}
                                                                />
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          'Light'
                                                                        ],
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 20,
                                                                      fontWeight:
                                                                        '400',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Duration'}
                                                                </Text>
                                                              </View>
                                                              <Spacer
                                                                right={8}
                                                                bottom={8}
                                                                left={8}
                                                                top={0}
                                                              />
                                                              {/* time */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    backgroundColor:
                                                                      theme
                                                                        .colors[
                                                                        'Grey Lines'
                                                                      ],
                                                                    borderRadius: 8,
                                                                    flexDirection:
                                                                      'row',
                                                                    justifyContent:
                                                                      'center',
                                                                    width: 50,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 13,
                                                                      fontWeight:
                                                                        '600',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    sessionsData?.duration
                                                                  }
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            {/* Error */}
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme
                                                                      .colors[
                                                                      'Error'
                                                                    ],
                                                                  fontSize: 12,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                sessionsData?.error
                                                              }
                                                            </Text>

                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'flex-start',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Edit */}
                                                              <Button
                                                                onPress={() => {
                                                                  try {
                                                                    setEditableOneTimeSession(
                                                                      sessionsData?.id
                                                                    );
                                                                    setDatePickerValue(
                                                                      sessionsData?.start_time
                                                                    );
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                }}
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    backgroundColor:
                                                                      theme
                                                                        .colors
                                                                        .primary,
                                                                    borderRadius: 8,
                                                                    fontFamily:
                                                                      'System',
                                                                    fontWeight:
                                                                      '700',
                                                                    textAlign:
                                                                      'center',
                                                                    width:
                                                                      '90%',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                                title={'Edit'}
                                                              />
                                                            </View>
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                          {/* Past */}
                                          <>
                                            {!(oneTimeTabI !== 1) ? null : (
                                              <View>
                                                {/* Card */}
                                                <>
                                                  {!isPastSession(
                                                    sessionsData
                                                  ) ? null : (
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems:
                                                            'flex-start',
                                                          backgroundColor:
                                                            theme.colors[
                                                              'Background'
                                                            ],
                                                          borderBottomWidth: 1,
                                                          borderColor:
                                                            theme.colors[
                                                              'Grey Lines'
                                                            ],
                                                          borderLeftWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderTopWidth: 1,
                                                          height: 250,
                                                          marginRight: 10,
                                                          paddingBottom: 20,
                                                          paddingLeft: 12,
                                                          paddingRight: 12,
                                                          paddingTop: 20,
                                                          width: 265,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Spacer
                                                        top={8}
                                                        right={8}
                                                        bottom={8}
                                                        left={8}
                                                      />
                                                      {/* Date time container */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignContent:
                                                              'flex-end',
                                                            alignItems:
                                                              'flex-start',
                                                            flexDirection:
                                                              'column',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Date */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              backgroundColor:
                                                                theme.colors[
                                                                  'Grey Lines'
                                                                ],
                                                              borderRadius: 8,
                                                              flexDirection:
                                                                'row',
                                                              paddingBottom: 4,
                                                              paddingLeft: 8,
                                                              paddingRight: 8,
                                                              paddingTop: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors
                                                                    .strong,
                                                                fontFamily:
                                                                  'System',
                                                                fontWeight:
                                                                  '600',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {getFormattedDate(
                                                              sessionsData
                                                            )}
                                                          </Text>
                                                          <Spacer
                                                            top={8}
                                                            right={8}
                                                            bottom={8}
                                                            left={8}
                                                          />
                                                          <Icon
                                                            size={24}
                                                            name={
                                                              'AntDesign/calendar'
                                                            }
                                                          />
                                                        </View>
                                                        <Spacer
                                                          top={8}
                                                          right={8}
                                                          bottom={8}
                                                          left={8}
                                                        />
                                                        {/* time */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              backgroundColor:
                                                                theme.colors[
                                                                  'Grey Lines'
                                                                ],
                                                              borderRadius: 8,
                                                              flexDirection:
                                                                'row',
                                                              paddingBottom: 4,
                                                              paddingLeft: 8,
                                                              paddingRight: 8,
                                                              paddingTop: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors
                                                                    .strong,
                                                                fontFamily:
                                                                  'System',
                                                                fontSize: 13,
                                                                fontWeight:
                                                                  '600',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {getFormattedTime(
                                                              sessionsData
                                                            )}
                                                          </Text>
                                                          <Spacer
                                                            top={8}
                                                            right={8}
                                                            bottom={8}
                                                            left={8}
                                                          />
                                                          <Icon
                                                            size={24}
                                                            name={
                                                              'EvilIcons/clock'
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                      <Spacer
                                                        top={8}
                                                        right={8}
                                                        bottom={8}
                                                        left={8}
                                                      />
                                                      {/* Duration */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignSelf:
                                                              'flex-start',
                                                            flex: 1,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                            paddingTop: 0,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Label Icon */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Icon
                                                            size={24}
                                                            name={
                                                              'AntDesign/clockcircleo'
                                                            }
                                                          />
                                                          <Spacer
                                                            top={8}
                                                            right={8}
                                                            bottom={8}
                                                            left={0}
                                                          />
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors[
                                                                    'Light'
                                                                  ],
                                                                fontFamily:
                                                                  'System',
                                                                fontSize: 20,
                                                                fontWeight:
                                                                  '400',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'Duration'}
                                                          </Text>
                                                        </View>
                                                        <Spacer
                                                          right={8}
                                                          bottom={8}
                                                          left={8}
                                                          top={0}
                                                        />
                                                        {/* time */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              backgroundColor:
                                                                theme.colors[
                                                                  'Grey Lines'
                                                                ],
                                                              borderRadius: 8,
                                                              flexDirection:
                                                                'row',
                                                              justifyContent:
                                                                'center',
                                                              paddingBottom: 4,
                                                              paddingLeft: 8,
                                                              paddingRight: 8,
                                                              paddingTop: 4,
                                                              width: 50,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors
                                                                    .strong,
                                                                fontFamily:
                                                                  'System',
                                                                fontSize: 13,
                                                                fontWeight:
                                                                  '600',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              sessionsData?.duration
                                                            }
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    </View>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </>
                                      );
                                    }}
                                    data={
                                      (formObj?.series && (formObj?.series)[0])
                                        ?.sessions
                                    }
                                    listKey={'lrCa08mM'}
                                    keyExtractor={sessionsData =>
                                      sessionsData?.id
                                    }
                                    style={StyleSheet.applyWidth(
                                      { flexGrow: 0 },
                                      dimensions.width
                                    )}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        flexShrink: 1,
                                        flexWrap: 'wrap',
                                        marginBottom: 10,
                                        marginTop: 10,
                                      },
                                      dimensions.width
                                    )}
                                    numColumns={1}
                                  />
                                )}
                              </>
                              {/* add new */}
                              <>
                                {!(oneTimeTabI === 1) ? null : (
                                  <Button
                                    onPress={() => {
                                      try {
                                        if (
                                          stopCreatingOneTimeSession(
                                            editableOneTimeSession
                                          )
                                        ) {
                                          return;
                                        }
                                        addNewOneTimeSession();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors['Grey Lines'],
                                        borderRadius: 8,
                                        color: theme.colors['Inactive Grey'],
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        height: 270,
                                        textAlign: 'center',
                                        width: 150,
                                      },
                                      dimensions.width
                                    )}
                                    icon={'Entypo/new-message'}
                                    title={'Create New Session'}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Multiday Tab */}
                      <>
                        {isOneTime ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 16 },
                              dimensions.width
                            )}
                          >
                            {/* tabsFrame */}
                            <View>
                              {/* Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Past */}
                                <View>
                                  {/* Active */}
                                  <>
                                    {oneTimeTabI ? null : (
                                      <Button
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderBottomWidth: 3,
                                            borderColor: theme.colors.primary,
                                            borderRadius: 0,
                                            color: theme.colors['Primary'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Past'}
                                      />
                                    )}
                                  </>
                                  {/* Inactive */}
                                  <>
                                    {!oneTimeTabI ? null : (
                                      <Button
                                        onPress={() => {
                                          try {
                                            setOneTimeTabI(0);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            borderRadius: 0,
                                            borderWidth: 1,
                                            color: theme.colors.inactiveGrey,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Past'}
                                      />
                                    )}
                                  </>
                                </View>
                                {/* Upcomming */}
                                <View>
                                  {/* Active */}
                                  <>
                                    {!oneTimeTabI ? null : (
                                      <Button
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderBottomWidth: 3,
                                            borderColor: theme.colors.primary,
                                            borderRadius: 0,
                                            color: theme.colors['Primary'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Upcoming'}
                                      />
                                    )}
                                  </>
                                  {/* Inactive */}
                                  <>
                                    {oneTimeTabI ? null : (
                                      <Button
                                        onPress={() => {
                                          try {
                                            setOneTimeTabI(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            borderWidth: 1,
                                            color: theme.colors.inactiveGrey,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Upcoming'}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                              <Divider
                                style={StyleSheet.applyWidth(
                                  { height: 1 },
                                  dimensions.width
                                )}
                                color={theme.colors.divider}
                              />
                            </View>
                            {/* Tab Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  marginTop: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Series */}
                              <>
                                {!formObj?.series?.length ? null : (
                                  <FlatList
                                    renderItem={({ item }) => {
                                      const seriesData = item;
                                      return (
                                        <>
                                          {/* Upcoming */}
                                          <>
                                            {!(oneTimeTabI === 1) ? null : (
                                              <View>
                                                {/* Container */}
                                                <>
                                                  {!isUpcomingSeries(
                                                    seriesData
                                                  ) ? null : (
                                                    <View>
                                                      {/* Editing Card */}
                                                      <>
                                                        {!(
                                                          seriesData?.series_no ===
                                                          editableSeries
                                                        ) ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'flex-start',
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    'Background'
                                                                  ],
                                                                borderBottomWidth: 1,
                                                                borderColor:
                                                                  theme.colors[
                                                                    'Grey Lines'
                                                                  ],
                                                                borderLeftWidth: 1,
                                                                borderRightWidth: 1,
                                                                borderTopWidth: 1,
                                                                marginRight: 10,
                                                                paddingBottom: 20,
                                                                paddingLeft: 12,
                                                                paddingRight: 12,
                                                                paddingTop: 20,
                                                                width: 320,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  justifyContent:
                                                                    'space-between',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'Series #'}
                                                                {
                                                                  seriesData?.series_no
                                                                }
                                                              </Text>

                                                              <Touchable
                                                                onPress={() => {
                                                                  try {
                                                                    setDeleteSeriesIntent(
                                                                      true
                                                                    );
                                                                    setDeletingSeriesId(
                                                                      seriesData?.session_no
                                                                    );
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <Icon
                                                                  name={
                                                                    'Entypo/circle-with-cross'
                                                                  }
                                                                  size={16}
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      'Inactive Grey'
                                                                    ]
                                                                  }
                                                                />
                                                              </Touchable>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Sessions */}
                                                            <FlatList
                                                              renderItem={({
                                                                item,
                                                              }) => {
                                                                const sessionsData =
                                                                  item;
                                                                return (
                                                                  <>
                                                                    {/* Container */}
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          marginBottom:
                                                                            {
                                                                              minWidth:
                                                                                Breakpoints.Laptop,
                                                                              value: 8,
                                                                            },
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      {/* Row */}
                                                                      <>
                                                                        {isPastSession(
                                                                          sessionsData
                                                                        ) ? null : (
                                                                          <View
                                                                            style={StyleSheet.applyWidth(
                                                                              {
                                                                                alignItems:
                                                                                  'center',
                                                                                flexDirection:
                                                                                  'row',
                                                                              },
                                                                              dimensions.width
                                                                            )}
                                                                          >
                                                                            {/* Date time container */}
                                                                            <>
                                                                              {sessionsData?.id ===
                                                                              editableOneTimeSession ? null : (
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      alignContent:
                                                                                        'flex-end',
                                                                                      alignItems:
                                                                                        'center',
                                                                                      flexDirection:
                                                                                        'row',
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  {/* Date */}
                                                                                  <View
                                                                                    style={StyleSheet.applyWidth(
                                                                                      {
                                                                                        alignItems:
                                                                                          'center',
                                                                                        backgroundColor:
                                                                                          theme
                                                                                            .colors[
                                                                                            'Grey Lines'
                                                                                          ],
                                                                                        borderRadius: 8,
                                                                                        flexDirection:
                                                                                          'row',
                                                                                        paddingBottom: 3,
                                                                                        paddingLeft: 3,
                                                                                        paddingRight: 3,
                                                                                        paddingTop: 3,
                                                                                        width:
                                                                                          {
                                                                                            minWidth:
                                                                                              Breakpoints.Laptop,
                                                                                            value: 150,
                                                                                          },
                                                                                      },
                                                                                      dimensions.width
                                                                                    )}
                                                                                  >
                                                                                    <Text
                                                                                      style={StyleSheet.applyWidth(
                                                                                        {
                                                                                          color:
                                                                                            theme
                                                                                              .colors
                                                                                              .strong,
                                                                                          fontFamily:
                                                                                            'System',
                                                                                          fontWeight:
                                                                                            '600',
                                                                                        },
                                                                                        dimensions.width
                                                                                      )}
                                                                                    >
                                                                                      {getFormattedDate(
                                                                                        sessionsData
                                                                                      )}
                                                                                    </Text>
                                                                                    <Spacer
                                                                                      top={
                                                                                        8
                                                                                      }
                                                                                      right={
                                                                                        8
                                                                                      }
                                                                                      bottom={
                                                                                        8
                                                                                      }
                                                                                      left={
                                                                                        8
                                                                                      }
                                                                                    />
                                                                                    <Icon
                                                                                      size={
                                                                                        24
                                                                                      }
                                                                                      name={
                                                                                        'AntDesign/calendar'
                                                                                      }
                                                                                      color={
                                                                                        theme
                                                                                          .colors[
                                                                                          'Inactive Grey'
                                                                                        ]
                                                                                      }
                                                                                    />
                                                                                  </View>
                                                                                  <Spacer
                                                                                    top={
                                                                                      8
                                                                                    }
                                                                                    right={
                                                                                      8
                                                                                    }
                                                                                    bottom={
                                                                                      8
                                                                                    }
                                                                                    left={
                                                                                      8
                                                                                    }
                                                                                  />
                                                                                  {/* time */}
                                                                                  <View
                                                                                    style={StyleSheet.applyWidth(
                                                                                      {
                                                                                        alignItems:
                                                                                          'center',
                                                                                        backgroundColor:
                                                                                          theme
                                                                                            .colors[
                                                                                            'Grey Lines'
                                                                                          ],
                                                                                        borderRadius: 8,
                                                                                        flexDirection:
                                                                                          'row',
                                                                                        width:
                                                                                          {
                                                                                            minWidth:
                                                                                              Breakpoints.Laptop,
                                                                                            value: 100,
                                                                                          },
                                                                                      },
                                                                                      dimensions.width
                                                                                    )}
                                                                                  >
                                                                                    <Text
                                                                                      style={StyleSheet.applyWidth(
                                                                                        {
                                                                                          color:
                                                                                            theme
                                                                                              .colors
                                                                                              .strong,
                                                                                          fontFamily:
                                                                                            'System',
                                                                                          fontSize: 13,
                                                                                          fontWeight:
                                                                                            '600',
                                                                                        },
                                                                                        dimensions.width
                                                                                      )}
                                                                                    >
                                                                                      {getFormattedTime(
                                                                                        sessionsData
                                                                                      )}
                                                                                    </Text>
                                                                                    <Spacer
                                                                                      top={
                                                                                        8
                                                                                      }
                                                                                      right={
                                                                                        8
                                                                                      }
                                                                                      bottom={
                                                                                        8
                                                                                      }
                                                                                      left={
                                                                                        8
                                                                                      }
                                                                                    />
                                                                                    <Icon
                                                                                      size={
                                                                                        24
                                                                                      }
                                                                                      name={
                                                                                        'EvilIcons/clock'
                                                                                      }
                                                                                      color={
                                                                                        theme
                                                                                          .colors[
                                                                                          'Inactive Grey'
                                                                                        ]
                                                                                      }
                                                                                    />
                                                                                  </View>
                                                                                </View>
                                                                              )}
                                                                            </>
                                                                            {/* Picker container */}
                                                                            <>
                                                                              {!(
                                                                                sessionsData?.id ===
                                                                                editableOneTimeSession
                                                                              ) ? null : (
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      width: 220,
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  <DatePicker
                                                                                    onDateChange={newDatePickerValue => {
                                                                                      try {
                                                                                        setDatePickerValue(
                                                                                          newDatePickerValue
                                                                                        );
                                                                                        updateMultidaySessionStartTime(
                                                                                          seriesData?.series_no,
                                                                                          sessionsData?.id,
                                                                                          newDatePickerValue
                                                                                        );
                                                                                      } catch (err) {
                                                                                        console.error(
                                                                                          err
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                    style={StyleSheet.applyWidth(
                                                                                      {
                                                                                        fontFamily:
                                                                                          'System',
                                                                                        fontSize: 14,
                                                                                        fontWeight:
                                                                                          '400',
                                                                                        height: 50,
                                                                                        justifyContent:
                                                                                          'center',
                                                                                        width:
                                                                                          '100%',
                                                                                      },
                                                                                      dimensions.width
                                                                                    )}
                                                                                    date={
                                                                                      new Date(
                                                                                        datePickerValue
                                                                                      )
                                                                                    }
                                                                                    label={
                                                                                      'Date'
                                                                                    }
                                                                                    leftIconMode={
                                                                                      'inset'
                                                                                    }
                                                                                    type={
                                                                                      'solid'
                                                                                    }
                                                                                    mode={
                                                                                      'datetime'
                                                                                    }
                                                                                    format={
                                                                                      'dd mmm yy, hh:MM TT'
                                                                                    }
                                                                                  />
                                                                                </View>
                                                                              )}
                                                                            </>
                                                                            {/* Container */}
                                                                            <>
                                                                              {!(
                                                                                sessionsData?.id ===
                                                                                editableOneTimeSession
                                                                              ) ? null : (
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      alignItems:
                                                                                        'flex-start',
                                                                                      flexDirection:
                                                                                        'row',
                                                                                      justifyContent:
                                                                                        'center',
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  <Spacer
                                                                                    top={
                                                                                      8
                                                                                    }
                                                                                    bottom={
                                                                                      8
                                                                                    }
                                                                                    right={
                                                                                      4
                                                                                    }
                                                                                    left={
                                                                                      0
                                                                                    }
                                                                                  />
                                                                                  {/* save */}
                                                                                  <IconButton
                                                                                    onPress={() => {
                                                                                      try {
                                                                                        sortMultidaySessions(
                                                                                          seriesData?.series_no
                                                                                        );
                                                                                        setEditableOneTimeSession(
                                                                                          ''
                                                                                        );
                                                                                        setDatePickerValue(
                                                                                          getNow()
                                                                                        );
                                                                                      } catch (err) {
                                                                                        console.error(
                                                                                          err
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                    size={
                                                                                      16
                                                                                    }
                                                                                    icon={
                                                                                      'Feather/check-square'
                                                                                    }
                                                                                    color={
                                                                                      theme
                                                                                        .colors[
                                                                                        'Inactive Grey'
                                                                                      ]
                                                                                    }
                                                                                  />
                                                                                </View>
                                                                              )}
                                                                            </>
                                                                            {/* Container */}
                                                                            <>
                                                                              {sessionsData?.id ===
                                                                              editableOneTimeSession ? null : (
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      alignItems:
                                                                                        'flex-start',
                                                                                      flexDirection:
                                                                                        'row',
                                                                                      justifyContent:
                                                                                        'center',
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  <Spacer
                                                                                    top={
                                                                                      8
                                                                                    }
                                                                                    bottom={
                                                                                      8
                                                                                    }
                                                                                    left={
                                                                                      4
                                                                                    }
                                                                                    right={
                                                                                      0
                                                                                    }
                                                                                  />
                                                                                  {/* Edit */}
                                                                                  <IconButton
                                                                                    onPress={() => {
                                                                                      try {
                                                                                        setEditableOneTimeSession(
                                                                                          sessionsData?.id
                                                                                        );
                                                                                        setDatePickerValue(
                                                                                          new Date(
                                                                                            sessionsData?.start_time
                                                                                          )
                                                                                        );
                                                                                      } catch (err) {
                                                                                        console.error(
                                                                                          err
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                    icon={
                                                                                      'Feather/edit'
                                                                                    }
                                                                                    size={
                                                                                      16
                                                                                    }
                                                                                    color={
                                                                                      theme
                                                                                        .colors[
                                                                                        'Inactive Grey'
                                                                                      ]
                                                                                    }
                                                                                  />
                                                                                </View>
                                                                              )}
                                                                            </>
                                                                            <Spacer
                                                                              top={
                                                                                8
                                                                              }
                                                                              bottom={
                                                                                8
                                                                              }
                                                                              right={
                                                                                4
                                                                              }
                                                                              left={
                                                                                0
                                                                              }
                                                                            />
                                                                            {/* delete */}
                                                                            <IconButton
                                                                              onPress={() => {
                                                                                const handler =
                                                                                  async () => {
                                                                                    try {
                                                                                      deleteMultidaySession(
                                                                                        seriesData?.series_no,
                                                                                        sessionsData?.id
                                                                                      );
                                                                                      if (
                                                                                        !sessionsData?.created_at
                                                                                      ) {
                                                                                        return;
                                                                                      }
                                                                                      await supabaseRestAPIDeleteSessionDELETE.mutateAsync(
                                                                                        {
                                                                                          sess_id:
                                                                                            sessionsData?.id,
                                                                                        }
                                                                                      );
                                                                                    } catch (err) {
                                                                                      console.error(
                                                                                        err
                                                                                      );
                                                                                    }
                                                                                  };
                                                                                handler();
                                                                              }}
                                                                              color={
                                                                                theme
                                                                                  .colors[
                                                                                  'Inactive Grey'
                                                                                ]
                                                                              }
                                                                              size={
                                                                                16
                                                                              }
                                                                              icon={
                                                                                'Feather/trash-2'
                                                                              }
                                                                            />
                                                                          </View>
                                                                        )}
                                                                      </>
                                                                    </View>
                                                                  </>
                                                                );
                                                              }}
                                                              data={
                                                                seriesData?.sessions
                                                              }
                                                              listKey={JSON.stringify(
                                                                seriesData?.sessions
                                                              )}
                                                              keyExtractor={sessionsData =>
                                                                sessionsData?.id
                                                              }
                                                              contentContainerStyle={StyleSheet.applyWidth(
                                                                { flex: 1 },
                                                                dimensions.width
                                                              )}
                                                              numColumns={1}
                                                            />
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* New Session */}
                                                            <Button
                                                              onPress={() => {
                                                                try {
                                                                  addMultidaySession(
                                                                    seriesData?.series_no
                                                                  );
                                                                  setDatePickerValue(
                                                                    getNow()
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    [
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Mobile,
                                                                        value:
                                                                          theme
                                                                            .colors
                                                                            .primary,
                                                                      },
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Laptop,
                                                                        value:
                                                                          theme
                                                                            .colors[
                                                                            'Background'
                                                                          ],
                                                                      },
                                                                    ],
                                                                  borderColor: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      theme
                                                                        .colors[
                                                                        'Inactive Grey'
                                                                      ],
                                                                  },
                                                                  borderRadius:
                                                                    [
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Mobile,
                                                                        value: 8,
                                                                      },
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Laptop,
                                                                        value: 12,
                                                                      },
                                                                    ],
                                                                  borderWidth: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 1,
                                                                  },
                                                                  color: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      theme
                                                                        .colors[
                                                                        'Inactive Grey'
                                                                      ],
                                                                  },
                                                                  fontFamily:
                                                                    'System',
                                                                  fontWeight:
                                                                    '700',
                                                                  marginBottom:
                                                                    {
                                                                      minWidth:
                                                                        Breakpoints.Laptop,
                                                                      value: 4,
                                                                    },
                                                                  textAlign:
                                                                    'center',
                                                                },
                                                                dimensions.width
                                                              )}
                                                              title={
                                                                'New Session'
                                                              }
                                                              icon={
                                                                'AntDesign/plus'
                                                              }
                                                            />
                                                            {/* Duration */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'flex-start',
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Label Icon */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'AntDesign/clockcircleo'
                                                                  }
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      'Inactive Grey'
                                                                    ]
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={0}
                                                                />
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          'Light'
                                                                        ],
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 20,
                                                                      fontWeight:
                                                                        '400',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Duration'}
                                                                </Text>
                                                              </View>
                                                              <Spacer
                                                                right={8}
                                                                bottom={8}
                                                                left={8}
                                                                top={0}
                                                              />
                                                              {/* Input */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Minutes */}
                                                                <NumberInput
                                                                  onChangeText={newMinutesValue => {
                                                                    const numberInputValue =
                                                                      newMinutesValue;
                                                                    try {
                                                                      updateSeriesDuration(
                                                                        seriesData?.series_no,
                                                                        newMinutesValue
                                                                      );
                                                                    } catch (err) {
                                                                      console.error(
                                                                        err
                                                                      );
                                                                    }
                                                                  }}
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      backgroundColor:
                                                                        theme
                                                                          .colors[
                                                                          'Grey Lines'
                                                                        ],
                                                                      borderBottomWidth: 1,
                                                                      borderColor:
                                                                        theme
                                                                          .colors
                                                                          .divider,
                                                                      borderLeftWidth: 1,
                                                                      borderRadius: 8,
                                                                      borderRightWidth: 1,
                                                                      borderTopWidth: 1,
                                                                      paddingBottom: 8,
                                                                      paddingLeft: 8,
                                                                      paddingRight: 8,
                                                                      paddingTop: 8,
                                                                      width: 40,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                  maxLength={3}
                                                                  selectTextOnFocus={
                                                                    true
                                                                  }
                                                                  editable={
                                                                    true
                                                                  }
                                                                  placeholder={
                                                                    'Enter a number...'
                                                                  }
                                                                  defaultValue={
                                                                    seriesData?.durationPerSession
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={8}
                                                                />
                                                                {/* Label */}
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Minutes'}
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={0}
                                                            />
                                                            {/* Error */}
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme
                                                                      .colors[
                                                                      'Error'
                                                                    ],
                                                                  fontSize: 12,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                seriesData?.error
                                                              }
                                                            </Text>
                                                            {/* Save */}
                                                            <Button
                                                              onPress={() => {
                                                                try {
                                                                  if (
                                                                    validateSeries()
                                                                  ) {
                                                                    return;
                                                                  }
                                                                  setEditableSeries(
                                                                    ''
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    theme.colors
                                                                      .primary,
                                                                  borderRadius: 8,
                                                                  fontFamily:
                                                                    'System',
                                                                  fontWeight:
                                                                    '700',
                                                                  textAlign:
                                                                    'center',
                                                                  width: '90%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                              title={'Save'}
                                                            />
                                                          </View>
                                                        )}
                                                      </>
                                                      {/* Editable Card */}
                                                      <>
                                                        {!(
                                                          seriesData?.series_no !==
                                                          editableSeries
                                                        ) ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'flex-start',
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    'Background'
                                                                  ],
                                                                borderBottomWidth: 1,
                                                                borderColor:
                                                                  theme.colors[
                                                                    'Grey Lines'
                                                                  ],
                                                                borderLeftWidth: 1,
                                                                borderRightWidth: 1,
                                                                borderTopWidth: 1,
                                                                marginRight: 10,
                                                                paddingBottom: 20,
                                                                paddingLeft: 12,
                                                                paddingRight: 12,
                                                                paddingTop: 20,
                                                                width: 320,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  justifyContent:
                                                                    'space-between',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'Series #'}
                                                                {
                                                                  seriesData?.series_no
                                                                }
                                                              </Text>

                                                              <Touchable
                                                                onPress={() => {
                                                                  try {
                                                                    setDeletingSeriesId(
                                                                      seriesData?.series_no
                                                                    );
                                                                    setDeleteSeriesIntent(
                                                                      true
                                                                    );
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <Icon
                                                                  size={12}
                                                                  name={
                                                                    'Entypo/circle-with-cross'
                                                                  }
                                                                />
                                                              </Touchable>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Sessions */}
                                                            <>
                                                              {!seriesData
                                                                ?.sessions
                                                                ?.length ? null : (
                                                                <FlatList
                                                                  renderItem={({
                                                                    item,
                                                                  }) => {
                                                                    const sessionsData =
                                                                      item;
                                                                    return (
                                                                      <>
                                                                        {/* Row */}
                                                                        <>
                                                                          {isPastSession(
                                                                            sessionsData
                                                                          ) ? null : (
                                                                            <View
                                                                              style={StyleSheet.applyWidth(
                                                                                {
                                                                                  alignItems:
                                                                                    'center',
                                                                                  flexDirection:
                                                                                    'row',
                                                                                  marginBottom:
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Laptop,
                                                                                      value: 8,
                                                                                    },
                                                                                },
                                                                                dimensions.width
                                                                              )}
                                                                            >
                                                                              {/* Date time container */}
                                                                              <View
                                                                                style={StyleSheet.applyWidth(
                                                                                  {
                                                                                    alignContent:
                                                                                      'flex-end',
                                                                                    alignItems:
                                                                                      'flex-start',
                                                                                    flexDirection:
                                                                                      'row',
                                                                                  },
                                                                                  dimensions.width
                                                                                )}
                                                                              >
                                                                                {/* Date */}
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      alignItems:
                                                                                        'center',
                                                                                      backgroundColor:
                                                                                        theme
                                                                                          .colors[
                                                                                          'Grey Lines'
                                                                                        ],
                                                                                      borderRadius: 8,
                                                                                      flexDirection:
                                                                                        'row',
                                                                                      paddingBottom: 4,
                                                                                      paddingLeft: 8,
                                                                                      paddingRight: 8,
                                                                                      paddingTop: 4,
                                                                                      width:
                                                                                        {
                                                                                          minWidth:
                                                                                            Breakpoints.Laptop,
                                                                                          value: 150,
                                                                                        },
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  <Text
                                                                                    style={StyleSheet.applyWidth(
                                                                                      {
                                                                                        color:
                                                                                          theme
                                                                                            .colors
                                                                                            .strong,
                                                                                        fontFamily:
                                                                                          'System',
                                                                                        fontWeight:
                                                                                          '600',
                                                                                      },
                                                                                      dimensions.width
                                                                                    )}
                                                                                  >
                                                                                    {getFormattedDate(
                                                                                      sessionsData
                                                                                    )}
                                                                                  </Text>
                                                                                  <Spacer
                                                                                    top={
                                                                                      8
                                                                                    }
                                                                                    right={
                                                                                      8
                                                                                    }
                                                                                    bottom={
                                                                                      8
                                                                                    }
                                                                                    left={
                                                                                      8
                                                                                    }
                                                                                  />
                                                                                  <Icon
                                                                                    name={
                                                                                      'AntDesign/calendar'
                                                                                    }
                                                                                    size={
                                                                                      16
                                                                                    }
                                                                                    color={
                                                                                      theme
                                                                                        .colors[
                                                                                        'Strong'
                                                                                      ]
                                                                                    }
                                                                                  />
                                                                                </View>
                                                                                <Spacer
                                                                                  top={
                                                                                    8
                                                                                  }
                                                                                  right={
                                                                                    8
                                                                                  }
                                                                                  bottom={
                                                                                    8
                                                                                  }
                                                                                  left={
                                                                                    8
                                                                                  }
                                                                                />
                                                                                {/* time */}
                                                                                <View
                                                                                  style={StyleSheet.applyWidth(
                                                                                    {
                                                                                      alignItems:
                                                                                        'center',
                                                                                      backgroundColor:
                                                                                        theme
                                                                                          .colors[
                                                                                          'Grey Lines'
                                                                                        ],
                                                                                      borderRadius: 8,
                                                                                      flexDirection:
                                                                                        'row',
                                                                                      paddingBottom:
                                                                                        {
                                                                                          minWidth:
                                                                                            Breakpoints.Laptop,
                                                                                          value: 4,
                                                                                        },
                                                                                      paddingLeft: 8,
                                                                                      paddingRight: 8,
                                                                                      paddingTop:
                                                                                        {
                                                                                          minWidth:
                                                                                            Breakpoints.Laptop,
                                                                                          value: 4,
                                                                                        },
                                                                                      width:
                                                                                        {
                                                                                          minWidth:
                                                                                            Breakpoints.Laptop,
                                                                                          value: 100,
                                                                                        },
                                                                                    },
                                                                                    dimensions.width
                                                                                  )}
                                                                                >
                                                                                  <Text
                                                                                    style={StyleSheet.applyWidth(
                                                                                      {
                                                                                        color:
                                                                                          theme
                                                                                            .colors
                                                                                            .strong,
                                                                                        fontFamily:
                                                                                          'System',
                                                                                        fontSize: 13,
                                                                                        fontWeight:
                                                                                          '600',
                                                                                      },
                                                                                      dimensions.width
                                                                                    )}
                                                                                  >
                                                                                    {getFormattedTime(
                                                                                      sessionsData
                                                                                    )}
                                                                                  </Text>
                                                                                  <Spacer
                                                                                    top={
                                                                                      8
                                                                                    }
                                                                                    right={
                                                                                      8
                                                                                    }
                                                                                    bottom={
                                                                                      0
                                                                                    }
                                                                                    left={
                                                                                      0
                                                                                    }
                                                                                  />
                                                                                  <Icon
                                                                                    name={
                                                                                      'EvilIcons/clock'
                                                                                    }
                                                                                    size={
                                                                                      16
                                                                                    }
                                                                                    color={
                                                                                      theme
                                                                                        .colors[
                                                                                        'Strong'
                                                                                      ]
                                                                                    }
                                                                                  />
                                                                                </View>
                                                                              </View>
                                                                            </View>
                                                                          )}
                                                                        </>
                                                                      </>
                                                                    );
                                                                  }}
                                                                  data={(() => {
                                                                    const e =
                                                                      seriesData?.sessions;
                                                                    console.log(
                                                                      e
                                                                    );
                                                                    return e;
                                                                  })()}
                                                                  listKey={JSON.stringify(
                                                                    (() => {
                                                                      const e =
                                                                        seriesData?.sessions;
                                                                      console.log(
                                                                        e
                                                                      );
                                                                      return e;
                                                                    })()
                                                                  )}
                                                                  keyExtractor={sessionsData =>
                                                                    sessionsData?.id
                                                                  }
                                                                  contentContainerStyle={StyleSheet.applyWidth(
                                                                    { flex: 1 },
                                                                    dimensions.width
                                                                  )}
                                                                  numColumns={1}
                                                                />
                                                              )}
                                                            </>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={8}
                                                            />
                                                            {/* Duration */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'flex-start',
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Label Icon */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    'AntDesign/clockcircleo'
                                                                  }
                                                                />
                                                                <Spacer
                                                                  top={8}
                                                                  right={8}
                                                                  bottom={8}
                                                                  left={0}
                                                                />
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          'Light'
                                                                        ],
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 20,
                                                                      fontWeight:
                                                                        '400',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {'Duration'}
                                                                </Text>
                                                              </View>
                                                              <Spacer
                                                                right={8}
                                                                bottom={8}
                                                                left={8}
                                                                top={0}
                                                              />
                                                              {/* time */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    backgroundColor:
                                                                      theme
                                                                        .colors[
                                                                        'Grey Lines'
                                                                      ],
                                                                    borderRadius: 8,
                                                                    flexDirection:
                                                                      'row',
                                                                    justifyContent:
                                                                      'center',
                                                                    paddingBottom: 4,
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    paddingTop: 4,
                                                                    width: 50,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Text
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors
                                                                          .strong,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 13,
                                                                      fontWeight:
                                                                        '600',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    seriesData?.durationPerSession
                                                                  }
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            <Spacer
                                                              top={8}
                                                              right={8}
                                                              bottom={8}
                                                              left={0}
                                                            />
                                                            {/* Error */}
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme
                                                                      .colors[
                                                                      'Error'
                                                                    ],
                                                                  fontSize: 12,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                seriesData?.error
                                                              }
                                                            </Text>
                                                            {/* Edit */}
                                                            <Button
                                                              onPress={() => {
                                                                try {
                                                                  if (
                                                                    editableSeries !==
                                                                    ''
                                                                  ) {
                                                                    return;
                                                                  }
                                                                  setEditableSeries(
                                                                    seriesData?.series_no
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    theme.colors
                                                                      .primary,
                                                                  borderRadius: 8,
                                                                  fontFamily:
                                                                    'System',
                                                                  fontWeight:
                                                                    '700',
                                                                  textAlign:
                                                                    'center',
                                                                  width: '90%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                              title={'Edit'}
                                                            />
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                          {/* Past */}
                                          <>
                                            {!(oneTimeTabI === 0) ? null : (
                                              <View>
                                                {/* Editable Card */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'flex-end',
                                                      backgroundColor:
                                                        theme.colors[
                                                          'Background'
                                                        ],
                                                      borderBottomWidth: 1,
                                                      borderColor:
                                                        theme.colors[
                                                          'Grey Lines'
                                                        ],
                                                      borderLeftWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderTopWidth: 1,
                                                      marginRight: 10,
                                                      paddingBottom: 20,
                                                      paddingLeft: 12,
                                                      paddingRight: 12,
                                                      paddingTop: 20,
                                                      width: 320,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            theme.colors.strong,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Series #'}
                                                      {null}
                                                    </Text>

                                                    <Touchable
                                                      onPress={() => {
                                                        try {
                                                          deleteASeries(
                                                            undefined
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                    >
                                                      <Icon
                                                        size={12}
                                                        name={
                                                          'Entypo/circle-with-cross'
                                                        }
                                                      />
                                                    </Touchable>
                                                  </View>
                                                  <Spacer
                                                    top={8}
                                                    right={8}
                                                    bottom={8}
                                                    left={8}
                                                  />
                                                  {/* Sessions */}
                                                  <FlatList
                                                    renderItem={({ item }) => {
                                                      const sessionsData = item;
                                                      return (
                                                        <>
                                                          {/* Row */}
                                                          <>
                                                            {!isPastSession(
                                                              sessionsData
                                                            ) ? null : (
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Date time container */}
                                                                <>
                                                                  {sessionsData ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          alignContent:
                                                                            'flex-end',
                                                                          alignItems:
                                                                            'center',
                                                                          flexDirection:
                                                                            'row',
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      {/* Date */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              theme
                                                                                .colors[
                                                                                'Grey Lines'
                                                                              ],
                                                                            borderRadius: 8,
                                                                            flexDirection:
                                                                              'row',
                                                                            paddingBottom: 3,
                                                                            paddingLeft: 3,
                                                                            paddingRight: 3,
                                                                            paddingTop: 3,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          style={StyleSheet.applyWidth(
                                                                            {
                                                                              color:
                                                                                theme
                                                                                  .colors
                                                                                  .strong,
                                                                              fontFamily:
                                                                                'System',
                                                                              fontWeight:
                                                                                '600',
                                                                            },
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {getFormattedDate(
                                                                            sessionsData
                                                                          )}
                                                                        </Text>
                                                                        <Spacer
                                                                          top={
                                                                            8
                                                                          }
                                                                          right={
                                                                            8
                                                                          }
                                                                          bottom={
                                                                            8
                                                                          }
                                                                          left={
                                                                            8
                                                                          }
                                                                        />
                                                                        <Icon
                                                                          size={
                                                                            24
                                                                          }
                                                                          name={
                                                                            'AntDesign/calendar'
                                                                          }
                                                                        />
                                                                      </View>
                                                                      <Spacer
                                                                        top={8}
                                                                        right={
                                                                          8
                                                                        }
                                                                        bottom={
                                                                          8
                                                                        }
                                                                        left={8}
                                                                      />
                                                                      {/* time */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              theme
                                                                                .colors[
                                                                                'Grey Lines'
                                                                              ],
                                                                            borderRadius: 8,
                                                                            flexDirection:
                                                                              'row',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          style={StyleSheet.applyWidth(
                                                                            {
                                                                              color:
                                                                                theme
                                                                                  .colors
                                                                                  .strong,
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 13,
                                                                              fontWeight:
                                                                                '600',
                                                                            },
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {getFormattedTime(
                                                                            sessionsData
                                                                          )}
                                                                        </Text>
                                                                        <Spacer
                                                                          top={
                                                                            8
                                                                          }
                                                                          right={
                                                                            8
                                                                          }
                                                                          bottom={
                                                                            8
                                                                          }
                                                                          left={
                                                                            8
                                                                          }
                                                                        />
                                                                        <Icon
                                                                          size={
                                                                            24
                                                                          }
                                                                          name={
                                                                            'EvilIcons/clock'
                                                                          }
                                                                        />
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                              </View>
                                                            )}
                                                          </>
                                                        </>
                                                      );
                                                    }}
                                                    data={[]}
                                                    listKey={JSON.stringify([])}
                                                    keyExtractor={sessionsData =>
                                                      sessionsData?.id
                                                    }
                                                    contentContainerStyle={StyleSheet.applyWidth(
                                                      { flex: 1 },
                                                      dimensions.width
                                                    )}
                                                    numColumns={1}
                                                  />
                                                  <Spacer
                                                    top={8}
                                                    right={8}
                                                    bottom={8}
                                                    left={8}
                                                  />
                                                  {/* Duration */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignSelf: 'flex-start',
                                                        flex: 1,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Label Icon */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        name={
                                                          'AntDesign/clockcircleo'
                                                        }
                                                      />
                                                      <Spacer
                                                        top={8}
                                                        right={8}
                                                        bottom={8}
                                                        left={0}
                                                      />
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Light'
                                                              ],
                                                            fontFamily:
                                                              'System',
                                                            fontSize: 20,
                                                            fontWeight: '400',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Duration'}
                                                      </Text>
                                                    </View>
                                                    <Spacer
                                                      right={8}
                                                      bottom={8}
                                                      left={8}
                                                      top={0}
                                                    />
                                                    {/* time */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            theme.colors[
                                                              'Grey Lines'
                                                            ],
                                                          borderRadius: 8,
                                                          flexDirection: 'row',
                                                          justifyContent:
                                                            'center',
                                                          width: 50,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .strong,
                                                            fontFamily:
                                                              'System',
                                                            fontSize: 13,
                                                            fontWeight: '600',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {null}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                        </>
                                      );
                                    }}
                                    data={(() => {
                                      const e = formObj?.series;
                                      console.log(e);
                                      return e;
                                    })()}
                                    listKey={'PDZrsk1g'}
                                    keyExtractor={seriesData => seriesData?.id}
                                    style={StyleSheet.applyWidth(
                                      { flexGrow: 0 },
                                      dimensions.width
                                    )}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        flexShrink: 1,
                                        flexWrap: 'wrap',
                                        marginBottom: 10,
                                        marginTop: 10,
                                      },
                                      dimensions.width
                                    )}
                                    numColumns={1}
                                  />
                                )}
                              </>
                              {/* Add new */}
                              <>
                                {!(oneTimeTabI === 1) ? null : (
                                  <Button
                                    onPress={() => {
                                      try {
                                        if (editableSeries !== '') {
                                          return;
                                        }
                                        addNewSeries();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors['Grey Lines'],
                                        borderRadius: 8,
                                        color: theme.colors['Inactive Grey'],
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        height: 270,
                                        textAlign: 'center',
                                        width: 150,
                                      },
                                      dimensions.width
                                    )}
                                    icon={'Entypo/new-message'}
                                    title={'Create New Series'}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Pricing */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Custom Color'],
                            fontFamily: 'System',
                            fontSize: 20,
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Pricing'}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors.strong, marginTop: 16 },
                          dimensions.width
                        )}
                      >
                        {
                          'The first price is what you would like to charge for the class\nThe second price is with City as a School fees add. This is what parents and learners see. \nWe suggest around $15-20 per hour. Pricing will vary based on class length, format, and teacher'
                        }
                      </Text>
                      {/* per class */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 16 },
                          dimensions.width
                        )}
                      >
                        {/* sec head */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '300',
                            },
                            dimensions.width
                          )}
                        >
                          {'Pricing per class\n'}
                        </Text>
                        {/* Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* price */}
                          <NumberInput
                            onChangeText={newPriceValue => {
                              try {
                                setPriceValue(newPriceValue);
                                setFormObj(
                                  setFieldValue(
                                    newPriceValue,
                                    'pricing_per_session',
                                    formObj
                                  )
                                );
                                const act = getActualValue(
                                  Variables,
                                  newPriceValue
                                );
                                setActPrice(act);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Grey Lines'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                                width: 60,
                              },
                              dimensions.width
                            )}
                            value={priceValue}
                            editable={true}
                            maxLength={4}
                            placeholder={'0'}
                            selectTextOnFocus={true}
                          />
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong },
                              dimensions.width
                            )}
                          >
                            {'USD'}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          {/* act price */}
                          <NumberInput
                            onChangeText={newActPriceValue => {
                              try {
                                setActPrice(newActPriceValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Grey Lines'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                                width: 60,
                              },
                              dimensions.width
                            )}
                            value={actPrice}
                            editable={true}
                            maxLength={4}
                            disabled={true}
                            placeholder={'0'}
                          />
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong },
                              dimensions.width
                            )}
                          >
                            {'USD'}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                        </View>
                      </View>
                      {/* whole series */}
                      <>
                        {isOneTime ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 12 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Custom Color'],
                                  fontFamily: 'System',
                                  fontSize: 20,
                                  fontWeight: '300',
                                },
                                dimensions.width
                              )}
                            >
                              {'Whole Series\n'}
                            </Text>
                            {/* Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* whole price */}
                              <NumberInput
                                onChangeText={newWholePriceValue => {
                                  try {
                                    setWholePriceValue(newWholePriceValue);
                                    setFormObj(
                                      setFieldValue(
                                        newWholePriceValue,
                                        'pricing_per_series',
                                        formObj
                                      )
                                    );
                                    const act = getActualValue(
                                      Variables,
                                      newWholePriceValue
                                    );
                                    setWholeActPriceValue(act);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Grey Lines'],
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                    width: 60,
                                  },
                                  dimensions.width
                                )}
                                value={wholePriceValue}
                                editable={true}
                                maxLength={4}
                                placeholder={'0'}
                                selectTextOnFocus={true}
                                clearTextOnFocus={false}
                              />
                              <Spacer top={8} right={8} bottom={8} left={8} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong },
                                  dimensions.width
                                )}
                              >
                                {'USD'}
                              </Text>
                              <Spacer top={8} right={8} bottom={8} left={8} />
                              {/* whole act price */}
                              <NumberInput
                                onChangeText={newWholeActPriceValue => {
                                  try {
                                    setWholeActPriceValue(
                                      newWholeActPriceValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors['Grey Lines'],
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                    width: 60,
                                  },
                                  dimensions.width
                                )}
                                value={wholeActPriceValue}
                                editable={true}
                                maxLength={4}
                                disabled={true}
                                placeholder={'0'}
                              />
                              <Spacer top={8} right={8} bottom={8} left={8} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong },
                                  dimensions.width
                                )}
                              >
                                {'USD'}
                              </Text>
                              <Spacer top={8} right={8} bottom={8} left={8} />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Error */}
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors['Error'], fontSize: 12 },
                        dimensions.width
                      )}
                    >
                      {scheduleTabError}
                    </Text>
                    <Spacer right={8} bottom={8} left={8} top={8} />
                    {/* Action container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Save Schedule */}
                      <Button
                        onPress={() => {
                          try {
                            if (validateScheduleTab()) {
                              return;
                            }
                            setTopTabI(3);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            marginTop: 40,
                            textAlign: 'center',
                            width: 250,
                          },
                          dimensions.width
                        )}
                        title={'Continue'}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Setting Tab Frame */}
              <>
                {!(topTabI === 3) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-start', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* Range */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Custom Color'],
                            fontFamily: 'System',
                            fontSize: 20,
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Age Ranges'}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors.strong, marginTop: 16 },
                          dimensions.width
                        )}
                      >
                        {
                          'Age ranges cannot be exceeded 5 years for group classes. Learners trend to thrive in classes with others who match their developmental stage. We therefor recommend narrow age ranges between 3-4 years'
                        }
                      </Text>
                      {/* Age */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {/* min */}
                        <NumberInput
                          onChangeText={newMinValue => {
                            try {
                              setFormObj(
                                setFieldValue(
                                  newMinValue,
                                  'ageRangeMin',
                                  formObj
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Grey Lines'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                              width: 60,
                            },
                            dimensions.width
                          )}
                          editable={true}
                          maxLength={4}
                          placeholder={'0'}
                          defaultValue={formObj?.ageRangeMin?.value}
                        />
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.strong },
                            dimensions.width
                          )}
                        >
                          {'to'}
                        </Text>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* max */}
                        <NumberInput
                          onChangeText={newMaxValue => {
                            try {
                              setFormObj(
                                setFieldValue(
                                  newMaxValue,
                                  'ageRangeMax',
                                  formObj
                                )
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Grey Lines'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                              width: 60,
                            },
                            dimensions.width
                          )}
                          editable={true}
                          maxLength={4}
                          placeholder={'0'}
                          disabled={false}
                          defaultValue={formObj?.ageRangeMax?.value}
                        />
                        <Spacer bottom={8} left={8} top={8} right={8} />
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.strong },
                            dimensions.width
                          )}
                        >
                          {'years old'}
                        </Text>
                      </View>
                      {/* Error */}
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error'], fontSize: 12 },
                          dimensions.width
                        )}
                      >
                        {getRangeError('age')}
                      </Text>
                      {/* Capacity */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-start', marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color'],
                              fontFamily: 'System',
                              fontSize: 20,
                              fontWeight: '400',
                            },
                            dimensions.width
                          )}
                        >
                          {'Min and max number of learners'}
                        </Text>

                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.strong, marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          {
                            'If a minimum number of participants is not reached by the start date a refund will be automatically issued to the family.'
                          }
                        </Text>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* min */}
                          <NumberInput
                            onChangeText={newMinValue => {
                              try {
                                setFormObj(
                                  setFieldValue(
                                    newMinValue,
                                    'capacityRangeMin',
                                    formObj
                                  )
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Grey Lines'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                                width: 60,
                              },
                              dimensions.width
                            )}
                            editable={true}
                            maxLength={4}
                            placeholder={'0'}
                            defaultValue={formObj?.capacityRangeMin?.value}
                          />
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong },
                              dimensions.width
                            )}
                          >
                            {'to'}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          {/* max */}
                          <NumberInput
                            onChangeText={newMaxValue => {
                              try {
                                setFormObj(
                                  setFieldValue(
                                    newMaxValue,
                                    'capacityRangeMax',
                                    formObj
                                  )
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Grey Lines'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                                width: 60,
                              },
                              dimensions.width
                            )}
                            editable={true}
                            maxLength={4}
                            placeholder={'0'}
                            disabled={false}
                            defaultValue={formObj?.capacityRangeMax?.value}
                          />
                          <Spacer bottom={8} left={8} top={8} right={8} />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong },
                              dimensions.width
                            )}
                          >
                            {'Learners'}
                          </Text>
                        </View>
                        {/* Error */}
                        <Text
                          style={StyleSheet.applyWidth(
                            { color: theme.colors['Error'], fontSize: 12 },
                            dimensions.width
                          )}
                        >
                          {getRangeError('load')}
                        </Text>
                      </View>
                    </View>
                    <Spacer right={8} bottom={8} left={8} top={8} />
                    {/* Status */}
                    <View>
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: {
                                minWidth: Breakpoints.Desktop,
                                value: theme.colors['Custom Color'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'System',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Desktop,
                                value: 20,
                              },
                              fontWeight: {
                                minWidth: Breakpoints.Desktop,
                                value: '400',
                              },
                              marginBottom: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Status'}
                      </Text>
                      {/* Status */}
                      <Picker
                        onValueChange={newStatusValue => {
                          try {
                            setStatusValue(newStatusValue);
                            setLXPStatus(newStatusValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Grey Lines'],
                            borderColor: '"rgba(0, 0, 0, 0)"',
                            color: theme.colors['Strong'],
                            height: 50,
                          },
                          dimensions.width
                        )}
                        options={['active', 'archived', 'draft']}
                        value={statusValue}
                        leftIconMode={'inset'}
                        type={'solid'}
                        iconSize={24}
                        autoDismissKeyboard={true}
                        rightIconName={'MaterialIcons/keyboard-arrow-down'}
                      />
                    </View>
                    {/* Create Update */}
                    <>
                      {isSubmitting ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Publish */}
                          <>
                            {!(mode === 'Create') ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      if (validateSettingTab()) {
                                        return;
                                      }
                                      setIsSubmitting(true);
                                      const lxpRes =
                                        await supabaseRestAPIRequest$UpdateALXPForListingPOST.mutateAsync(
                                          {
                                            archived: false,
                                            author_id: Constants['UUID'],
                                            desc: formObj?.description.value,
                                            difficulty:
                                              formObj?.difficulty.value,
                                            imageURL: '',
                                            isOneTime: isOneTime,
                                            isOnline:
                                              formObj?.location_type?.value ===
                                              'zoom',
                                            learning_center_id: nullify(
                                              formObj?.learning_center_id?.value
                                            ),
                                            location_type:
                                              formObj?.location_type?.value,
                                            max_age: formObj?.ageRangeMax.value,
                                            max_part:
                                              formObj?.capacityRangeMax.value,
                                            min_age: formObj?.ageRangeMin.value,
                                            min_part:
                                              formObj?.capacityRangeMin.value,
                                            price_ser:
                                              formObj?.pricing_per_series.value,
                                            price_sess:
                                              formObj?.pricing_per_session
                                                .value,
                                            req_resources:
                                              formObj?.requiredResources.value,
                                            status: formObj?.status.value,
                                            subject: formObj?.subject.value,
                                            title: formObj?.title.value,
                                            uuid: formObj?.id.value,
                                            zoom_id: formObj?.zoom_id.value,
                                            zoom_password:
                                              formObj?.zoom_password.value,
                                            zoom_url: formObj?.zoom_url.value,
                                          }
                                        );
                                      if (stopForApiRes(lxpRes)) {
                                        return;
                                      }
                                      const imgPath = await uploadImage(
                                        formObj?.coverImage.value
                                      );
                                      await supabaseRestAPIAddCoverImageToLXPPATCH.mutateAsync(
                                        {
                                          imageURL: imgPath,
                                          lxp_id: formObj?.id.value,
                                        }
                                      );
                                      await SupabaseRESTAPIAuthOptionalApi.upsertSessionPOST(
                                        Constants,
                                        { arr: parseSessionsForApiCall() }
                                      );
                                      setIsSubmitting(false);
                                      setTopTabI(0);
                                      navigation.navigate(
                                        'HomeScreen_0FrGgFCx'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors.primary,
                                    borderRadius: 8,
                                    fontFamily: 'System',
                                    fontWeight: '700',
                                    marginTop: 40,
                                    textAlign: 'center',
                                    width: 250,
                                  },
                                  dimensions.width
                                )}
                                title={'Publish'}
                              />
                            )}
                          </>
                          {/* Update */}
                          <>
                            {!(mode === 'Edit') ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      if (validateSettingTab()) {
                                        return;
                                      }
                                      setIsSubmitting(true);
                                      const lxpRes =
                                        await supabaseRestAPIRequest$UpdateALXPForListingPOST.mutateAsync(
                                          {
                                            archived: formObj?.archived,
                                            author_id: Constants['UUID'],
                                            desc: formObj?.description.value,
                                            difficulty:
                                              formObj?.difficulty.value,
                                            imageURL: formObj?.coverImage.value,
                                            isOneTime: isOneTime,
                                            isOnline:
                                              formObj?.location_type?.value ===
                                              'zoom',
                                            learning_center_id: nullify(
                                              formObj?.learning_center_id?.value
                                            ),
                                            location_type:
                                              formObj?.location_type?.value,
                                            max_age: formObj?.ageRangeMax.value,
                                            max_part:
                                              formObj?.capacityRangeMax.value,
                                            min_age: formObj?.ageRangeMin.value,
                                            min_part:
                                              formObj?.capacityRangeMin.value,
                                            price_ser:
                                              formObj?.pricing_per_series.value,
                                            price_sess:
                                              formObj?.pricing_per_session
                                                .value,
                                            req_resources:
                                              formObj?.requiredResources.value,
                                            status: formObj?.status.value,
                                            subject: formObj?.subject.value,
                                            title: formObj?.title.value,
                                            uuid: formObj?.id.value,
                                            zoom_id: formObj?.zoom_id.value,
                                            zoom_password:
                                              formObj?.zoom_password.value,
                                            zoom_url: formObj?.zoom_url.value,
                                          }
                                        );
                                      if (stopForApiRes(lxpRes)) {
                                        return;
                                      }
                                      await SupabaseRESTAPIAuthOptionalApi.upsertSessionPOST(
                                        Constants,
                                        { arr: parseSessionsForApiCall() }
                                      );
                                      setIsSubmitting(false);
                                      setGlobalVariableValue({
                                        key: 'CURRENT_SCREEN',
                                        value: 'HOME',
                                      });
                                      navigation.navigate(
                                        'HomeScreen_0FrGgFCx'
                                      );
                                      setTopTabI(0);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: theme.colors.primary,
                                    borderRadius: 8,
                                    fontFamily: 'System',
                                    fontWeight: '700',
                                    marginTop: 40,
                                    textAlign: 'center',
                                    width: 250,
                                  },
                                  dimensions.width
                                )}
                                title={'Update'}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                    <View>
                      {/* Request Listing Disabled */}
                      <>
                        {!isSubmitting ? null : (
                          <Button
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            loading={true}
                            disabled={true}
                            title={''}
                          />
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
            </View>
          </KeyboardAwareScrollView>
        )}
      </>
      <>
        {!fetchingSubjects ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={'large'}
          />
        )}
      </>
      {/* series delete modal */}
      <>
        {!deleteSeriesIntent ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Medium'],
                bottom: 0,
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100%',
                zIndex: 12,
              },
              dimensions.width
            )}
          >
            {/* Body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.colors['Background'],
                  borderRadius: 8,
                  justifyContent: 'center',
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                },
                dimensions.width
              )}
            >
              {/* Header editable */}
              <>
                {!isEditing() ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontSize: 18,
                        marginBottom: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Do you want to delete whole series?'}
                  </Text>
                )}
              </>
              {/* Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 40,
                    justifyContent: 'space-between',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Delete */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Grey Lines'],
                      flex: 1,
                      height: [
                        { minWidth: Breakpoints.Mobile, value: '80%' },
                        { minWidth: Breakpoints.Laptop, value: '80%' },
                      ],
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  {/* Confim */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setIsDeletingSeries(true);
                          const sessionsToDelete =
                            deleteASeries(deletingSeriesId);
                          await supabaseRestAPIDeleteMultipleSessionsDELETE.mutateAsync(
                            { sess_ids: arrayItemsJoin(sessionsToDelete) }
                          );
                          setIsDeletingSeries(false);
                          setDeleteSeriesIntent(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Error'],
                        },
                        borderRadius: 8,
                        color: theme.colors['Background'],
                        fontFamily: 'System',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        fontWeight: '700',
                        textAlign: 'center',
                        width: { minWidth: Breakpoints.Laptop, value: 140 },
                      },
                      dimensions.width
                    )}
                    disabled={!deleteSeriesIntent}
                    loading={isDeletingSeries}
                    title={'Confirm'}
                  />
                </View>
                <Spacer top={8} right={8} bottom={8} left={8} />
                {/* Cancel */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.colors['Primary'],
                      flex: 1,
                      height: '80%',
                      justifyContent: 'center',
                      paddingBottom: 20,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 20,
                      width: 140,
                    },
                    dimensions.width
                  )}
                >
                  {/* cancel */}
                  <Touchable
                    onPress={() => {
                      try {
                        setDeleteSeriesIntent(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors['Background'] },
                        dimensions.width
                      )}
                    >
                      {'Cancel\n'}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(CreateUpdateALearningExperienceScreen);
