import React from 'react';
import * as CustomCode from '../custom-files/CustomCode';

const searchLearningCenters = async query => {
  const { data, error } = await CustomCode.supabase
    .from('learning_center')
    .select()
    .or(`name.ilike.${query}%,name.ilike.%${query}%`);
  // .textSearch("name", query, {
  //     type: 'plain',
  //     config: 'english'
  // })

  console.log('search Learning Center error', error, 'query', query);
  if (data?.length) return data;

  return [];
};

export default searchLearningCenters;
