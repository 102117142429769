import React from 'react';
import * as SupabaseEdgeFunctionsApi from '../apis/SupabaseEdgeFunctionsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const RequestPasswordLinkScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getLoginLabel = () => {
    return isSubmitting ? 'Logging In' : 'Log In';
  };

  const checkRedirect = () => {
    if (redirectObj) {
      // if (redirectObj.param)
      //     props.navigation.navigate(redirectObj.path, {...redirectObj.param})
      // else
      props.navigation.navigate(redirectObj);
      return true;
    }

    return false;
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShowMessage(false);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { alignItems: 'center', justifyContent: 'center' },
        dimensions.width
      )}
      hasSafeArea={false}
      scrollable={false}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: 350,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('WelcomeScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              color={theme.colors['Custom Color']}
              icon={'MaterialIcons/chevron-left'}
              size={32}
            />
          </View>
          <Spacer top={8} right={8} bottom={8} left={32} />
          <Text
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                color: theme.colors.strong,
                fontFamily: 'System',
                fontSize: 24,
                fontWeight: '600',
                marginLeft: 12,
              },
              dimensions.width
            )}
          >
            {'Request Password'}
          </Text>
        </View>
        <Spacer right={8} bottom={8} left={8} top={20} />
        {/* Email Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Inactive Grey'],
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '400',
                  marginBottom: 6,
                },
                dimensions.width
              )}
            >
              {'Enter your registered email address'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setEmailInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Grey Lines'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              defaultValue={emailInputValue}
            />
            {/* Error */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Error'], fontSize: 12 },
                dimensions.width
              )}
            >
              {null}
            </Text>
          </View>
        </View>
        <Spacer right={8} bottom={8} left={8} top={16} />
        {/* Button Frame */}
        <View>
          {/* Error Above */}
          <Text
            style={StyleSheet.applyWidth(
              { color: theme.colors['Error'], fontSize: 12 },
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
          {/* Email Login */}
          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  setIsSubmitting(true);
                  const apiResponse =
                    await SupabaseEdgeFunctionsApi.requestPasswordResetPOST(
                      Constants,
                      { email: emailInputValue }
                    );
                  setIsSubmitting(false);
                  setGlobalVariableValue({
                    key: 'EMAIL',
                    value: emailInputValue,
                  });

                  const valueF4QAr69P = apiResponse?.msg;
                  setErrorMessage(valueF4QAr69P);
                  const errorMessageResult = valueF4QAr69P;
                  if (errorMessageResult) {
                    return;
                  }
                  setShowMessage(true);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Primary'],
                borderRadius: 8,
                fontFamily: 'System',
                fontWeight: '700',
                opacity: 1,
                textAlign: 'center',
                width: 350,
              },
              dimensions.width
            )}
            disabled={!emailInputValue?.length}
            loading={isSubmitting}
            title={'Get Email Link'}
          />
          <Spacer right={8} top={16} left={8} bottom={16} />
          {/* Success Message */}
          <View>
            {/* Message */}
            <>
              {!showMessage ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {
                    'Check your inbox. If you have an account with us, you will receive an email with the password reset link. '
                  }
                </Text>
              )}
            </>
            <Spacer top={8} right={8} bottom={8} left={8} />
          </View>
        </View>
        <Spacer right={8} left={8} bottom={0} top={16} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(RequestPasswordLinkScreen);
