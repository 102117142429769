import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const addCoverImageToLXPPATCHStatusAndText = (
  Constants,
  { imageURL, lxp_id }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_experience?id=eq.${
      lxp_id ?? ''
    }`,
    {
      body: JSON.stringify({ coverimage: imageURL }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        Prefer: 'return=representation',
        apikey: Constants['API_KEY'],
      },
      method: 'PATCH',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const addCoverImageToLXPPATCH = (Constants, { imageURL, lxp_id }) =>
  addCoverImageToLXPPATCHStatusAndText(Constants, { imageURL, lxp_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useAddCoverImageToLXPPATCH = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => addCoverImageToLXPPATCH(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('LXP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('LXP');
        queryClient.invalidateQueries('LXPS');
      },
    }
  );
};

export const addSessionsPOSTStatusAndText = (Constants, { arr }) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions`, {
    body: JSON.stringify(arr),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      Prefer: 'resolution=merge-duplicates',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const addSessionsPOST = (Constants, { arr }) =>
  addSessionsPOSTStatusAndText(Constants, { arr }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useAddSessionsPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => addSessionsPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('sessions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
      },
    }
  );
};

export const FetchAddSessionsPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  arr,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useAddSessionsPOST({ arr }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchAddSessions: refetch });
};

export const deleteSessionDELETEStatusAndText = (Constants, { sess_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions?id=eq.${
      sess_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'DELETE',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const deleteSessionDELETE = (Constants, { sess_id }) =>
  deleteSessionDELETEStatusAndText(Constants, { sess_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useDeleteSessionDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteSessionDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('sessions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
      },
    }
  );
};

export const deleteMultipleSessionsDELETEStatusAndText = (
  Constants,
  { sess_ids }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions?id=in.(${
      sess_ids ?? ''
    })`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'DELETE',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const deleteMultipleSessionsDELETE = (Constants, { sess_ids }) =>
  deleteMultipleSessionsDELETEStatusAndText(Constants, { sess_ids }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useDeleteMultipleSessionsDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args =>
      deleteMultipleSessionsDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('sessions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
      },
    }
  );
};

export const directMessageAStudentPOSTStatusAndText = (
  Constants,
  { content, student_id }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/direct_message_student`,
    {
      body: JSON.stringify({ student_id_in: student_id, content: content }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const directMessageAStudentPOST = (Constants, { content, student_id }) =>
  directMessageAStudentPOSTStatusAndText(Constants, {
    content,
    student_id,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useDirectMessageAStudentPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => directMessageAStudentPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('message', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('message');
        queryClient.invalidateQueries('messages');
      },
    }
  );
};

export const FetchDirectMessageAStudentPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content,
  student_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useDirectMessageAStudentPOST(
    { content, student_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchDirectMessageAStudent: refetch,
  });
};

export const draftALXPPOSTStatusAndText = (
  Constants,
  { UUID, imageURL, location_type, title }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_experience`,
    {
      body: JSON.stringify({
        title: title,
        coverimage: imageURL,
        lxp_location_type: location_type,
        author_id: UUID,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        Prefer: 'return=representation',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const draftALXPPOST = (
  Constants,
  { UUID, imageURL, location_type, title }
) =>
  draftALXPPOSTStatusAndText(Constants, {
    UUID,
    imageURL,
    location_type,
    title,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useDraftALXPPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => draftALXPPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('LXP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('LXP');
        queryClient.invalidateQueries('LXPS');
      },
    }
  );
};

export const FetchDraftALXPPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  UUID,
  imageURL,
  location_type,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useDraftALXPPOST(
    { UUID, imageURL, location_type, title },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchDraftALXP: refetch });
};

export const fetchLXPGETStatusAndText = (Constants, { user_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_experience?"author_id"=eq.${
      user_id ?? ''
    }&order=created_at.desc`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const fetchLXPGET = (Constants, { user_id }) =>
  fetchLXPGETStatusAndText(Constants, { user_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useFetchLXPGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['LXPS', args], () => fetchLXPGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchFetchLXPGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useFetchLXPGET(
    { user_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchFetchLXP: refetch });
};

export const fetchLXPSessionsGETStatusAndText = (Constants, { lxp_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions?lxp_id=eq.${
      lxp_id ?? ''
    }&order=start_time`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjY4NTA2OTE2LCJzdWIiOiI2NWE4YzM2My1iZDM1LTQ5NjctOTA5NS0yZjViMTk1Y2YxOTkiLCJlbWFpbCI6InJwQGVtYWlsLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZmlyc3ROYW1lIjoiUmlnaHQiLCJsYXN0TmFtZSI6IlBhdGgiLCJwYXJ0aWNpcGFudElkIjoicGFydF8zNDI0IiwidHlwZSI6InByb3ZpZGVyIn0sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwic2Vzc2lvbl9pZCI6IjBjNTJlOWJlLThiNTQtNGQ0Zi1iNWU5LWE4YWNmNjMzZDBhNCJ9.6FxwxnzEKaS93pK8jWLGJaK6azMJz_DokYfoEqLRcY8',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const fetchLXPSessionsGET = (Constants, { lxp_id }) =>
  fetchLXPSessionsGETStatusAndText(Constants, { lxp_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useFetchLXPSessionsGET = ({ lxp_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions?lxp_id=eq.${
      lxp_id ?? ''
    }&order=start_time`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjY4NTA2OTE2LCJzdWIiOiI2NWE4YzM2My1iZDM1LTQ5NjctOTA5NS0yZjViMTk1Y2YxOTkiLCJlbWFpbCI6InJwQGVtYWlsLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZmlyc3ROYW1lIjoiUmlnaHQiLCJsYXN0TmFtZSI6IlBhdGgiLCJwYXJ0aWNpcGFudElkIjoicGFydF8zNDI0IiwidHlwZSI6InByb3ZpZGVyIn0sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwic2Vzc2lvbl9pZCI6IjBjNTJlOWJlLThiNTQtNGQ0Zi1iNWU5LWE4YWNmNjMzZDBhNCJ9.6FxwxnzEKaS93pK8jWLGJaK6azMJz_DokYfoEqLRcY8',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchFetchLXPSessionsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  lxp_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions?lxp_id=eq.${
      lxp_id ?? ''
    }&order=start_time`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjY4NTA2OTE2LCJzdWIiOiI2NWE4YzM2My1iZDM1LTQ5NjctOTA5NS0yZjViMTk1Y2YxOTkiLCJlbWFpbCI6InJwQGVtYWlsLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZmlyc3ROYW1lIjoiUmlnaHQiLCJsYXN0TmFtZSI6IlBhdGgiLCJwYXJ0aWNpcGFudElkIjoicGFydF8zNDI0IiwidHlwZSI6InByb3ZpZGVyIn0sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwic2Vzc2lvbl9pZCI6IjBjNTJlOWJlLThiNTQtNGQ0Zi1iNWU5LWE4YWNmNjMzZDBhNCJ9.6FxwxnzEKaS93pK8jWLGJaK6azMJz_DokYfoEqLRcY8',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchFetchLXPSessions: refetch });
};

export const getInsightsGETStatusAndText = Constants =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getInsightsGET = Constants =>
  getInsightsGETStatusAndText(Constants).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetInsightsGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetInsightsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetInsights: refetch });
};

export const getProfileGETStatusAndText = (Constants, { user_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getProfileGET = (Constants, { user_id }) =>
  getProfileGETStatusAndText(Constants, { user_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetProfileGET = ({ user_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetProfileGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetProfile: refetch });
};

export const getTeacherStudentChatsGETStatusAndText = (
  Constants,
  { channel_id }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/messages_teacher_student?channel_id=eq.${
      channel_id ?? ''
    }&order=created_at.desc.nullslast`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getTeacherStudentChatsGET = (Constants, { channel_id }) =>
  getTeacherStudentChatsGETStatusAndText(Constants, { channel_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetTeacherStudentChatsGET = (
  args,
  { refetchInterval } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['messages', args],
    () => getTeacherStudentChatsGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetTeacherStudentChatsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  channel_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetTeacherStudentChatsGET(
    { channel_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetTeacherStudentChats: refetch,
  });
};

export const getMessageSummaryGETStatusAndText = Constants =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_user_get_last_teacher_student_messages`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getMessageSummaryGET = Constants =>
  getMessageSummaryGETStatusAndText(Constants).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetMessageSummaryGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['messages', args],
    () => getMessageSummaryGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetMessageSummaryGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetMessageSummaryGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetMessageSummary: refetch });
};

export const requestPasswordResetEmailPOSTStatusAndText = (
  Constants,
  { email }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/recover`, {
    body: JSON.stringify({ email: email }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const requestPasswordResetEmailPOST = (Constants, { email }) =>
  requestPasswordResetEmailPOSTStatusAndText(Constants, { email }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useRequestPasswordResetEmailPOST = (
  args,
  { refetchInterval } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['password request', args],
    () => requestPasswordResetEmailPOST(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['password requests']),
    }
  );
};

export const FetchRequestPasswordResetEmailPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useRequestPasswordResetEmailPOST(
    { email },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchRequestPasswordResetEmail: refetch,
  });
};

export const request$UpdateALXPForListingPOSTStatusAndText = (
  Constants,
  {
    archived,
    author_id,
    desc,
    difficulty,
    imageURL,
    isOneTime,
    isOnline,
    learning_center_id,
    location_type,
    max_age,
    max_part,
    min_age,
    min_part,
    price_ser,
    price_sess,
    req_resources,
    status,
    subject,
    title,
    uuid,
    zoom_id,
    zoom_password,
    zoom_url,
  }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_experience`,
    {
      body: JSON.stringify({
        id: uuid,
        coverimage: imageURL,
        title: title,
        subject: subject,
        difficulty: difficulty,
        description: desc,
        required_resources: req_resources,
        zoom_url: zoom_url,
        zoom_id: zoom_id,
        zoom_password: zoom_password,
        isOneTime: isOneTime,
        pricing_per_session: price_sess,
        pricing_per_series: price_ser,
        min_age: min_age,
        max_age: max_age,
        min_participants: min_part,
        max_participants: max_part,
        author_id: author_id,
        status: status,
        archived: archived,
        lxp_location_type: location_type,
        learning_center_id: learning_center_id,
        isvirtual: isOnline,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        Prefer: 'resolution=merge-duplicates',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const request$UpdateALXPForListingPOST = (
  Constants,
  {
    archived,
    author_id,
    desc,
    difficulty,
    imageURL,
    isOneTime,
    isOnline,
    learning_center_id,
    location_type,
    max_age,
    max_part,
    min_age,
    min_part,
    price_ser,
    price_sess,
    req_resources,
    status,
    subject,
    title,
    uuid,
    zoom_id,
    zoom_password,
    zoom_url,
  }
) =>
  request$UpdateALXPForListingPOSTStatusAndText(Constants, {
    archived,
    author_id,
    desc,
    difficulty,
    imageURL,
    isOneTime,
    isOnline,
    learning_center_id,
    location_type,
    max_age,
    max_part,
    min_age,
    min_part,
    price_ser,
    price_sess,
    req_resources,
    status,
    subject,
    title,
    uuid,
    zoom_id,
    zoom_password,
    zoom_url,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useRequest$UpdateALXPForListingPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args =>
      request$UpdateALXPForListingPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('LXP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('LXP');
        queryClient.invalidateQueries('LXPS');
      },
    }
  );
};

export const FetchRequest$UpdateALXPForListingPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  archived,
  author_id,
  desc,
  difficulty,
  imageURL,
  isOneTime,
  isOnline,
  learning_center_id,
  location_type,
  max_age,
  max_part,
  min_age,
  min_part,
  price_ser,
  price_sess,
  req_resources,
  status,
  subject,
  title,
  uuid,
  zoom_id,
  zoom_password,
  zoom_url,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useRequest$UpdateALXPForListingPOST(
    {
      archived,
      author_id,
      desc,
      difficulty,
      imageURL,
      isOneTime,
      isOnline,
      learning_center_id,
      location_type,
      max_age,
      max_part,
      min_age,
      min_part,
      price_ser,
      price_sess,
      req_resources,
      status,
      subject,
      title,
      uuid,
      zoom_id,
      zoom_password,
      zoom_url,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchRequest$UpdateALXPForListing: refetch,
  });
};

export const updateAuthProfilePUTStatusAndText = (
  Constants,
  { email, password }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'PUT',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updateAuthProfilePUT = (Constants, { email, password }) =>
  updateAuthProfilePUTStatusAndText(Constants, { email, password }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const updateAuthUser$sTrustshareParticipantIdPUTStatusAndText = (
  Constants,
  { participant_id }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    body: JSON.stringify({ data: { participantId: participant_id } }),
    headers: {
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjY2OTQwODU3LCJzdWIiOiI2NWE4YzM2My1iZDM1LTQ5NjctOTA5NS0yZjViMTk1Y2YxOTkiLCJlbWFpbCI6InJwQGVtYWlsLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZmlyc3ROYW1lIjoiUmlnaHQiLCJsYXN0TmFtZSI6IlBhdGgiLCJ0eXBlIjoicHJvdmlkZXIifSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJzZXNzaW9uX2lkIjoiZDViZWU4YTAtMDc2YS00YWU0LWEyYmEtNzczMGE5MDk3OTNmIn0.CphUQGsFwkG3HMFXu_89CeBIIxtGqYs_-HkvMNZeTwY',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'PUT',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updateAuthUser$sTrustshareParticipantIdPUT = (
  Constants,
  { participant_id }
) =>
  updateAuthUser$sTrustshareParticipantIdPUTStatusAndText(Constants, {
    participant_id,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const updateLXPStatusPATCHStatusAndText = (Constants, { id, status }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_experience?id=eq.${
      id ?? ''
    }`,
    {
      body: JSON.stringify({ status: status }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'PATCH',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updateLXPStatusPATCH = (Constants, { id, status }) =>
  updateLXPStatusPATCHStatusAndText(Constants, { id, status }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useUpdateLXPStatusPATCH = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => updateLXPStatusPATCH(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('LXP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('LXP');
        queryClient.invalidateQueries('LXPS');
      },
    }
  );
};

export const updateProfilePATCHStatusAndText = (
  Constants,
  { id, update_data }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?id=eq.${
      id ?? ''
    }`,
    {
      body: JSON.stringify(update_data),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        Prefer: 'resolution=merge-duplicates',
        apikey: Constants['API_KEY'],
      },
      method: 'PATCH',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updateProfilePATCH = (Constants, { id, update_data }) =>
  updateProfilePATCHStatusAndText(Constants, { id, update_data }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );
