import { useEffect, useState } from 'react';

export const useDebounce = (value, delay) => {
  const [deboncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const hanlder = setTimeout(() => {
      console.log('setting debounced value', value, delay);
      setDebouncedValue(value);
      // if (typeof callBackFn === 'function')
      //   callBackFn(value)
    }, delay);

    return () => {
      clearTimeout(hanlder);
    };
  }, [value, delay]);

  return deboncedValue;
};
