import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseRESTAPIAuthOptionalApi from '../apis/SupabaseRESTAPIAuthOptionalApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import createSubjectList from '../global-functions/createSubjectList';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View, useWindowDimensions } from 'react-native';

const WelcomeScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  /*
const linking = {
 config: {
   screens:{
   WelcomeScreen: "",
   LoginScreen: "login/:redirect",
   PublicProfileScreen: "public_profile/:handle",
   UpdatePasswordScreen: "update_password/:accessToken"
 }
 },
};


*/

  let requiredAuth = false;
  let redirectPath = '';
  let redirectObj = {};
  console.log(props?.route);

  console.log(props?.route?.params?.screen);
  console.log(props?.route?.params?.handle);

  switch (props?.route?.params?.screen) {
    case 'LXPListing': {
      redirectPath = 'LoginScreen';
      requiredAuth = true;
      redirectObj = { path: 'MyExperiencesScreen' };
      break;
    }

    case 'LocationListing': {
      // redirectPath = 'LoginScreen'
      requiredAuth = true;
      break;
    }

    case 'PublicProfile': {
      requiredAuth = false;
      redirectPath = 'PublicProfileScreen';
      redirectObj = { handle: props?.route?.params?.handle };
      break;
    }
    case 'UpdatePassword': {
      requiredAuth = false;
      redirectPath = 'UpdatePasswordScreen';
      redirectObj = { accessToken: props?.route?.params?.accessToken };
      break;
    }
  }

  if (redirectPath) {
    props.navigation.navigate(redirectPath, redirectObj);
  }
  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const res = await SupabaseRESTAPIAuthOptionalApi.getSubjectsGET(
          Constants
        );
        const subjects = createSubjectList(res);
        setGlobalVariableValue({
          key: 'SUBJECTS',
          value: Constants['SUBJECTS'].concat(subjects),
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: '"rgba(0, 0, 0, 0)"',
            height: '100%',
            justifyContent: 'center',
          },
          dimensions.width
        )}
      >
        <Image
          style={StyleSheet.applyWidth(
            { height: 100, width: 100 },
            dimensions.width
          )}
          resizeMode={'cover'}
          source={Images.Group}
        />
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Inter Regular 400 14pt'],
              {
                color: theme.colors.notBlackMidnight,
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'Inter_700Bold' },
                  { minWidth: Breakpoints.Laptop, value: 'Inter_700Bold' },
                ],
                fontSize: 24,
                marginTop: 40,
                textAlign: 'center',
              }
            ),
            dimensions.width
          )}
        >
          {'Team up with us for'}
        </Text>
        {/* Title Wrapper */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
            },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Inter Regular 400 14pt'],
                {
                  color: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors.notBlackMidnight,
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Primary'],
                    },
                  ],
                  fontFamily: [
                    { minWidth: Breakpoints.Mobile, value: 'Inter_700Bold' },
                    { minWidth: Breakpoints.Laptop, value: 'Inter_700Bold' },
                  ],
                  fontSize: 24,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'a new era '}
          </Text>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Inter Regular 400 14pt'],
                {
                  color: theme.colors.notBlackMidnight,
                  fontFamily: [
                    { minWidth: Breakpoints.Mobile, value: 'Inter_700Bold' },
                    { minWidth: Breakpoints.Laptop, value: 'Inter_700Bold' },
                  ],
                  fontSize: 24,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'of learning'}
          </Text>
        </View>

        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Inter Regular 400 14pt'],
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors.notBlackMidnight,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Custom Color'],
                  },
                ],
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  { minWidth: Breakpoints.Laptop, value: 'Inter_500Medium' },
                ],
                fontSize: 16,
                fontWeight: { minWidth: Breakpoints.Mobile, value: '400' },
                marginBottom: 40,
                marginTop: 20,
                textAlign: 'center',
              }
            ),
            dimensions.width
          )}
        >
          {'City as a School \nTeacher Dashboard'}
        </Text>

        <View
          style={StyleSheet.applyWidth(
            { borderRadius: 8, width: 350 },
            dimensions.width
          )}
        >
          {/* Button Solid */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('SignupScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.primary,
                borderRadius: 8,
                color: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Background'],
                },
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: 'Montserrat_500Medium',
                  },
                ],
                fontWeight: { minWidth: Breakpoints.Mobile, value: '700' },
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Sign Up'}
          />
          {/* Button Outline */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('LoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Background'],
                borderColor: theme.colors['Primary'],
                borderRadius: 8,
                borderWidth: 1,
                color: theme.colors['Primary'],
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: 'Montserrat_500Medium',
                  },
                ],
                fontWeight: { minWidth: Breakpoints.Mobile, value: '700' },
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Log in'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);
