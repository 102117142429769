import React from 'react';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import openUrlWithLinking from '../global-functions/openUrlWithLinking';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const UpdatePasswordScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const validateForm = setGlobalVariableValue => {
    let hasError = false;
    if (password !== confirmPassword) {
      setGlobalVariableValue({
        key: 'ERROR_MESSAGE',
        value: 'Passwords should match',
      });
      hasError = true;
    }

    return hasError;
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!(props.route?.params?.accessToken ?? '')) {
        return;
      }
      setGlobalVariableValue({
        key: 'AUTH_BEARER',
        value: 'Bearer ' + props.route?.params?.accessToken ?? '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingTop: '5%',
          },
          dimensions.width
        )}
      >
        {/* Form */}
        <View style={StyleSheet.applyWidth({ width: 350 }, dimensions.width)}>
          {/* Header */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', padding: 0 },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 8 },
                dimensions.width
              )}
              size={32}
              color={theme.colors['Custom Color']}
              icon={'MaterialIcons/chevron-left'}
            />
            <Text
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'stretch',
                  color: theme.colors['Custom Color'],
                  fontFamily: 'System',
                  fontSize: 20,
                  fontWeight: '600',
                },
                dimensions.width
              )}
            >
              {'Update password'}
            </Text>
          </View>

          <Text
            style={StyleSheet.applyWidth(
              { color: theme.colors['Error'], fontSize: 12 },
              dimensions.width
            )}
          >
            {Constants['ERROR_MESSAGE']}
          </Text>
          {/* Input Container */}
          <View
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Inactive Grey'],
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '400',
                  marginBottom: 8,
                },
                dimensions.width
              )}
            >
              {'New Password'}
            </Text>
            {/* password */}
            <TextInput
              onChangeText={newPasswordValue => {
                try {
                  setPassword(newPasswordValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Grey Lines'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: theme.colors['Custom Color'],
                  fontFamily: 'System',
                  fontWeight: '400',
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              value={password}
              placeholder={' '}
              autoCapitalize={'none'}
              secureTextEntry={true}
            />
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          {/* Input Container */}
          <View
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Inactive Grey'],
                  fontSize: 14,
                  marginBottom: 8,
                },
                dimensions.width
              )}
            >
              {'Confirm Password'}
            </Text>
            {/* password */}
            <TextInput
              onChangeText={newPasswordValue => {
                try {
                  setConfirmPassword(newPasswordValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Grey Lines'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: theme.colors['Custom Color'],
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              value={confirmPassword}
              placeholder={' '}
              autoCapitalize={'none'}
              secureTextEntry={true}
            />
            <Spacer top={8} right={8} bottom={8} left={8} />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', justifyContent: 'center', width: '100%' },
              dimensions.width
            )}
          >
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const hasError = validateForm(setGlobalVariableValue);
                    if (hasError) {
                      return;
                    }
                    setIsLoading(true);
                    const res = await SupabaseAuthApi.updatePasswordPUT(
                      Constants,
                      { confirmPassword: confirmPassword }
                    );
                    const err = setGlobalVariableValue({
                      key: 'ERROR_MESSAGE',
                      value: res?.msg,
                    });
                    setIsLoading(false);
                    if (err) {
                      return;
                    }
                    setGlobalVariableValue({
                      key: 'ERROR_MESSAGE',
                      value: '',
                    });
                    openUrlWithLinking('');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.primary,
                  borderRadius: 8,
                  fontFamily: 'System',
                  fontWeight: '700',
                  textAlign: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
              title={'Update Password'}
            />
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UpdatePasswordScreen);
