// This import is required if you are defining react components in this module.
import React, { useEffect } from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { TextInput } from 'react-native';

import { useWindowDimensions } from 'react-native';
import { useDebounce } from './hooks';
import * as StyleSheet from '../utils/StyleSheet';
import searchLearningCenters from '../global-functions/searchLearningCenters';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <D.MyExampleComponent />
export const SearchLearningCenter = ({
  theme,
  searchQuery,
  setSearchQuery,
  setLearning_centers,
  selectedLearningCenter,
  setShowingLearningCenters,
}) => {
  const dimensions = useWindowDimensions();

  const debouncedQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    const handler = async () => {
      try {
        // setIsQuerying(true);
        const res = await searchLearningCenters(debouncedQuery);
        // console.log(res)

        // if (res?.length) {
        // }
        setLearning_centers(res);
      } catch (err) {
        console.error(err);
      }
    };
    if (debouncedQuery) handler();
  }, [debouncedQuery]);

  return (
    <TextInput
      onChangeText={newTextInputValue => {
        setSearchQuery(newTextInputValue);
        // setShowingLearningCenters(true)
      }}
      onFocus={() => setShowingLearningCenters(true)}
      // onBlur={() => setShowingLearningCenters(false)}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['Grey Lines'],
          borderBottomWidth: 1,
          borderColor: theme.colors.divider,
          borderLeftWidth: 1,
          borderRadius: 8,
          borderRightWidth: 1,
          borderTopWidth: 1,
          paddingBottom: 8,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 8,
        },
        dimensions.width
      )}
      value={searchQuery}
      editable={true}
      placeholder={selectedLearningCenter}
      autoFocus={true}
      clearButtonMode={'while-editing'}
    />
  );
};
