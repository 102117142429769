import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({ 'Activity Indicator': { height: 36, width: 36 } });

export const ButtonStyles = theme =>
  StyleSheet.create({
    'Add New Experience Button': {
      borderRadius: 8,
      color: theme.colors['Background'],
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    'Gray Icon Button': {
      backgroundColor: theme.colors['NFT_TIME_Border'],
      borderRadius: 30,
      fontFamily: 'System',
      fontWeight: '700',
      marginTop: 16,
      textAlign: 'center',
      width: 200,
    },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { height: 1 } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { height: 100, width: 100 },
    'Menu Icon': {
      height: [
        { minWidth: Breakpoints.Mobile, value: 100 },
        { minWidth: Breakpoints.Laptop, value: 24 },
      ],
      marginRight: { minWidth: Breakpoints.Laptop, value: 4 },
      width: [
        { minWidth: Breakpoints.Mobile, value: 100 },
        { minWidth: Breakpoints.Laptop, value: 24 },
      ],
    },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    'Inter Medium 500 16pt': {
      color: [
        { minWidth: Breakpoints.Mobile, value: theme.colors.strong },
        { minWidth: Breakpoints.Laptop, value: theme.colors['Custom Color_4'] },
      ],
      fontFamily: [
        { minWidth: Breakpoints.Mobile, value: 'System' },
        { minWidth: Breakpoints.Laptop, value: 'Inter_500Medium' },
      ],
      fontSize: 16,
      fontWeight: { minWidth: Breakpoints.Mobile, value: '600' },
      marginLeft: [
        { minWidth: Breakpoints.Mobile, value: 8 },
        { minWidth: Breakpoints.Laptop, value: 4 },
      ],
    },
    'Inter Regular 400 14pt': {
      color: theme.colors.strong,
      fontFamily: [
        { minWidth: Breakpoints.Mobile, value: 'System' },
        { minWidth: Breakpoints.Laptop, value: 'Inter_400Regular' },
      ],
      fontWeight: { minWidth: Breakpoints.Mobile, value: '400' },
    },
    'Montserrat Regurlar 14pt': {
      color: theme.colors.strong,
      fontFamily: 'Montserrat_400Regular',
    },
    'Signup Form Label': {
      color: theme.colors['Inactive Grey'],
      fontFamily: [
        { minWidth: Breakpoints.Mobile, value: 'System' },
        { minWidth: Breakpoints.Laptop, value: 'Inter_500Medium' },
      ],
      fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
      fontWeight: { minWidth: Breakpoints.Mobile, value: '400' },
      marginBottom: 6,
    },
    Title: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 18,
      fontWeight: '700',
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Learning Center Nav': { marginTop: 40 },
    'Learning Center Nav 2': { marginTop: 40 },
    'Learning Center Nav 3': { marginTop: 40 },
    'Learning Center Nav 4': { marginTop: 40 },
    'Learning Center Nav 5': { marginTop: 40 },
    'Learning Center Nav 6': { marginTop: 40 },
    'Learning Center Nav 7': { marginTop: 40 },
    Navigation: { marginTop: 40 },
    Sidebar: {
      height: '100%',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      width: '25%',
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: { color: theme.colors.primary },
    'Montserrat Medium 14pt': {
      color: theme.colors.primary,
      fontFamily: 'Montserrat_500Medium',
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Signup Form Field': {
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Grey Lines'],
      },
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      color: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Custom Color_4'],
      },
      fontFamily: { minWidth: Breakpoints.Laptop, value: 'Inter_400Regular' },
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });
