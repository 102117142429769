import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RPCApi from '../apis/RPCApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import titileCase from '../global-functions/titileCase';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Circle,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const MyExperiencesScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const checkStatus = item => {
    // return status === 'all' || ( (item.archived && status === 'archived') || item.status === status)

    if (status === 'all') return true;
    else if (status === 'archived') return item.archived ? true : false;
    else return !item.archived && item.status === status ? true : false;
  };

  const convertToFormObj = item => {
    const keyMap = {
      lxp_id: 'id',

      coverimage: 'coverImage',
      title: 'title',

      isvirtual: 'isVirtual',
      lxp_location_type: 'location_type',
      subject: 'subject',
      difficulty: 'difficulty',
      description: 'description',
      required_resources: 'requiredResources',
      zoom_url: 'zoom_url',

      zoom_id: 'zoom_id',
      zoom_password: 'zoom_password',

      isOneTime: 'isOneTime',
      pricing_per_session: 'pricing_per_session',
      pricing_per_series: 'pricing_per_series',

      min_age: 'ageRangeMin',
      max_age: 'ageRangeMax',

      learning_center_id: 'learning_center_id',
      learning_center: 'learning_center',

      min_participants: 'capacityRangeMin',
      max_participants: 'capacityRangeMax',

      status: 'status',
      archived: 'archived',
      id: 'id',
    };

    const newObj = {};
    const boolFields = ['isOneTime', 'isVirtual', 'archived'];
    for (let [key, value] of Object.entries(item)) {
      // console.log(key, value):w
      let newKey = keyMap[key];
      if (newKey) {
        if (boolFields.includes(newKey)) newObj[newKey] = value;
        else newObj[newKey] = { value, error: '' };
      }
    }

    newObj['explainVideo'] = { value: '', error: '' };
    // console.log(item)
    if (item?.series?.length)
      newObj['series'] = item.series.map(({ series_no, sessions }) => ({
        series_no,
        durationPerSession: sessions[0].duration,
        error: '',
        sessions,
      }));
    else
      newObj['series'] = [
        { series_no: 1, sessions: [], error: '', durationPerSession: 0 },
      ];
    console.log(newObj);
    return newObj;
  };

  // return true if there are lxps with current tab status
  const hasLXPs = fetchData => {
    return (
      fetchData.filter(data => status === 'all' || data.status === status)
        .length > 0
    );
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CURRENT_SCREEN',
        value: 'MY_LXP',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [initialFormObj, setInitialFormObj] = React.useState({
    id: { value: '' },
    title: { error: '', value: '' },
    series: [{ id: 1, error: '', sessions: [], durationPerSession: 0 }],
    subject: { error: '', value: 'Arts' },
    zoom_id: { error: '', value: '' },
    zoom_url: { error: '', value: '' },
    isOneTime: true,
    isVirtual: true,
    coverImage: { error: '', value: '' },
    difficulty: { value: 1 },
    ageRangeMax: { error: '', value: 0 },
    ageRangeMin: { error: '', value: 0 },
    description: { error: '', value: '' },
    explainVideo: { error: '', value: '' },
    zoom_password: { error: '', value: '' },
    capacityRangeMax: { error: '', value: 0 },
    capacityRangeMin: { error: '', value: 0 },
    requiredResources: { value: '' },
    pricing_per_series: { error: '', value: 0 },
    pricing_per_session: { error: '', value: 0 },
  });
  const [status, setStatus] = React.useState('all');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Sidebar */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Sidebar'],
            dimensions.width
          )}
        >
          {/* Profile */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '100%' },
              dimensions.width
            )}
          >
            {/* Upload avatar */}
            <>
              {Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  source={{
                    uri: `${getNameAvatarUrl(Constants['USER_DATA'])}`,
                  }}
                  size={60}
                />
              )}
            </>
            <>
              {!Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  size={60}
                  source={{ uri: `${Constants['USER_DATA']?.profile_picture}` }}
                />
              )}
            </>
            {/* User Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 12 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['USER_DATA']?.firstName}{' '}
              {Constants['USER_DATA']?.lastName}
            </Text>
            <Spacer top={8} bottom={8} left={0} right={4} />
            <>
              {!Constants['USER_DATA']?.is_verified ? null : (
                <Icon
                  size={24}
                  name={'MaterialIcons/verified'}
                  color={theme.colors['Primary']}
                />
              )}
            </>
          </View>
          {/* new Nav with matching icon */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Learning Center Nav 7'],
              dimensions.width
            )}
          >
            {/* Home */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'HOME',
                  });
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Application / Roles */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'APPLICATION',
                  });
                  navigation.navigate('ApplicationScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Inbox Item */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INBOX',
                  });
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* My Learning Experiences */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'MY LXP',
                  });
                  navigation.navigate('MyExperiencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Learning Centers */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'LEARNING_CENTERS',
                  });
                  navigation.navigate('LearningCentersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Insights */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INSIGHTS',
                  });
                  navigation.navigate('InsightsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Light'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Feedback */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/feature-requests'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/message-processing-outline'}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback'}
                </Text>
              </View>
            </Touchable>
            {/* Marketing Support Nav */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Marketing-Strategies-to-Grow-Your-Offerings-42c25ec6074d428194faf210e270d67a'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'AntDesign/adduser'} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Marketing Support'}
                </Text>
              </View>
            </Touchable>
            {/* Settings */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'SETTINGS',
                  });
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Primary 20%'],
                        },
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            { height: '100%', width: '75%' },
            dimensions.width
          )}
        >
          {/* tabsFrame */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Background'],
                height: '5%',
                marginTop: 16,
              },
              dimensions.width
            )}
          >
            {/* Row */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* All */}
              <View>
                {/* Active */}
                <>
                  {!(status === 'all') ? null : (
                    <Button
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderBottomWidth: 3,
                          borderColor: theme.colors.primary,
                          borderRadius: 0,
                          color: theme.colors['Primary'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'All'}
                    />
                  )}
                </>
                {/* Inactive */}
                <>
                  {!(status !== 'all') ? null : (
                    <Button
                      onPress={() => {
                        try {
                          setStatus('all');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 0,
                          borderWidth: 1,
                          color: theme.colors.inactiveGrey,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'All'}
                    />
                  )}
                </>
              </View>
              {/* Archived */}
              <View>
                {/* Active */}
                <>
                  {!(status === 'archived') ? null : (
                    <Button
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderBottomWidth: 3,
                          borderColor: theme.colors.primary,
                          borderRadius: 0,
                          color: theme.colors['Primary'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Archived'}
                    />
                  )}
                </>
                {/* Inactive */}
                <>
                  {!(status !== 'archived') ? null : (
                    <Button
                      onPress={() => {
                        try {
                          setStatus('archived');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 0,
                          borderWidth: 1,
                          color: theme.colors.inactiveGrey,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Archived'}
                    />
                  )}
                </>
              </View>
              {/* Active */}
              <View>
                {/* Active */}
                <>
                  {!(status === 'active') ? null : (
                    <Button
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderBottomWidth: 3,
                          borderColor: theme.colors.primary,
                          borderRadius: 0,
                          color: theme.colors['Primary'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Active'}
                    />
                  )}
                </>
                {/* Inactive */}
                <>
                  {!(status !== 'active') ? null : (
                    <Button
                      onPress={() => {
                        try {
                          setStatus('active');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderWidth: 1,
                          color: theme.colors.inactiveGrey,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Active'}
                    />
                  )}
                </>
              </View>
              {/* Draft */}
              <View>
                {/* Active */}
                <>
                  {!(status === 'draft') ? null : (
                    <Button
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderBottomWidth: 3,
                          borderColor: theme.colors.primary,
                          borderRadius: 0,
                          color: theme.colors['Primary'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Draft'}
                    />
                  )}
                </>
                {/* Inactive */}
                <>
                  {!(status !== 'draft') ? null : (
                    <Button
                      onPress={() => {
                        try {
                          setStatus('draft');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderWidth: 1,
                          color: theme.colors.inactiveGrey,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Draft'}
                    />
                  )}
                </>
              </View>
              {/* In Review */}
              <View>
                {/* Active */}
                <>
                  {!(status === 'in-review') ? null : (
                    <Button
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderBottomWidth: 3,
                          borderColor: theme.colors.primary,
                          borderRadius: 0,
                          color: theme.colors['Primary'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'In Review'}
                    />
                  )}
                </>
                {/* Inactive */}
                <>
                  {!(status !== 'in-review') ? null : (
                    <Button
                      onPress={() => {
                        try {
                          setStatus('in-review');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderWidth: 1,
                          color: theme.colors.inactiveGrey,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'In Review'}
                    />
                  )}
                </>
              </View>
            </View>
            <Divider
              style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
              color={theme.colors.divider}
            />
          </View>
          {/* Tab Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Background'],
                height: '95%',
                marginTop: 16,
              },
              dimensions.width
            )}
          >
            <ScrollView
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
              showsVerticalScrollIndicator={true}
              bounces={true}
            >
              <RPCApi.FetchGetLXPsGET>
                {({ loading, error, data, refetchGetLXPs }) => {
                  const fetchData = data;
                  if (!fetchData || loading) {
                    return <ActivityIndicator />;
                  }

                  if (error) {
                    return (
                      <Text style={{ textAlign: 'center' }}>
                        There was a problem fetching this data
                      </Text>
                    );
                  }

                  return (
                    <>
                      {/* Empty Frame */}
                      <>
                        {fetchData?.length ? null : (
                          <View>
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 20,
                                  fontWeight: '700',
                                },
                                dimensions.width
                              )}
                            >
                              {'No Experiences'}
                            </Text>
                          </View>
                        )}
                      </>
                      <FlatList
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <>
                              {/*  Container */}
                              <>
                                {!checkStatus(listData) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors['Grey Lines'],
                                        borderLeftWidth: 1,
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 4,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 4,
                                          },
                                        ],
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        marginBottom: 20,
                                        marginRight: 20,
                                        paddingBottom: 10,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        paddingTop: 10,
                                        width: 300,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Image
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderTopLeftRadius: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 4,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            ],
                                            borderTopRightRadius: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 4,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            ],
                                            height: 100,
                                          },
                                          dimensions.width
                                        )}
                                        source={{
                                          uri: `${listData?.coverimage}`,
                                        }}
                                        resizeMode={'cover'}
                                      />
                                    </View>
                                    {/* title container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginTop: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 12,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'System',
                                            fontSize: 18,
                                            fontWeight: '700',
                                          },
                                          dimensions.width
                                        )}
                                        numberOfLines={2}
                                        ellipsizeMode={'tail'}
                                      >
                                        {listData?.title}
                                      </Text>
                                    </View>
                                    <Spacer
                                      right={8}
                                      left={8}
                                      top={8}
                                      bottom={0}
                                    />
                                    {/* Profile Row */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <>
                                          {!listData?.author
                                            ?.profile_picture ? null : (
                                            <CircleImage
                                              size={24}
                                              source={{
                                                uri: `${listData?.author?.profile_picture}`,
                                              }}
                                            />
                                          )}
                                        </>
                                        <>
                                          {listData?.author
                                            ?.profile_picture ? null : (
                                            <Circle
                                              bgColor={theme.colors.light}
                                              size={24}
                                            >
                                              <Icon
                                                size={24}
                                                name={'Ionicons/person'}
                                              />
                                            </Circle>
                                          )}
                                        </>
                                      </View>
                                      <Spacer top={8} bottom={8} right={4} />
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          { color: theme.colors.strong },
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.author?.firstName}{' '}
                                        {listData?.author?.lastName}
                                      </Text>
                                    </View>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                    {/* description container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { height: 40, minHeight: 40 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* description */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          { color: theme.colors.strong },
                                          dimensions.width
                                        )}
                                        numberOfLines={2}
                                        ellipsizeMode={'clip'}
                                      >
                                        {listData?.description}
                                      </Text>
                                    </View>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                    {/* tags */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* session */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          name={'Entypo/calendar'}
                                          color={theme.colors['Inactive Grey']}
                                          size={15}
                                        />
                                        <Spacer
                                          top={8}
                                          right={8}
                                          bottom={8}
                                          left={0}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            { color: theme.colors.strong },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.up_session_count}
                                          {' session'}
                                        </Text>
                                      </View>
                                      <Spacer
                                        top={8}
                                        right={8}
                                        bottom={8}
                                        left={8}
                                      />
                                      {/* virtual */}
                                      <>
                                        {!listData?.isvirtual
                                          ?.isVirtual ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Icon
                                              color={
                                                theme.colors['Inactive Grey']
                                              }
                                              size={15}
                                              name={'Entypo/location-pin'}
                                            />
                                            <Spacer
                                              top={8}
                                              right={8}
                                              bottom={8}
                                              left={0}
                                            />
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                { color: theme.colors.strong },
                                                dimensions.width
                                              )}
                                            >
                                              {'zoom'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                    {/* Button Container */}
                                    <>
                                      {listData?.archived ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'center' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* draft button */}
                                          <>
                                            {!(
                                              listData?.status === 'draft'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    const converted =
                                                      convertToFormObj(
                                                        listData
                                                      );
                                                    navigation.navigate(
                                                      'CreateUpdateALearningExperienceScreen',
                                                      {
                                                        mode: 'Edit',
                                                        initialFormObj:
                                                          converted,
                                                      }
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      theme.colors.primary,
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      theme.colors['Primary'],
                                                    borderLeftWidth: 1,
                                                    borderRadius: 8,
                                                    borderRightWidth: 1,
                                                    borderTopWidth: 1,
                                                    color:
                                                      theme.colors[
                                                        'Background'
                                                      ],
                                                    fontFamily: 'System',
                                                    fontWeight: '700',
                                                    textAlign: 'center',
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '85%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '100%',
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                                title={`${titileCase(
                                                  listData?.status
                                                )}`}
                                              />
                                            )}
                                          </>
                                          {/* non action button */}
                                          <>
                                            {!(
                                              listData?.status !== 'draft'
                                            ) ? null : (
                                              <Button
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      theme.colors.primary,
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      theme.colors['Primary'],
                                                    borderLeftWidth: 1,
                                                    borderRadius: 8,
                                                    borderRightWidth: 1,
                                                    borderTopWidth: 1,
                                                    color:
                                                      theme.colors[
                                                        'Background'
                                                      ],
                                                    fontFamily: 'System',
                                                    fontWeight: '700',
                                                    textAlign: 'center',
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '85%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: '100%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '100%',
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                                title={`${titileCase(
                                                  listData?.status
                                                )}`}
                                              />
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                    {/* Manage Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'flex-start',
                                            },
                                          ],
                                          alignSelf: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'flex-start',
                                          },
                                          flex: 1,
                                          justifyContent: 'center',
                                          marginBottom: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 0,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            const converted =
                                              convertToFormObj(listData);
                                            navigation.navigate(
                                              'CreateUpdateALearningExperienceScreen',
                                              {
                                                mode: 'Edit',
                                                initialFormObj: converted,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <Icon
                                          size={20}
                                          name={'Ionicons/settings-outline'}
                                          color={theme.colors['Custom Color']}
                                        />
                                      </Touchable>
                                    </View>
                                  </View>
                                )}
                              </>
                            </>
                          );
                        }}
                        data={fetchData}
                        listKey={'NCFz8fRs'}
                        keyExtractor={listData => listData?.id}
                        contentContainerStyle={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            paddingBottom: 40,
                          },
                          dimensions.width
                        )}
                        horizontal={false}
                        numColumns={3}
                      />
                    </>
                  );
                }}
              </RPCApi.FetchGetLXPsGET>
            </ScrollView>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(MyExperiencesScreen);
