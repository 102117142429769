import React from 'react';
import * as SupabaseEdgeFunctionsApi from '../apis/SupabaseEdgeFunctionsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import getFullName from '../global-functions/getFullName';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Divider,
  Icon,
  Picker,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Text, View, useWindowDimensions } from 'react-native';

const UpdateBankDetailsScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const isFieldRequired = field => {
    return requirements.includes(field);
  };

  const checkRequirementsError = reqs => {
    if (reqs?.length) {
      setHasPrimaryError(false);
      return false;
    }
    setHasPrimaryError(true);
    return true;
  };

  const createBankAccountObj = () => {
    const bankAcc = {
      country: bankCountry,
      currency,
    };

    for (let field of requirements) {
      switch (field) {
        case 'account_number': {
          bankAcc[field] = accNum;
          break;
        }

        case 'iban': {
          bankAcc[field] = iban;

          break;
        }

        case 'aba': {
          bankAcc[field] = aba;
          break;
        }

        case 'bank_code': {
          bankAcc[field] = bank_code;
          break;
        }
        case 'bic_swift': {
          bankAcc[field] = bic_swift;
          break;
        }

        case 'branch_code': {
          bankAcc[field] = branch_code;
          break;
        }

        case 'bsb_code': {
          bankAcc[field] = bsb_code;
          break;
        }

        case 'clabe': {
          bankAcc[field] = clabe;
          break;
        }
        case 'cnaps': {
          bankAcc[field] = cnaps;
          break;
        }
        case 'ifsc': {
          bankAcc[field] = ifsc;
          break;
        }

        case 'sort_code': {
          bankAcc[field] = sort_code;
          break;
        }

        case 'bank_name': {
          bankAcc[field] = bankName;
          break;
        }

        case 'bank_address': {
          bankAcc[field] = bankAddress;
          break;
        }

        case 'identification': {
          bankAcc[field] = identification;
          break;
        }

        default: {
          console.log(field);
          break;
        }
      }
    }

    console.log(bankAcc);
    return bankAcc;
  };

  const { theme } = props;

  const [aba, setAba] = React.useState('');
  const [accNum, setAccNum] = React.useState('');
  const [addressLine1, setAddressLine1] = React.useState('');
  const [addressLine2, setAddressLine2] = React.useState('');
  const [avatar, setAvatar] = React.useState('');
  const [bankAddress, setBankAddress] = React.useState('');
  const [bankCountry, setBankCountry] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [bank_code, setBank_code] = React.useState('');
  const [bic_swift, setBic_swift] = React.useState('');
  const [branchCode, setBranchCode] = React.useState('');
  const [bsb_code, setBsb_code] = React.useState('');
  const [clabe, setClabe] = React.useState('');
  const [cnaps, setCnaps] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [countryOptions, setCountryOptions] = React.useState([
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ]);
  const [currency, setCurrency] = React.useState('');
  const [currencyOptions, setCurrencyOptions] = React.useState([
    'aed',
    'afn',
    'all',
    'amd',
    'ang',
    'aoa',
    'ars',
    'aud',
    'awg',
    'azn',
    'bam',
    'bbd',
    'bdt',
    'bgn',
    'bhd',
    'bif',
    'bmd',
    'bnd',
    'bob',
    'brl',
    'bsd',
    'btn',
    'bwp',
    'byn',
    'bzd',
    'cad',
    'cdf',
    'chf',
    'clp',
    'cny',
    'cop',
    'crc',
    'cup',
    'cve',
    'czk',
    'djf',
    'dkk',
    'dop',
    'dzd',
    'egp',
    'ern',
    'etb',
    'eur',
    'fjd',
    'fkp',
    'gbp',
    'gel',
    'ghs',
    'gip',
    'gmd',
    'gnf',
    'gtq',
    'gyd',
    'hkd',
    'hnl',
    'hrk',
    'htg',
    'huf',
    'idr',
    'ils',
    'inr',
    'iqd',
    'irr',
    'isk',
    'jmd',
    'jod',
    'jpy',
    'kes',
    'kgs',
    'khr',
    'kmf',
    'kpw',
    'krw',
    'kwd',
    'kyd',
    'kzt',
    'lak',
    'lbp',
    'lkr',
    'lrd',
    'lsl',
    'ltl',
    'lvl',
    'lyd',
    'mad',
    'mdl',
    'mga',
    'mkd',
    'mmk',
    'mnt',
    'mop',
    'mro',
    'mur',
    'mvr',
    'mwk',
    'mxn',
    'myr',
    'mzn',
    'nad',
    'ngn',
    'nio',
    'nok',
    'npr',
    'nzd',
    'omr',
    'pab',
    'pen',
    'pgk',
    'php',
    'pkr',
    'pln',
    'pyg',
    'qar',
    'ron',
    'rsd',
    'rub',
    'rwf',
    'sar',
    'sbd',
    'scr',
    'sdg',
    'sek',
    'sgd',
    'shp',
    'sll',
    'sos',
    'srd',
    'ssp',
    'std',
    'syp',
    'szl',
    'thb',
    'tjs',
    'tmt',
    'tnd',
    'top',
    'try',
    'ttd',
    'tvd',
    'twd',
    'tzs',
    'uah',
    'ugx',
    'usd',
    'uyu',
    'uzs',
    'ved',
    'vnd',
    'vuv',
    'wst',
    'xaf',
    'xcd',
    'xof',
    'xpf',
    'yer',
    'zar',
    'zmw',
  ]);
  const [hasPrimaryError, setHasPrimaryError] = React.useState(false);
  const [iban, setIban] = React.useState('');
  const [identification, setIdentification] = React.useState('');
  const [ifsc, setIfsc] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [profileObj, setProfileObj] = React.useState({});
  const [region, setRegion] = React.useState('');
  const [requirements, setRequirements] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [sort_code, setSort_code] = React.useState('');
  const [step, setStep] = React.useState(0);
  const [townCity, setTownCity] = React.useState('');
  const [updating, setUpdating] = React.useState(false);

  return (
    <ScreenContainer scrollable={true} hasSafeArea={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 40, marginTop: 40, width: '75%' },
            dimensions.width
          )}
        >
          {/* Title */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 0, marginRight: 16 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 30,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Update Bank Details'}
            </Text>
            <Divider
              style={StyleSheet.applyWidth(
                {
                  height: 1,
                  marginBottom: 16,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 16,
                },
                dimensions.width
              )}
              color={theme.colors.greyLines}
            />
          </View>
          {/* Public Profile */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 0, marginRight: 16, marginTop: 40, width: '50%' },
              dimensions.width
            )}
          >
            <Spacer top={8} right={8} bottom={8} left={8} />
            {/* primary details */}
            <View>
              {/* country currency */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', justifyContent: 'space-between' },
                  dimensions.width
                )}
              >
                {/* Country */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <Picker
                    onValueChange={newPickerValue => {
                      try {
                        setBankCountry(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: 40, width: 180 },
                      dimensions.width
                    )}
                    options={countryOptions}
                    value={bankCountry}
                    label={'Country Code'}
                    placeholder={'Select an option'}
                    leftIconMode={'inset'}
                    type={'solid'}
                    iconSize={24}
                    rightIconName={'AntDesign/down'}
                  />
                </View>
                {/* currency */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <Picker
                    onValueChange={newPickerValue => {
                      try {
                        setCurrency(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: 40, width: 180 },
                      dimensions.width
                    )}
                    options={currencyOptions}
                    value={currency}
                    label={'Currency'}
                    placeholder={'Select an option'}
                    leftIconMode={'inset'}
                    type={'solid'}
                    iconSize={24}
                    rightIconName={'AntDesign/down'}
                  />
                </View>
              </View>
              <>
                {!hasPrimaryError ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors['Error'] },
                      dimensions.width
                    )}
                  >
                    {"The country code and the currency doesn't support"}
                  </Text>
                )}
              </>
              {/* Update */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      setUpdating(true);
                      const reqRes =
                        await SupabaseEdgeFunctionsApi.getVerificationRequirementsPOST(
                          Constants,
                          { country_code: bankCountry, currency_code: currency }
                        );

                      const valueZhyQr0gM = reqRes?.requirements;
                      setRequirements(valueZhyQr0gM);
                      const requiredFields = valueZhyQr0gM;
                      if (checkRequirementsError(requiredFields)) {
                        return;
                      }
                      setUpdating(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.primary,
                    borderRadius: 8,
                    fontFamily: 'System',
                    fontWeight: '700',
                    marginTop: 16,
                    textAlign: 'center',
                    width: 150,
                  },
                  dimensions.width
                )}
                loading={updating}
                title={'Get Fields'}
              />
            </View>
            {/* Secondary */}
            <>
              {!requirements?.length ? null : (
                <View>
                  {/* Bank Name & address */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* Bank Name */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontSize: 16,
                            fontWeight: '600',
                          },
                          dimensions.width
                        )}
                      >
                        {'Bank Name'}
                      </Text>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setBankName(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        value={bankName}
                        editable={true}
                        placeholder={'Enter a value...'}
                      />
                    </View>
                    {/* Bank Address */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontSize: 16,
                            fontWeight: '600',
                          },
                          dimensions.width
                        )}
                      >
                        {'Bank Address'}
                      </Text>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setBankAddress(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        value={bankAddress}
                        editable={true}
                        placeholder={'Enter a value...'}
                      />
                    </View>
                  </View>
                  {/* Bank */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* Bank Code */}
                    <>
                      {!isFieldRequired('bank_code') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'Bank Code'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setBank_code(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={bank_code}
                            editable={true}
                            placeholder={'Enter a value...'}
                          />
                        </View>
                      )}
                    </>
                    {/* BIC Swift */}
                    <>
                      {!isFieldRequired('bic_swift') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'BIC Swift'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setBic_swift(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={bic_swift}
                            editable={true}
                            placeholder={'Enter a value...'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                  {/* Bank */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* Branch */}
                    <>
                      {!isFieldRequired('branch_code') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'Branch Code'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setBranchCode(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={branchCode}
                            editable={true}
                            placeholder={'Enter a value...'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                  {/* Bank-US */}
                  <>
                    {!(bankCountry === 'US') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* ABA */}
                        <>
                          {!isFieldRequired('aba') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'ABA'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setAba(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={aba}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Bank-AU */}
                  <>
                    {!(bankCountry === 'AU') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* BSB Code */}
                        <>
                          {!isFieldRequired('bsb_code') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'BSB Code'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setBsb_code(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={bsb_code}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Bank-IN */}
                  <>
                    {!(bankCountry === 'IN') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* IFSC Code */}
                        <>
                          {!isFieldRequired('ifsc') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'IFSC Code'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setIfsc(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={ifsc}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Bank-MX */}
                  <>
                    {!(bankCountry === 'MX') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Clabe */}
                        <>
                          {!isFieldRequired('clabe') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'CLABE'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setClabe(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={clabe}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                        {/* Identification */}
                        <>
                          {!isFieldRequired('identification') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Identification'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setIdentification(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={identification}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Bank-CN */}
                  <>
                    {!(bankCountry === 'CN') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* CNAPS */}
                        <>
                          {!isFieldRequired('cnaps') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'CNAPS'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setCnaps(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={cnaps}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Bank-UK */}
                  <>
                    {!(bankCountry === 'UK') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* sort code */}
                        <>
                          {!isFieldRequired('sort_code') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '600',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Sort Code'}
                              </Text>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setSort_code(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.divider,
                                    borderLeftWidth: 1,
                                    borderRadius: 8,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                                value={sort_code}
                                editable={true}
                                placeholder={'Enter a value...'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Account */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* account number */}
                    <>
                      {!isFieldRequired('account_number') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'Account Number'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setAccNum(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={accNum}
                            editable={true}
                            placeholder={'Enter a value...'}
                          />
                        </View>
                      )}
                    </>
                    {/* iban */}
                    <>
                      {!isFieldRequired('iban') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'IBAN'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setIban(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={iban}
                            editable={true}
                            placeholder={'Enter a value...'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                  {/* Connect */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const bank_account = createBankAccountObj();
                          await SupabaseEdgeFunctionsApi.createVerificationPOST(
                            Constants,
                            {
                              acc_details: createBankAccountObj(),
                              address_line1: addressLine1,
                              address_line2: addressLine2,
                              city: townCity,
                              country_code: country,
                              email: (props.route?.params?.profileData ?? {})
                                ?.email,
                              fullName: getFullName(
                                props.route?.params?.profileData ?? {}
                              ),
                              post_code: postalCode,
                              region: region,
                            }
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 8,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 16,
                        textAlign: 'center',
                        width: 150,
                      },
                      dimensions.width
                    )}
                    disabled={saving}
                    loading={saving}
                    title={'Connect'}
                  />
                </View>
              )}
            </>
          </View>
          {/* Help */}
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: 16, marginTop: 16, paddingLeft: 0 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 24,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Help'}
            </Text>
            {/* Contact Us Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://go.crisp.chat/chat/embed/?website_id=5da7a8de-200b-43f5-80df-d44a951658f2'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Contact Us'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
            {/* Report Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://airtable.com/shrBGs9tyPUfrRGLX'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Report Inappropriate or Concerning Behaviour'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
            {/* Request Features Touchable */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 0 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '700',
                      marginRight: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Request New Features'}
                </Text>
                <Icon name={'AntDesign/right'} size={16} />
              </View>
            </Touchable>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UpdateBankDetailsScreen);
