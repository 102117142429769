import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getLXPsGETStatusAndText = Constants =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxps_for_author_feed`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getLXPsGET = Constants =>
  getLXPsGETStatusAndText(Constants).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useGetLXPsGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxps_for_author_feed`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetLXPsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxps_for_author_feed`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetLXPs: refetch });
};

export const getScheduledSessionsGETStatusAndText = Constants =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_provider_get_scheduled_sessions`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getScheduledSessionsGET = Constants =>
  getScheduledSessionsGETStatusAndText(Constants).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetScheduledSessionsGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_provider_get_scheduled_sessions`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetScheduledSessionsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_provider_get_scheduled_sessions`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetScheduledSessions: refetch,
  });
};

export const getMultidayLXPSeriesPOSTStatusAndText = (Constants, { lxp_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_multiday_lxp_series`,
    {
      body: JSON.stringify({ lxp_id_in: lxp_id }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getMultidayLXPSeriesPOST = (Constants, { lxp_id }) =>
  getMultidayLXPSeriesPOSTStatusAndText(Constants, { lxp_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetMultidayLXPSeriesPOST = ({ lxp_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_multiday_lxp_series`,
    {
      body: JSON.stringify({ lxp_id_in: lxp_id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  );
};

export const FetchGetMultidayLXPSeriesPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  lxp_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_multiday_lxp_series`,
    {
      body: JSON.stringify({ lxp_id_in: lxp_id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_BEARER'],
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetMultidayLXPSeries: refetch,
  });
};
