import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RPCApi from '../apis/RPCApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const LearningCentersScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const checkStatus = item => {
    // return status === 'all' || ( (item.archived && status === 'archived') || item.status === status)

    if (status === 'all') return true;
    else if (status === 'archived') return item.archived ? true : false;
    else return !item.archived && item.status === status ? true : false;
  };

  // return true if there are lxps with current tab status
  const hasLXPs = fetchData => {
    return (
      fetchData.filter(data => status === 'all' || data.status === status)
        .length > 0
    );
  };

  const convertToFormObj = item => {
    const keyMap = {
      lxp_id: 'id',

      coverimage: 'coverImage',
      title: 'title',

      isvirtual: 'isVirtual',
      lxp_location_type: 'location_type',
      subject: 'subject',
      difficulty: 'difficulty',
      description: 'description',
      required_resources: 'requiredResources',
      zoom_url: 'zoom_url',

      zoom_id: 'zoom_id',
      zoom_password: 'zoom_password',

      isOneTime: 'isOneTime',
      pricing_per_session: 'pricing_per_session',
      pricing_per_series: 'pricing_per_series',

      min_age: 'ageRangeMin',
      max_age: 'ageRangeMax',

      learning_center_id: 'learning_center_id',
      learning_center: 'learning_center',

      min_participants: 'capacityRangeMin',
      max_participants: 'capacityRangeMax',

      status: 'status',
      archived: 'archived',
      id: 'id',
    };

    const newObj = {};
    const boolFields = ['isOneTime', 'isVirtual', 'archived'];
    for (let [key, value] of Object.entries(item)) {
      // console.log(key, value):w
      let newKey = keyMap[key];
      if (newKey) {
        if (boolFields.includes(newKey)) newObj[newKey] = value;
        else newObj[newKey] = { value, error: '' };
      }
    }

    newObj['explainVideo'] = { value: '', error: '' };
    // console.log(item)
    if (item?.series?.length)
      newObj['series'] = item.series.map(({ series_no, sessions }) => ({
        series_no,
        durationPerSession: sessions[0].duration,
        error: '',
        sessions,
      }));
    else
      newObj['series'] = [
        { series_no: 1, sessions: [], error: '', durationPerSession: 0 },
      ];
    console.log(newObj);
    return newObj;
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CURRENT_SCREEN',
        value: 'LEARNING_CENTERS',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [initialFormObj, setInitialFormObj] = React.useState({
    id: { value: '' },
    title: { error: '', value: '' },
    series: [{ id: 1, error: '', sessions: [], durationPerSession: 0 }],
    subject: { error: '', value: 'Arts' },
    zoom_id: { error: '', value: '' },
    zoom_url: { error: '', value: '' },
    isOneTime: true,
    isVirtual: true,
    coverImage: { error: '', value: '' },
    difficulty: { value: 1 },
    ageRangeMax: { error: '', value: 0 },
    ageRangeMin: { error: '', value: 0 },
    description: { error: '', value: '' },
    explainVideo: { error: '', value: '' },
    zoom_password: { error: '', value: '' },
    capacityRangeMax: { error: '', value: 0 },
    capacityRangeMin: { error: '', value: 0 },
    requiredResources: { value: '' },
    pricing_per_series: { error: '', value: 0 },
    pricing_per_session: { error: '', value: 0 },
  });
  const [status, setStatus] = React.useState('all');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Sidebar */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Sidebar'],
            dimensions.width
          )}
        >
          {/* Profile */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '100%' },
              dimensions.width
            )}
          >
            {/* Upload avatar */}
            <>
              {Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  source={{
                    uri: `${getNameAvatarUrl(Constants['USER_DATA'])}`,
                  }}
                  size={60}
                />
              )}
            </>
            <>
              {!Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  size={60}
                  source={{ uri: `${Constants['USER_DATA']?.profile_picture}` }}
                />
              )}
            </>
            {/* User Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 12 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['USER_DATA']?.firstName}{' '}
              {Constants['USER_DATA']?.lastName}
            </Text>
            <Spacer top={8} bottom={8} left={0} right={4} />
            <>
              {!Constants['USER_DATA']?.is_verified ? null : (
                <Icon
                  size={24}
                  name={'MaterialIcons/verified'}
                  color={theme.colors['Primary']}
                />
              )}
            </>
          </View>
          {/* new icon nav */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Learning Center Nav 7'],
              dimensions.width
            )}
          >
            {/* Home */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'HOME',
                  });
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Application / Roles */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'APPLICATION',
                  });
                  navigation.navigate('ApplicationScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Inbox Item */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INBOX',
                  });
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* My Learning Experiences */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'MY LXP',
                  });
                  navigation.navigate('MyExperiencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Learning Centers */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'LEARNING_CENTERS',
                  });
                  navigation.navigate('LearningCentersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(
                  Constants['CURRENT_SCREEN'] === 'LEARNING_CENTERS'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(
                  Constants['CURRENT_SCREEN'] === 'LEARNING_CENTERS'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Insights */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INSIGHTS',
                  });
                  navigation.navigate('InsightsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Light'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Feedback */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/feature-requests'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/message-processing-outline'}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback'}
                </Text>
              </View>
            </Touchable>
            {/* Marketing Support Nav */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Marketing-Strategies-to-Grow-Your-Offerings-42c25ec6074d428194faf210e270d67a'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'AntDesign/adduser'} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Marketing Support'}
                </Text>
              </View>
            </Touchable>
            {/* Settings */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'SETTINGS',
                  });
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Primary 20%'],
                        },
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            { height: '100%', width: '75%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: [
                  { minWidth: Breakpoints.Desktop, value: 'row' },
                  { minWidth: Breakpoints.Tablet, value: 'row' },
                ],
                justifyContent: [
                  { minWidth: Breakpoints.Desktop, value: 'space-between' },
                  { minWidth: Breakpoints.Tablet, value: 'space-between' },
                ],
                paddingRight: { minWidth: Breakpoints.Desktop, value: 60 },
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Title'], {
                  fontFamily: {
                    minWidth: Breakpoints.Desktop,
                    value: 'Inter_700Bold',
                  },
                }),
                dimensions.width
              )}
            >
              {'Learning Centers'}
            </Text>
            <Button
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Add New Experience Button'],
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Desktop,
                      value: theme.colors['Grey Lines'],
                    },
                    color: {
                      minWidth: Breakpoints.Desktop,
                      value: theme.colors['Custom Color'],
                    },
                    width: { minWidth: Breakpoints.Tablet, value: 230 },
                  }
                ),
                dimensions.width
              )}
              icon={'AntDesign/plus'}
              title={'New Learning Center'}
            />
          </View>
          {/* Tab Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Background'],
                height: '95%',
                marginTop: 16,
              },
              dimensions.width
            )}
          >
            <ScrollView
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
              showsVerticalScrollIndicator={true}
              bounces={true}
            >
              <FlatList
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <>
                      {/*  Container */}
                      <>
                        {!checkStatus(listData) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Grey Lines'],
                                borderLeftWidth: 1,
                                borderRadius: [
                                  { minWidth: Breakpoints.Desktop, value: 4 },
                                  { minWidth: Breakpoints.Laptop, value: 4 },
                                ],
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                marginBottom: 20,
                                marginRight: 20,
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 10,
                                width: 300,
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Image
                                style={StyleSheet.applyWidth(
                                  {
                                    borderTopLeftRadius: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 4,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 4,
                                      },
                                    ],
                                    borderTopRightRadius: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 4,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 4,
                                      },
                                    ],
                                    height: 100,
                                  },
                                  dimensions.width
                                )}
                                resizeMode={'cover'}
                                source={Images.Frame347}
                              />
                            </View>
                            {/* title container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginTop: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 12 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Title'],
                                  dimensions.width
                                )}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                              >
                                {'Makerversity'}
                              </Text>
                            </View>
                            <Spacer right={8} left={8} top={8} bottom={0} />
                            {/* virtual */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Inactive Grey']}
                                size={15}
                                name={'Entypo/location-pin'}
                              />
                              <Spacer top={8} right={8} bottom={8} left={0} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong },
                                  dimensions.width
                                )}
                              >
                                {'Address'}
                              </Text>
                            </View>
                            {/* type */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Inactive Grey']}
                                size={15}
                                name={'Ionicons/pricetag-outline'}
                              />
                              <Spacer top={8} right={8} bottom={8} left={0} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong },
                                  dimensions.width
                                )}
                              >
                                {'Type'}
                              </Text>
                            </View>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* description container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { height: 40, minHeight: 40 },
                                dimensions.width
                              )}
                            >
                              {/* description */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong },
                                  dimensions.width
                                )}
                                numberOfLines={2}
                                ellipsizeMode={'clip'}
                              >
                                {'We are providing creative workspace'}
                              </Text>
                            </View>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* tags */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* session */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  name={'Entypo/calendar'}
                                  color={theme.colors['Inactive Grey']}
                                  size={15}
                                />
                                <Spacer top={8} right={8} bottom={8} left={0} />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors.strong },
                                    dimensions.width
                                  )}
                                >
                                  {10}
                                  {' session'}
                                </Text>
                              </View>
                              <Spacer top={8} right={8} bottom={8} left={8} />
                            </View>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* Button Container */}
                            <>
                              {listData?.archived ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center' },
                                    dimensions.width
                                  )}
                                >
                                  {/* non action button */}
                                  <Button
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor: theme.colors.primary,
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors['Primary'],
                                        borderLeftWidth: 1,
                                        borderRadius: 8,
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        color: theme.colors['Background'],
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '85%',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                    title={'View Schedule'}
                                  />
                                </View>
                              )}
                            </>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* Manage Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'flex-start',
                                    },
                                  ],
                                  alignSelf: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'flex-start',
                                  },
                                  flex: 1,
                                  justifyContent: 'center',
                                  marginBottom: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 0,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  try {
                                    const converted =
                                      convertToFormObj(listData);
                                    navigation.navigate(
                                      'CreateUpdateALearningExperienceScreen',
                                      {
                                        mode: 'Edit',
                                        initialFormObj: converted,
                                      }
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <Icon
                                  size={20}
                                  name={'Ionicons/settings-outline'}
                                  color={theme.colors['Custom Color']}
                                />
                              </Touchable>
                            </View>
                          </View>
                        )}
                      </>
                    </>
                  );
                }}
                data={[1, 2, 3]}
                listKey={'ljgve68C'}
                keyExtractor={listData =>
                  listData?.id || listData?.uuid || JSON.stringify(listData)
                }
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexDirection: 'row', flexWrap: 'wrap', paddingBottom: 40 },
                  dimensions.width
                )}
                horizontal={false}
                numColumns={3}
              />
            </ScrollView>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LearningCentersScreen);
