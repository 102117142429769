import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getLearningCenterListGETStatusAndText = Constants =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getLearningCenterListGET = Constants =>
  getLearningCenterListGETStatusAndText(Constants).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetLearningCenterListGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetLearningCenterListGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetLearningCenterList: refetch,
  });
};

export const getProfileByIdGETStatusAndText = (Constants, { user_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getProfileByIdGET = (Constants, { user_id }) =>
  getProfileByIdGETStatusAndText(Constants, { user_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetProfileByIdGET = ({ user_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetProfileByIdGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetProfileById: refetch });
};

export const getProfileByHandleGETStatusAndText = (Constants, { handel }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?handle=eq.${
      handel ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getProfileByHandleGET = (Constants, { handel }) =>
  getProfileByHandleGETStatusAndText(Constants, { handel }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetProfileByHandleGET = ({ handel }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?handle=eq.${
      handel ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetProfileByHandleGET = ({
  children,
  onData = () => {},
  refetchInterval,
  handel,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?handle=eq.${
      handel ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetProfileByHandle: refetch });
};

export const getSalesGETStatusAndText = (Constants, { seller_id }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)&seller_id=eq.${
      seller_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getSalesGET = (Constants, { seller_id }) =>
  getSalesGETStatusAndText(Constants, { seller_id }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetSalesGET = ({ seller_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)&seller_id=eq.${
      seller_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );
};

export const FetchGetSalesGET = ({
  children,
  onData = () => {},
  refetchInterval,
  seller_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/checkouts?select=*,buyer:buyer_id(*),lxp:lxp_id(*)&seller_id=eq.${
      seller_id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetSales: refetch });
};

export const getSubjectsGETStatusAndText = Constants =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/subjects`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getSubjectsGET = Constants =>
  getSubjectsGETStatusAndText(Constants).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useGetSubjectsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['subjects', args], () => getSubjectsGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetSubjectsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetSubjectsGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetSubjects: refetch });
};

export const upsertSessionPOSTStatusAndText = (Constants, { arr }) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions`, {
    body: JSON.stringify(arr),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      Prefer: 'resolution=merge-duplicates',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const upsertSessionPOST = (Constants, { arr }) =>
  upsertSessionPOSTStatusAndText(Constants, { arr }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useUpsertSessionPOST = ({ arr }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions`, {
    body: JSON.stringify(arr),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      Prefer: 'resolution=merge-duplicates',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });
};

export const FetchUpsertSessionPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  arr,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/sessions`, {
    body: JSON.stringify(arr),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      Prefer: 'resolution=merge-duplicates',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchUpsertSession: refetch });
};
