import * as Linking from "expo-linking";
const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      path: "",
      initialRouteName: "WelcomeScreen",
      WelcomeScreen: "welcome",
      UpdatePasswordScreen: {
        path: "update_password/:accessToken",
        parse: {
          accessToken: (token) => token,
        },
      },
      LoginScreen: "login",
      ApplicationScreen: "application",
      CreateUpdateALearningExperienceScreen: "create_update_lxp",
      ExternalPageScreen: "external_page",
      HomeScreen_0FrGgFCx: "home",
      InsightsScreen: "insights",
      InboxScreen: "inbox",
      LearningCentersScreen: "learning_centers",
      MyExperiencesScreen: "my_experiences",
      PublicProfileScreen: {
        path: "public_profile/:handle",
        parse: {
          handle: (s) => s,
        },
      },
      RequestPasswordLinkScreen: "request_password_link",
      SettingsScreen: "settings",
      SignupScreen: "signup",
    },
  },
};

export default linking;
