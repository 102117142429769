import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as SupabaseEdgeFunctionsApi from '../apis/SupabaseEdgeFunctionsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import formatAuthBearer from '../global-functions/formatAuthBearer';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Text, View, useWindowDimensions } from 'react-native';

const SignupScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const createContactObject = (email, firstName, lastName) => {
    return { email, firstName, lastName };
  };

  const setErrorMsg = (err1, err2) => {
    return err1 || err2;
  };

  const setFieldValue = (value, field, form) => {
    return { ...form, [field]: { ...form[`${field}`], value } };
  };

  const getSignUpLabel = () => {
    return isSubmitting ? 'Signing Up' : 'Sign Up';
  };

  const validateForm = oldObj => {
    var foundError = false;

    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
    const passwordExpr = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!$#%_]{8,}$/;
    // console.log(oldObj)

    setFormObj(oldObj => {
      let newObj = {};
      for (let [key, obj] of Object.entries(oldObj)) {
        // console.log(key, obj)
        let val = obj.value;

        switch (key) {
          case 'email': {
            if (!expr.test(val)) {
              obj.error = 'Please enter a valid email';
              foundError = true;
            } else {
              obj.error = '';
            }
            break;
          }

          case 'password': {
            if (!passwordExpr.test(val)) {
              obj.error = 'Invalid Password.';
              foundError = true;
            } else {
              obj.error = '';
            }
            break;
          }

          case 'firstName':
          case 'lastName': {
            if (val.length < 1) {
              if (key == 'firstName') obj.error = 'First name cannot be empty';
              else obj.error = 'Last name cannot be empty';
              foundError = true;
            } else {
              obj.error = '';
            }
            break;
          }
        }

        newObj = { ...newObj, [key]: { ...obj } };
      }
      return newObj;
    });

    return foundError;

    return foundError;
  };

  const { theme } = props;
  const { navigation } = props;

  const [FORM_ERROR, setFORM_ERROR] = React.useState('');
  const [formObj, setFormObj] = React.useState({
    email: { error: '', value: '' },
    lastName: { error: '', value: '' },
    password: { error: '', value: '' },
    firstName: { error: '', value: '' },
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { alignItems: 'center', justifyContent: 'center' },
        dimensions.width
      )}
      hasSafeArea={false}
      scrollable={false}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: 350,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.strong,
              fontFamily: [
                { minWidth: Breakpoints.Mobile, value: 'System' },
                { minWidth: Breakpoints.Laptop, value: 'Inter_700Bold' },
              ],
              fontSize: 24,
              fontWeight: { minWidth: Breakpoints.Mobile, value: '700' },
            },
            dimensions.width
          )}
        >
          {'Create Account'}
        </Text>
        <Spacer right={8} left={8} top={8} bottom={0} />
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Inter Regular 400 14pt'],
              { fontFamily: 'System', fontWeight: '400' }
            ),
            dimensions.width
          )}
        >
          {'Let’s get started'}
        </Text>
        <Spacer top={8} right={8} left={8} bottom={16} />
        {/* Name Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          {/* Field */}
          <View
            style={StyleSheet.applyWidth(
              { flexBasis: 170, flexGrow: 0, flexShrink: 1 },
              dimensions.width
            )}
          >
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Signup Form Label'],
                dimensions.width
              )}
            >
              {'First Name'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setFormObj(
                    setFieldValue(newTextInputValue, 'firstName', formObj)
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Signup Form Field'],
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              defaultValue={formObj?.firstName.value}
            />
            {/* Error */}
            <>
              {!formObj?.firstName.error ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {formObj?.firstName.error}
                </Text>
              )}
            </>
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          {/* Field */}
          <View
            style={StyleSheet.applyWidth(
              { flexBasis: 170, flexGrow: 0, flexShrink: 1 },
              dimensions.width
            )}
          >
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Signup Form Label'],
                dimensions.width
              )}
            >
              {'Last Name'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setFormObj(
                    setFieldValue(newTextInputValue, 'lastName', formObj)
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Signup Form Field'],
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              defaultValue={formObj?.lastName.value}
            />
            {/* Error */}
            <>
              {!formObj?.lastName.error ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {formObj?.lastName.error}
                </Text>
              )}
            </>
          </View>
        </View>
        <Spacer top={8} right={8} bottom={8} left={8} />
        {/* Email Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Signup Form Label'],
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setFormObj(
                    setFieldValue(newTextInputValue, 'email', formObj)
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Signup Form Field'],
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              defaultValue={formObj?.email.value}
            />
            {/* Error */}
            <>
              {!formObj?.email.error ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {formObj?.email.error}
                </Text>
              )}
            </>
          </View>
        </View>
        <Spacer top={8} right={8} bottom={8} left={8} />
        {/* Handle Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Signup Form Label'],
                dimensions.width
              )}
            >
              {'Handle'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setFormObj(
                    setFieldValue(newTextInputValue, 'handle', formObj)
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Signup Form Field'],
                dimensions.width
              )}
              placeholder={'@yourhandle'}
              editable={true}
            />
            {/* Error */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Error'], fontSize: 12 },
                dimensions.width
              )}
            >
              {null}
            </Text>
          </View>
        </View>
        <Spacer top={8} right={8} bottom={8} left={8} />
        {/* Password Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Signup Form Label'],
                dimensions.width
              )}
            >
              {'Password'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setFormObj(
                    setFieldValue(newTextInputValue, 'password', formObj)
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Signup Form Field'],
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              secureTextEntry={true}
              clearButtonMode={'while-editing'}
              spellcheck={true}
              defaultValue={formObj?.password.value}
            />
            {/* Error */}
            <>
              {!formObj?.password.error ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {formObj?.password.error}
                </Text>
              )}
            </>
          </View>
        </View>
        <Spacer top={8} right={8} bottom={8} left={8} />
        {/* Hint Wrapper */}
        <View>
          {/* Hint */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                {
                  color: theme.colors['Error'],
                  fontFamily: {
                    minWidth: Breakpoints.Laptop,
                    value: 'Montserrat_500Medium',
                  },
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Laptop, value: 14 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {
              'Password should be at least 8 characters in length, contain at least 1 letter, 1 number and 1 of these @!$#%_ special characters.'
            }
          </Text>
          <Spacer top={8} right={8} bottom={8} left={8} />
        </View>
        {/* Sign Up Options */}
        <View>
          {/* Email SIgn up */}
          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  const hasError = validateForm(undefined);
                  if (hasError) {
                    return;
                  }
                  setIsSubmitting(true);
                  const newLogin = await SupabaseAuthApi.providerSignUpPOST(
                    Constants,
                    {
                      email: formObj?.email.value,
                      firstName: formObj?.firstName.value,
                      handle: formObj?.handle.value,
                      lastName: formObj?.lastName.value,
                      password: formObj?.password.value,
                    }
                  );
                  const errMessage = newLogin?.message;
                  const accessToekn = newLogin?.access_token;
                  const authError = newLogin?.msg;
                  setFORM_ERROR(setErrorMsg(errMessage, authError));
                  setIsSubmitting(false);
                  const cObj = createContactObject(
                    formObj?.email.value,
                    formObj?.firstName.value,
                    formObj?.lastName.value
                  );
                  await SupabaseEdgeFunctionsApi.createASendGridContactPOST(
                    Constants,
                    {
                      action: 'add-contact',
                      contact: cObj,
                      list_id: Constants['WEB_INTERESTS_LIST_ID'],
                    }
                  );
                  if (setErrorMsg(errMessage, authError)) {
                    return;
                  }
                  navigation.navigate('LoginScreen');
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.primary,
                borderRadius: 8,
                fontFamily: 'System',
                fontWeight: '600',
                textAlign: 'center',
                width: 350,
              },
              dimensions.width
            )}
            disabled={isSubmitting}
            loading={isSubmitting}
            title={`${getSignUpLabel()}`}
          />
          <Spacer top={8} right={8} bottom={8} left={8} />
          {/* Error Wrapper */}
          <View>
            {/* Error */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  {
                    color: theme.colors['Error'],
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Montserrat_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 14 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {null}
            </Text>
            <Spacer top={8} right={8} bottom={8} left={8} />
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row' },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                { fontFamily: 'Montserrat_500Medium' }
              ),
              dimensions.width
            )}
          >
            {'Already have an account? '}
          </Text>

          <Touchable
            onPress={() => {
              try {
                navigation.navigate('LoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Link */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  {
                    color: theme.colors['Primary'],
                    fontFamily: 'Montserrat_500Medium',
                  }
                ),
                dimensions.width
              )}
            >
              {'Login'}
            </Text>
          </Touchable>
        </View>
        <Spacer top={8} right={8} bottom={8} left={8} />
        {/* Privacy Policy */}
        <View>
          {/* Line 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  { fontFamily: 'Montserrat_500Medium' }
                ),
                dimensions.width
              )}
            >
              {'By clicking sign up, you are agreeing to City as a'}
            </Text>

            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  { fontFamily: 'Montserrat_500Medium' }
                ),
                dimensions.width
              )}
            >
              {"School's "}
            </Text>
            <Link
              style={StyleSheet.applyWidth(
                GlobalStyles.LinkStyles(theme)['Montserrat Medium 14pt'],
                dimensions.width
              )}
              title={'Terms of Service'}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  { fontFamily: 'Montserrat_500Medium' }
                ),
                dimensions.width
              )}
            >
              {', including our '}
            </Text>
            <Link
              style={StyleSheet.applyWidth(
                GlobalStyles.LinkStyles(theme)['Montserrat Medium 14pt'],
                dimensions.width
              )}
              title={'Code of Conduct'}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Montserrat Regurlar 14pt'],
                  { fontFamily: 'Montserrat_500Medium' }
                ),
                dimensions.width
              )}
            >
              {', and our '}
            </Text>
            <Link
              style={StyleSheet.applyWidth(
                GlobalStyles.LinkStyles(theme)['Montserrat Medium 14pt'],
                dimensions.width
              )}
              title={'Privacy Policy'}
            />
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SignupScreen);
