import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RPCApi from '../apis/RPCApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import openUrlWithLinking from '../global-functions/openUrlWithLinking';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const HomeScreen_0FrGgFCx = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getNewForm = () => {
    return { ...CustomCode.formData, id: { value: getUUIDV4() } };
  };

  const formatDateTimeStr = (dateStr, format_str) => {
    // console.log(CustomCode.moment(dateStr).format('MMMM Do YYYY'))
    return CustomCode.moment(dateStr).format(format_str);
  };

  const getUUIDV4 = () => {
    return CustomCode.uuidv4();
  };

  const setFormObjField = () => {
    return CustomCode.formData;
  };

  const getUpcommingSessions = items => {
    return items.length ? items.filter(item => isUpcomingSession(item)) : [];
  };

  const isUpcomingSession = item => {
    // console.log(CustomCode.moment().diff(CustomCode.moment(dateStr), 'seconds') < 0, CustomCode.moment().diff(CustomCode.moment(dateStr), 'seconds'), dateStr)
    // console.log(item?.session_start)
    return (
      CustomCode.moment(item?.start_time).diff(CustomCode.moment(), 'seconds') >
      0
    );
  };

  const getFormattedEndTime = (start_str, duration, format_str) => {
    return CustomCode.moment(start_str)
      .add(duration.duration, 'minutes')
      .format(format_str);
  };

  const getLxpTypeLabel = type => {
    return type === 'zoom' ? 'Online' : 'In Person';
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CURRENT_SCREEN',
        value: 'HOME',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [initialObj, setInitialObj] = React.useState({
    id: { value: '' },
    title: { error: '', value: '' },
    series: [{ error: '', sessions: [], series_no: 1, durationPerSession: 0 }],
    status: { value: 'in-review' },
    subject: { value: 'Arts' },
    zoom_id: { error: '', value: '' },
    archived: { value: false },
    zoom_url: { error: '', value: '' },
    isOneTime: { value: true },
    isVirtual: { value: true },
    coverImage: { error: '', value: '' },
    difficulty: { value: 1 },
    ageRangeMax: { error: '', value: 0 },
    ageRangeMin: { error: '', value: 0 },
    description: { error: '', value: '' },
    explainVideo: { error: '', value: '' },
    zoom_password: { value: '' },
    capacityRangeMax: { error: '', value: 0 },
    capacityRangeMin: { error: '', value: 0 },
    requiredResources: { value: '' },
    pricing_per_series: { error: '', value: 0 },
    pricing_per_session: { error: '', value: 0 },
  });
  const [meeting_details, setMeeting_details] = React.useState({});
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Sidebar */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Sidebar'],
            dimensions.width
          )}
        >
          {/* Profile */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                flexDirection: [
                  { minWidth: Breakpoints.Mobile, value: 'row' },
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'flex-start',
                },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Upload avatar */}
            <>
              {Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    {
                      height: 84,
                      marginRight: { minWidth: Breakpoints.Laptop, value: 12 },
                      width: 84,
                    },
                    dimensions.width
                  )}
                  source={{
                    uri: `${getNameAvatarUrl(Constants['USER_DATA'])}`,
                  }}
                  size={60}
                />
              )}
            </>
            <>
              {!Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    {
                      height: 84,
                      marginRight: { minWidth: Breakpoints.Laptop, value: 12 },
                      width: 84,
                    },
                    dimensions.width
                  )}
                  size={60}
                  source={{ uri: `${Constants['USER_DATA']?.profile_picture}` }}
                />
              )}
            </>
            {/* User Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                  {
                    alignSelf: [
                      { minWidth: Breakpoints.Laptop, value: 'stretch' },
                      { minWidth: Breakpoints.Desktop, value: 'auto' },
                    ],
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: [
                      { minWidth: Breakpoints.Desktop, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 12 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['USER_DATA']?.firstName}{' '}
              {Constants['USER_DATA']?.lastName}
            </Text>
            <Spacer top={8} bottom={8} right={0} left={4} />
            {/* verified */}
            <>
              {!Constants['USER_DATA']?.is_verified ? null : (
                <Icon
                  size={24}
                  name={'MaterialIcons/verified'}
                  color={theme.colors['Primary']}
                />
              )}
            </>
          </View>
          {/* new Nav with matching icon */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Learning Center Nav 7'],
              dimensions.width
            )}
          >
            {/* Home */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'HOME',
                  });
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Application / Roles */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'APPLICATION',
                  });
                  navigation.navigate('ApplicationScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Inbox Item */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INBOX',
                  });
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* My Learning Experiences */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'MY LXP',
                  });
                  navigation.navigate('MyExperiencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Learning Centers */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'LEARNING_CENTERS',
                  });
                  navigation.navigate('LearningCentersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Insights */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INSIGHTS',
                  });
                  navigation.navigate('InsightsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Light'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Feedback */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/feature-requests'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/message-processing-outline'}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback'}
                </Text>
              </View>
            </Touchable>
            {/* Marketing Support Nav */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Marketing-Strategies-to-Grow-Your-Offerings-42c25ec6074d428194faf210e270d67a'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'AntDesign/adduser'} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Marketing Support'}
                </Text>
              </View>
            </Touchable>
            {/* Settings */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'SETTINGS',
                  });
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Primary 20%'],
                        },
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, height: '100%', width: '75%' },
            dimensions.width
          )}
        >
          {/* Experience */}
          <View
            style={StyleSheet.applyWidth(
              { height: '10%', marginLeft: 16, marginRight: 16, marginTop: 16 },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 30,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Welcome'}
            </Text>
            <Link
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Welcome-Teachers-FAQ-a1a647ddd35646df9792cefe7a778213'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                  fontFamily: 'System',
                  fontWeight: '400',
                  marginTop: 12,
                }),
                dimensions.width
              )}
              numberOfLines={1}
              title={'Watch walkthrough video'}
            />
            <Divider
              style={StyleSheet.applyWidth(
                {
                  height: 1,
                  marginBottom: 16,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 16,
                },
                dimensions.width
              )}
              color={theme.colors.greyLines}
            />
          </View>
          {/* Upcoming Sessions */}
          <View
            style={StyleSheet.applyWidth(
              {
                height: '90%',
                marginLeft: 16,
                marginRight: 16,
                marginTop: 22,
                paddingBottom: 110,
              },
              dimensions.width
            )}
          >
            {/* L2 Heading */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 24,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Quick Actions'}
            </Text>
            <Spacer top={8} right={8} bottom={8} left={8} />
            {/* Add New Experience Button */}
            <Button
              onPress={() => {
                try {
                  const newObj = getNewForm();
                  navigation.navigate('CreateUpdateALearningExperienceScreen', {
                    mode: 'Create',
                    initialFormObj: newObj,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.primary,
                  borderRadius: 8,
                  color: theme.colors['Background'],
                  fontFamily: 'System',
                  fontWeight: '700',
                  textAlign: 'center',
                  width: 300,
                },
                dimensions.width
              )}
              icon={'AntDesign/plus'}
              title={'Add New Experience'}
            />
            <Spacer right={8} left={8} bottom={16} top={16} />
            {/* L2 Heading */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 24,
                  fontWeight: '700',
                },
                dimensions.width
              )}
            >
              {'Upcoming Sessions'}
            </Text>
            <Spacer top={8} right={8} bottom={8} left={8} />
            <ScrollView
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
              contentContainerStyle={StyleSheet.applyWidth(
                { minHeight: 200 },
                dimensions.width
              )}
              showsVerticalScrollIndicator={true}
              bounces={true}
            >
              {/* Fetch Sessions */}
              <RPCApi.FetchGetScheduledSessionsGET
                onData={fetchSessionsData => {
                  try {
                    setUpcomingSessions(
                      getUpcommingSessions(fetchSessionsData)
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {({ loading, error, data, refetchGetScheduledSessions }) => {
                  const fetchSessionsData = data;
                  if (!fetchSessionsData || loading) {
                    return <ActivityIndicator />;
                  }

                  if (error) {
                    return (
                      <Text style={{ textAlign: 'center' }}>
                        There was a problem fetching this data
                      </Text>
                    );
                  }

                  return (
                    <>
                      {/* Empty Frame */}
                      <>
                        {upcomingSessions?.length ? null : (
                          <View>
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexWrap: 'nowrap' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                name={'AntDesign/calendar'}
                                size={80}
                                color={theme.colors['Inactive Grey']}
                              />
                              <Spacer right={8} left={8} top={22} bottom={0} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 20,
                                    fontWeight: '700',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'No Classes Scheduled'}
                              </Text>
                              <Spacer top={8} right={8} bottom={8} left={8} />
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color: theme.colors.strong,
                                    fontFamily: 'System',
                                    fontSize: 20,
                                    fontWeight: '400',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'This calendar has no upcoming sessions'}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                      <FlatList
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Main Conatainer */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: 'row',
                                    paddingBottom: 16,
                                    paddingTop: 16,
                                    width: '50%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value:
                                            theme.colors['NFT_TIME_Stone_Gray'],
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: theme.colors['Grey Lines'],
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Grey Lines'],
                                        },
                                      ],
                                      borderLeftWidth: 1,
                                      borderRadius: 9,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      paddingBottom: 20,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                      paddingTop: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* title */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'Poppins_600SemiBold',
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 'System',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'System',
                                          },
                                        ],
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 16,
                                          },
                                        ],
                                        fontWeight: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: '600',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '600',
                                          },
                                        ],
                                        marginBottom: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 12,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                    numberOfLines={1}
                                    ellipsizeMode={'tail'}
                                  >
                                    {listData?.title}
                                  </Text>
                                  {/* Timeline */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        marginBottom: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 8,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Date */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            theme.colors['Grey Lines'],
                                          borderRadius: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 20,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 20,
                                            },
                                          ],
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          paddingBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 6,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 6,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingTop: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        name={'Entypo/calendar'}
                                        size={16}
                                      />
                                      <Spacer
                                        top={8}
                                        bottom={8}
                                        right={0}
                                        left={6}
                                      />
                                      {/* content */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {formatDateTimeStr(
                                          listData?.start_time,
                                          'MMMM Do YYYY'
                                        )}
                                      </Text>
                                    </View>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                    {/* Time */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            theme.colors['Grey Lines'],
                                          borderRadius: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 20,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 20,
                                            },
                                          ],
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          paddingBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                          paddingTop: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 4,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 8,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        size={16}
                                        name={'AntDesign/clockcircleo'}
                                      />
                                      <Spacer
                                        top={8}
                                        bottom={8}
                                        right={0}
                                        left={6}
                                      />
                                      {/* content */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Custom Color'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {formatDateTimeStr(
                                          listData?.start_time,
                                          'h:mm a'
                                        )}
                                        {'-'}
                                        {getFormattedEndTime(
                                          listData?.start_time,
                                          listData,
                                          'h:mm a'
                                        )}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* Location */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderRadius: 10,
                                        flexDirection: 'row',
                                        marginTop: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 4,
                                        },
                                        paddingBottom: 4,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        paddingTop: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      size={16}
                                      name={'EvilIcons/location'}
                                    />
                                    <Spacer
                                      top={8}
                                      bottom={8}
                                      right={0}
                                      left={10}
                                    />
                                    {/* zoom */}
                                    <>
                                      {!listData?.isvirtual ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Custom Color'],
                                              fontFamily: 'Poppins_400Regular',
                                              fontSize: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'zoom'}
                                        </Text>
                                      )}
                                    </>
                                    {/* in person */}
                                    <>
                                      {listData?.isvirtual ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Custom Color'],
                                              fontFamily: 'Poppins_400Regular',
                                              fontSize: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'In person'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Participants */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderRadius: 10,
                                        flexDirection: 'row',
                                        paddingBottom: 4,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        paddingTop: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      size={16}
                                      name={'Ionicons/person-outline'}
                                    />
                                    <Spacer
                                      top={8}
                                      bottom={8}
                                      right={0}
                                      left={10}
                                    />
                                    {/* content */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Custom Color'],
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.enrolled_count}
                                      {' / '}
                                      {listData?.max_participants}
                                      {' Members'}
                                    </Text>
                                  </View>
                                  <Spacer
                                    top={8}
                                    right={8}
                                    bottom={8}
                                    left={8}
                                  />
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: {
                                          minWidth: Breakpoints.Desktop,
                                          value: '100%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Start Button */}
                                    <Button
                                      onPress={() => {
                                        try {
                                          openUrlWithLinking(
                                            listData?.zoom_url
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor: theme.colors.primary,
                                          borderRadius: 8,
                                          fontFamily: 'System',
                                          fontWeight: '700',
                                          textAlign: 'center',
                                          width: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '70%',
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: '100%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                      title={'Start Class'}
                                    />
                                  </View>
                                </View>
                                <Spacer top={8} right={8} bottom={8} left={8} />
                              </View>
                            </>
                          );
                        }}
                        data={upcomingSessions}
                        listKey={'3rFSi6R0'}
                        keyExtractor={listData => listData?.id}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flexWrap: 'wrap' },
                          dimensions.width
                        )}
                        numColumns={1}
                        horizontal={true}
                      />
                    </>
                  );
                }}
              </RPCApi.FetchGetScheduledSessionsGET>
            </ScrollView>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen_0FrGgFCx);
