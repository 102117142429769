import React from 'react';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as SupabaseRESTAPIAuthOptionalApi from '../apis/SupabaseRESTAPIAuthOptionalApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import formatAuthBearer from '../global-functions/formatAuthBearer';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const LoginScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const checkRedirect = () => {
    console.log('check redirect', redirectObj);
    if (redirectObj.path) {
      if (redirectObj.param)
        props.navigation.navigate(redirectObj.path, { ...redirectObj.param });
      else props.navigation.navigate(redirectObj.path);
      return true;
    }

    return false;
  };

  const getLoginLabel = () => {
    return isSubmitting ? 'Logging In' : 'Log In';
  };

  const updateRedirectObj = (path, param) => {
    setRedirectObj({ path, param });
  };

  const isProviderAccount = res => {
    // console.log('checking account type', res.user, res?.user?.user_metadata?.type)
    if (res?.user?.user_metadata?.type === 'provider') {
      console.log(res?.user?.user_metadata?.type);
      return true;
    } else {
      console.log('setting form error');
      setFORM_ERROR('Invalid Login attempt');
      return false;
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      updateRedirectObj(props.route?.params?.redirectPath ?? '', '');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [FORM_ERROR, setFORM_ERROR] = React.useState('');
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [passwordInputValue, setPasswordInputValue] = React.useState('');
  const [redirectObj, setRedirectObj] = React.useState({});

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { alignItems: 'center', justifyContent: 'center' },
        dimensions.width
      )}
      hasSafeArea={false}
      scrollable={false}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: 350,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors.strong,
              fontFamily: 'System',
              fontSize: 24,
              fontWeight: '700',
            },
            dimensions.width
          )}
        >
          {'Welcome Back!'}
        </Text>
        <Spacer right={8} bottom={8} left={8} top={20} />
        {/* Email Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Inactive Grey'], marginBottom: 6 },
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setEmailInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              clearTextOnFocus={true}
              defaultValue={emailInputValue}
            />
            {/* Error */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Error'], fontSize: 12 },
                dimensions.width
              )}
            >
              {null}
            </Text>
          </View>
        </View>
        <Spacer right={8} bottom={8} left={8} top={16} />
        {/* Password Row */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', width: '100%' },
            dimensions.width
          )}
        >
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Label */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Inactive Grey'], marginBottom: 6 },
                dimensions.width
              )}
            >
              {'Password'}
            </Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setPasswordInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              placeholder={' '}
              editable={true}
              secureTextEntry={true}
              clearButtonMode={'while-editing'}
              spellcheck={true}
              clearTextOnFocus={true}
            />
            {/* Error */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Error'], fontSize: 12 },
                dimensions.width
              )}
            >
              {null}
            </Text>
          </View>
        </View>
        <Spacer right={8} left={8} top={22} bottom={0} />
        {/* Forgot Password Row */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'stretch' },
            dimensions.width
          )}
        >
          {/* Forgot Password Touchable */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('RequestPasswordLinkScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Link */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Primary'] },
                dimensions.width
              )}
            >
              {'Forgot your password?'}
            </Text>
          </Touchable>
        </View>
        <Spacer right={8} left={8} top={22} bottom={0} />
        {/* Login Options */}
        <View>
          {/* Email Login */}
          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  setIsSubmitting(true);
                  const newLogin =
                    await SupabaseAuthApi.providerEmail$PasswordLoginPOST(
                      Constants,
                      { email: emailInputValue, password: passwordInputValue }
                    );
                  setIsSubmitting(false);
                  if (!isProviderAccount(newLogin)) {
                    return;
                  }
                  const errMessage = newLogin?.error_description;
                  const accessToekn = newLogin?.access_token;
                  setGlobalVariableValue({
                    key: 'AUTH_BEARER',
                    value: formatAuthBearer(accessToekn),
                  });
                  const authError = newLogin?.msg;
                  setFORM_ERROR(errMessage);
                  if (errMessage) {
                    return;
                  }
                  setGlobalVariableValue({
                    key: 'REFRESH_TOKEN',
                    value: '',
                  });
                  setGlobalVariableValue({
                    key: 'UUID',
                    value: newLogin?.user?.id,
                  });
                  const profileData =
                    await SupabaseRESTAPIAuthOptionalApi.getProfileByIdGET(
                      Constants,
                      { user_id: newLogin?.user?.id }
                    );
                  setGlobalVariableValue({
                    key: 'USER_DATA',
                    value: profileData && profileData[0],
                  });
                  if (checkRedirect()) {
                    return;
                  }
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.primary,
                borderRadius: 8,
                fontFamily: 'System',
                fontWeight: '700',
                textAlign: 'center',
                width: 350,
              },
              dimensions.width
            )}
            disabled={isSubmitting}
            loading={isSubmitting}
            title={`${getLoginLabel()}`}
          />
          <Spacer right={8} top={16} left={8} bottom={16} />
          {/* Error Wrapper */}
          <>
            {!FORM_ERROR ? null : (
              <View>
                {/* Error */}
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['Error'], fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {FORM_ERROR}
                </Text>
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
            )}
          </>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row' },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              { color: theme.colors.strong },
              dimensions.width
            )}
          >
            {"Don't have an account? "}
          </Text>

          <Touchable
            onPress={() => {
              try {
                navigation.navigate('SignupScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Link */}
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors['Primary'] },
                dimensions.width
              )}
            >
              {'Create Account'}
            </Text>
          </Touchable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
