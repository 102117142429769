import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { decode, encode } from 'base-64';
import formData from '../formObj.json';

import { createClient } from '@supabase/supabase-js';
// import createSDK from '@trustshare/sdk';

// import { Checkout } from '@trustshare/react-sdk';

// console.log(formData)
const API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI';

const supabase = createClient(
  'https://qthvouonhshkvbaugrhc.supabase.co',
  API_KEY
);
// const trustshare = createSDK('sandbox_pk_EcyGsQMrtG0JHQPfMKkzDCx7B65BXezd');

// export function CheckoutApp() {
//   return (
//     <div className="MyWrapper">
//       <Checkout
//         subdomain="demo"
//         to="seller@example.com"
//         from="buyer@example.com"
//         amount="150000"
//         depositAmount="100000"
//         description="An example description"
//         onComplete={({ status, token, paymentToken }) => {
//           // see `status` table below
//           console.log({ status, token, paymentToken });
//         }}
//         onUpdate={({ status, token, paymentToken }) => {
//           // see `status` table below
//           console.log({ status, token, paymentToken });
//         }}
//       />
//     </div>
//   );
// }

export { supabase, uuidv4, decode, encode, moment, formData };
