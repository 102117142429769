import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import ApplicationScreen from './screens/ApplicationScreen';
import BaggageCheckoutScreen from './screens/BaggageCheckoutScreen';
import CreateUpdateALearningExperienceScreen from './screens/CreateUpdateALearningExperienceScreen';
import ExternalPageScreen from './screens/ExternalPageScreen';
import HomeScreen_0FrGgFCx from './screens/HomeScreen_0FrGgFCx';
import InboxScreen from './screens/InboxScreen';
import InsightsScreen from './screens/InsightsScreen';
import LearningCentersScreen from './screens/LearningCentersScreen';
import LoginScreen from './screens/LoginScreen';
import MyExperiencesScreen from './screens/MyExperiencesScreen';
import PublicProfileScreen from './screens/PublicProfileScreen';
import RequestPasswordLinkScreen from './screens/RequestPasswordLinkScreen';
import SettingsScreen from './screens/SettingsScreen';
import SignupScreen from './screens/SignupScreen';
import UpdateBankDetailsScreen from './screens/UpdateBankDetailsScreen';
import UpdatePasswordScreen from './screens/UpdatePasswordScreen';
import WelcomeScreen from './screens/WelcomeScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Placeholder() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#131A2A',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 36,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 12,
          color: '#FFF',
        }}
      >
        Missing Screen
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        This screen is not in a navigator.
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        Go to Navigation mode, and click the + (plus) icon in the Navigator tab
        on the left side to add this screen to a Navigator.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, color: '#FFF' }}>
        If the screen is in a Tab Navigator, make sure the screen is assigned to
        a tab in the Config panel on the right.
      </Text>
    </View>
  );
}
export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator headerMode="none" initialRouteName="WelcomeScreen">
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={{
            title: 'Welcome',
          }}
        />
        <Stack.Screen
          name="SignupScreen"
          component={SignupScreen}
          options={{
            title: 'Signup',
          }}
        />
        <Stack.Screen
          name="LearningCentersScreen"
          component={LearningCentersScreen}
          options={{
            title: 'Learning Centers',
          }}
        />
        <Stack.Screen
          name="SettingsScreen"
          component={SettingsScreen}
          options={{
            title: 'Settings',
          }}
        />
        <Stack.Screen
          name="InsightsScreen"
          component={InsightsScreen}
          options={{
            title: 'Insights',
          }}
        />
        <Stack.Screen
          name="HomeScreen_0FrGgFCx"
          component={HomeScreen_0FrGgFCx}
          options={{
            title: 'Home',
          }}
        />
        <Stack.Screen
          name="ApplicationScreen"
          component={ApplicationScreen}
          options={{
            title: 'Application',
          }}
        />
        <Stack.Screen
          name="CreateUpdateALearningExperienceScreen"
          component={CreateUpdateALearningExperienceScreen}
          options={{
            title: 'Create Update A Learning Experience',
          }}
        />
        <Stack.Screen
          name="BaggageCheckoutScreen"
          component={BaggageCheckoutScreen}
          options={{
            title: 'Baggage - Checkout',
          }}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            title: 'Login',
          }}
        />
        <Stack.Screen
          name="PublicProfileScreen"
          component={PublicProfileScreen}
          options={{
            title: 'Public Profile',
          }}
        />
        <Stack.Screen
          name="UpdateBankDetailsScreen"
          component={UpdateBankDetailsScreen}
          options={{
            title: 'Update Bank Details',
          }}
        />
        <Stack.Screen
          name="ExternalPageScreen"
          component={ExternalPageScreen}
          options={{
            title: 'External Page',
          }}
        />
        <Stack.Screen
          name="RequestPasswordLinkScreen"
          component={RequestPasswordLinkScreen}
          options={{
            title: 'Request Password Link',
          }}
        />
        <Stack.Screen
          name="UpdatePasswordScreen"
          component={UpdatePasswordScreen}
          options={{
            title: 'Update Password',
          }}
        />
        <Stack.Screen
          name="InboxScreen"
          component={InboxScreen}
          options={{
            title: 'Inbox',
          }}
        />
        <Stack.Screen
          name="MyExperiencesScreen"
          component={MyExperiencesScreen}
          options={{
            title: 'My Experiences',
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
