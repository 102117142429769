import React from 'react';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { IconButton, ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { Text, View, useWindowDimensions } from 'react-native';

const ExternalPageScreen = props => {
  const dimensions = useWindowDimensions();

  const { theme } = props;
  const { navigation } = props;

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            paddingBottom: 20,
            paddingLeft: 20,
            paddingTop: 30,
          },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth({ marginRight: 8 }, dimensions.width)}
          color={theme.colors['Custom Color']}
          icon={'MaterialIcons/chevron-left'}
          size={32}
        />
        {/* Title */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color'],
              fontFamily: 'System',
              fontSize: 20,
              fontWeight: '600',
            },
            dimensions.width
          )}
        >
          {'Go Back'}
        </Text>
      </View>
      <WebView
        style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
        javaScriptEnabled={true}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        cacheEnabled={true}
        source={{ uri: `${props.route?.params?.redirect_link ?? ''}` }}
      />
    </ScreenContainer>
  );
};

export default withTheme(ExternalPageScreen);
