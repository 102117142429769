import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const checkToSendEmailAboutUpdateLXPPOSTStatusAndText = (
  Constants,
  { payload }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-utils`, {
    body: JSON.stringify({ action: 'check_sessions', payload: payload }),
    headers: {
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const checkToSendEmailAboutUpdateLXPPOST = (Constants, { payload }) =>
  checkToSendEmailAboutUpdateLXPPOSTStatusAndText(Constants, { payload }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useCheckToSendEmailAboutUpdateLXPPOST = ({ payload }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-utils`,
    {
      body: JSON.stringify({ action: 'check_sessions', payload: payload }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchCheckToSendEmailAboutUpdateLXPPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  payload,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-utils`,
    {
      body: JSON.stringify({ action: 'check_sessions', payload: payload }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchCheckToSendEmailAboutUpdateLXP: refetch,
  });
};

export const createVerificationPOSTStatusAndText = (
  Constants,
  {
    acc_details,
    address_line1,
    address_line2,
    city,
    country_code,
    email,
    fullName,
    post_code,
    region,
  }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_verification',
        payload: {
          type: 'individual',
          email: email,
          name: fullName,
          address: {
            type: 'billing',
            address_line_1: address_line1,
            address_line_2: address_line2,
            town_city: city,
            region: region,
            postal_code: post_code,
            country: country_code,
          },
          bank_account: acc_details,
        },
      }),
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const createVerificationPOST = (
  Constants,
  {
    acc_details,
    address_line1,
    address_line2,
    city,
    country_code,
    email,
    fullName,
    post_code,
    region,
  }
) =>
  createVerificationPOSTStatusAndText(Constants, {
    acc_details,
    address_line1,
    address_line2,
    city,
    country_code,
    email,
    fullName,
    post_code,
    region,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useCreateVerificationPOST = ({
  acc_details,
  address_line1,
  address_line2,
  city,
  country_code,
  email,
  fullName,
  post_code,
  region,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_verification',
        payload: {
          type: 'individual',
          email: email,
          name: fullName,
          address: {
            type: 'billing',
            address_line_1: address_line1,
            address_line_2: address_line2,
            town_city: city,
            region: region,
            postal_code: post_code,
            country: country_code,
          },
          bank_account: acc_details,
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchCreateVerificationPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  acc_details,
  address_line1,
  address_line2,
  city,
  country_code,
  email,
  fullName,
  post_code,
  region,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_verification',
        payload: {
          type: 'individual',
          email: email,
          name: fullName,
          address: {
            type: 'billing',
            address_line_1: address_line1,
            address_line_2: address_line2,
            town_city: city,
            region: region,
            postal_code: post_code,
            country: country_code,
          },
          bank_account: acc_details,
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateVerification: refetch });
};

export const createAParticipantPOSTStatusAndText = (
  Constants,
  { email, firstName, lastName, type, userId }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_participant',
        payload: {
          email: email,
          metadata: {
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            type: type,
          },
        },
      }),
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const createAParticipantPOST = (
  Constants,
  { email, firstName, lastName, type, userId }
) =>
  createAParticipantPOSTStatusAndText(Constants, {
    email,
    firstName,
    lastName,
    type,
    userId,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useCreateAParticipantPOST = ({
  email,
  firstName,
  lastName,
  type,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_participant',
        payload: {
          email: email,
          metadata: {
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            type: type,
          },
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchCreateAParticipantPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  firstName,
  lastName,
  type,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'create_participant',
        payload: {
          email: email,
          metadata: {
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            type: type,
          },
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateAParticipant: refetch });
};

export const createASendGridContactPOSTStatusAndText = (
  Constants,
  { action, contact, list_id }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.functions.supabase.co/sendgrid-utils`, {
    body: JSON.stringify({
      action: action,
      payload: {
        listId: list_id,
        contact: contact,
        custom_fields: { e1_T: 'provider' },
      },
    }),
    headers: {
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const createASendGridContactPOST = (
  Constants,
  { action, contact, list_id }
) =>
  createASendGridContactPOSTStatusAndText(Constants, {
    action,
    contact,
    list_id,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useCreateASendGridContactPOST = ({ action, contact, list_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/sendgrid-utils`,
    {
      body: JSON.stringify({
        action: action,
        payload: {
          listId: list_id,
          contact: contact,
          custom_fields: { e1_T: 'provider' },
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchCreateASendGridContactPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  action,
  contact,
  list_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/sendgrid-utils`,
    {
      body: JSON.stringify({
        action: action,
        payload: {
          listId: list_id,
          contact: contact,
          custom_fields: { e1_T: 'provider' },
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchCreateASendGridContact: refetch,
  });
};

export const getVerificationRequirementsPOSTStatusAndText = (
  Constants,
  { country_code, currency_code }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'get_verification_requirements',
        payload: { country_code: country_code, currency_code: currency_code },
      }),
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const getVerificationRequirementsPOST = (
  Constants,
  { country_code, currency_code }
) =>
  getVerificationRequirementsPOSTStatusAndText(Constants, {
    country_code,
    currency_code,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useGetVerificationRequirementsPOST = ({
  country_code,
  currency_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'get_verification_requirements',
        payload: { country_code: country_code, currency_code: currency_code },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchGetVerificationRequirementsPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  country_code,
  currency_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/trustshare-utils-handlers`,
    {
      body: JSON.stringify({
        action: 'get_verification_requirements',
        payload: { country_code: country_code, currency_code: currency_code },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetVerificationRequirements: refetch,
  });
};

export const requestPasswordResetPOSTStatusAndText = (Constants, { email }) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-admin-utils`,
    {
      body: JSON.stringify({
        action: 'request_password_reset',
        payload: {
          email: email,
          redirectUrl: 'http://www.cityasaschool.com/password-reset-redirect',
          destination: 'provider-web',
        },
      }),
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const requestPasswordResetPOST = (Constants, { email }) =>
  requestPasswordResetPOSTStatusAndText(Constants, { email }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const useRequestPasswordResetPOST = ({ email }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-admin-utils`,
    {
      body: JSON.stringify({
        action: 'request_password_reset',
        payload: {
          email: email,
          redirectUrl: 'http://www.cityasaschool.com/password-reset-redirect',
          destination: 'provider-web',
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
};

export const FetchRequestPasswordResetPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.functions.supabase.co/supabase-admin-utils`,
    {
      body: JSON.stringify({
        action: 'request_password_reset',
        payload: {
          email: email,
          redirectUrl: 'http://www.cityasaschool.com/password-reset-redirect',
          destination: 'provider-web',
        },
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchRequestPasswordReset: refetch,
  });
};
