import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const logoutPOSTStatusAndText = Constants =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const logoutPOST = Constants =>
  logoutPOSTStatusAndText(Constants).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useLogoutPOST = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });
};

export const FetchLogoutPOST = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLogout: refetch });
};

export const mEGETStatusAndText = Constants =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const mEGET = Constants =>
  mEGETStatusAndText(Constants).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useMEGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
  });
};

export const FetchMEGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchME: refetch });
};

export const providerEmail$PasswordLoginPOSTStatusAndText = (
  Constants,
  { email, password }
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  ).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const providerEmail$PasswordLoginPOST = (
  Constants,
  { email, password }
) =>
  providerEmail$PasswordLoginPOSTStatusAndText(Constants, {
    email,
    password,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useProviderEmail$PasswordLoginPOST = ({ email, password }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  );
};

export const FetchProviderEmail$PasswordLoginPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['API_KEY'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchProviderEmail$PasswordLogin: refetch,
  });
};

export const providerSignUpPOSTStatusAndText = (
  Constants,
  { email, firstName, handle, lastName, password }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      email: email,
      password: password,
      email_confirmed: true,
      data: {
        firstName: firstName,
        lastName: lastName,
        handle: handle,
        type: 'provider',
      },
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const providerSignUpPOST = (
  Constants,
  { email, firstName, handle, lastName, password }
) =>
  providerSignUpPOSTStatusAndText(Constants, {
    email,
    firstName,
    handle,
    lastName,
    password,
  }).then(({ status, statusText, text }) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error(
        [
          'Failed to parse response text as JSON.',
          `Error: ${e.message}`,
          `Text: ${JSON.stringify(text)}`,
        ].join('\n\n')
      );
    }
  });

export const useProviderSignUpPOST = ({
  email,
  firstName,
  handle,
  lastName,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      email: email,
      password: password,
      email_confirmed: true,
      data: {
        firstName: firstName,
        lastName: lastName,
        handle: handle,
        type: 'provider',
      },
    }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });
};

export const FetchProviderSignUpPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  firstName,
  handle,
  lastName,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      email: email,
      password: password,
      email_confirmed: true,
      data: {
        firstName: firstName,
        lastName: lastName,
        handle: handle,
        type: 'provider',
      },
    }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchProviderSignUp: refetch });
};

export const updateMEPUTStatusAndText = (Constants, { user_meta_data }) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    body: JSON.stringify({ data: user_meta_data }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'PUT',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updateMEPUT = (Constants, { user_meta_data }) =>
  updateMEPUTStatusAndText(Constants, { user_meta_data }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );

export const updatePasswordPUTStatusAndText = (
  Constants,
  { confirmPassword }
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    body: JSON.stringify({ password: confirmPassword }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_BEARER'],
      'Content-Type': 'application/json',
      apikey: Constants['API_KEY'],
    },
    method: 'PUT',
  }).then(async res => ({
    status: res.status,
    statusText: res.statusText,
    text: await res.text(),
  }));

export const updatePasswordPUT = (Constants, { confirmPassword }) =>
  updatePasswordPUTStatusAndText(Constants, { confirmPassword }).then(
    ({ status, statusText, text }) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        console.error(
          [
            'Failed to parse response text as JSON.',
            `Error: ${e.message}`,
            `Text: ${JSON.stringify(text)}`,
          ].join('\n\n')
        );
      }
    }
  );
