import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const ApplicationScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CURRENT_SCREEN',
        value: 'APPLICATION',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '100%',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Sidebar */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Sidebar'],
            dimensions.width
          )}
        >
          {/* Profile */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '100%' },
              dimensions.width
            )}
          >
            {/* Upload avatar */}
            <>
              {Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  source={{
                    uri: `${getNameAvatarUrl(Constants['USER_DATA'])}`,
                  }}
                  size={60}
                />
              )}
            </>
            <>
              {!Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  size={60}
                  source={{ uri: `${Constants['USER_DATA']?.profile_picture}` }}
                />
              )}
            </>
            {/* User Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 12 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['USER_DATA']?.firstName}{' '}
              {Constants['USER_DATA']?.lastName}
            </Text>
            <Spacer top={8} bottom={8} left={4} right={0} />
            {/* verified */}
            <>
              {!Constants['USER_DATA']?.is_verified ? null : (
                <Icon
                  size={24}
                  name={'MaterialIcons/verified'}
                  color={theme.colors['Primary']}
                />
              )}
            </>
          </View>
          {/* new Nav with matching icon */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Learning Center Nav 7'],
              dimensions.width
            )}
          >
            {/* Home */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'HOME',
                  });
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Application / Roles */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'APPLICATION',
                  });
                  navigation.navigate('ApplicationScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Inbox Item */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INBOX',
                  });
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* My Learning Experiences */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'MY LXP',
                  });
                  navigation.navigate('MyExperiencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Learning Centers */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'LEARNING_CENTERS',
                  });
                  navigation.navigate('LearningCentersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Insights */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INSIGHTS',
                  });
                  navigation.navigate('InsightsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Light'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Feedback */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/feature-requests'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/message-processing-outline'}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback'}
                </Text>
              </View>
            </Touchable>
            {/* Marketing Support Nav */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Marketing-Strategies-to-Grow-Your-Offerings-42c25ec6074d428194faf210e270d67a'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'AntDesign/adduser'} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Marketing Support'}
                </Text>
              </View>
            </Touchable>
            {/* Settings */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'SETTINGS',
                  });
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Primary 20%'],
                        },
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
        >
          <ScrollView
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            {/* Title */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 16, marginRight: 16, marginTop: 16, width: 704 },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 30,
                    fontWeight: '700',
                  },
                  dimensions.width
                )}
              >
                {'Welcome to City as a School'}
              </Text>
              <Divider
                style={StyleSheet.applyWidth(
                  {
                    height: 1,
                    marginBottom: 8,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 16,
                  },
                  dimensions.width
                )}
                color={theme.colors.greyLines}
              />
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                {
                  "Please follow the step-by-step guide to getting started. You can begin listing your classes now but CAS cannot promote your offerings until you're verified. \n\nThe whole process usually takes 2-3 weeks. When you're verified you'll receive a blue 'verified' mark next to your name."
                }
              </Text>
            </View>
            {/* Steps */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 16, marginRight: 16, marginTop: 40, width: 702 },
                dimensions.width
              )}
            >
              {/* Step 1 Apply */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    marginBottom: { minWidth: Breakpoints.Desktop, value: 32 },
                    marginTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/circle-slice-8'}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 16, width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontSize: 24,
                        fontWeight: '700',
                      },
                      dimensions.width
                    )}
                  >
                    {'1. Complete Your Teacher Profile'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        marginBottom: {
                          minWidth: Breakpoints.Desktop,
                          value: 16,
                        },
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'Fill out your application to teach with us. If you do not have your own zoom pro account please check the box in this form to receive a free one from CAS.'
                    }
                  </Text>
                  {/* Button Solid */}
                  <Button
                    onPress={() => {
                      try {
                        navigation.navigate('SettingsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 8,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 16,
                        textAlign: 'center',
                        width: 300,
                      },
                      dimensions.width
                    )}
                    title={'Complete Profile'}
                  />
                </View>
              </View>
              {/* Step 2 Background Check */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    marginBottom: { minWidth: Breakpoints.Desktop, value: 32 },
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 16 },
                      { minWidth: Breakpoints.Desktop, value: 0 },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/circle-slice-8'}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 16 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontSize: 24,
                        fontWeight: '700',
                      },
                      dimensions.width
                    )}
                  >
                    {'2. Background Check'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        marginBottom: 8,
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "We'll also need to do your background check. Once it's\ncomplete (usually a few days), you'll be able to join our\ncommunity of teachers."
                    }
                  </Text>

                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://cityasaschool.notion.site/Background-Check-Process-8565da787f5e45fbb7cd3c724e30fa7c'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <View>
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { color: theme.colors['Primary'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Read more about our background check process and policies here.'
                        }
                      </Text>
                    </View>
                  </Touchable>
                  {/* Button Solid */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://airtable.com/shrNL3wJKRFiY4bgj'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 8,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginTop: 16,
                        textAlign: 'center',
                        width: 300,
                      },
                      dimensions.width
                    )}
                    title={'Start'}
                  />
                </View>
              </View>
              {/* Step 3 List your first class */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 16 },
                      { minWidth: Breakpoints.Desktop, value: 0 },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/circle-slice-8'}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 16, width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontSize: 24,
                        fontWeight: '700',
                      },
                      dimensions.width
                    )}
                  >
                    {'3. List Your First Learning Experience '}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        marginBottom: [
                          { minWidth: Breakpoints.Desktop, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 16 },
                        ],
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "You're ready to submit your first learning experience listing! When you've created a new offering and you're verified, we'll promote your offerings across our network and mailing list."
                    }
                  </Text>
                  {/* Add New Experience Button */}
                  <Button
                    onPress={() => {
                      try {
                        navigation.navigate(
                          'CreateUpdateALearningExperienceScreen',
                          { mode: 'Create' }
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)[
                        'Add New Experience Button'
                      ],
                      dimensions.width
                    )}
                    icon={'AntDesign/plus'}
                    title={'Add New Experience'}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ApplicationScreen);
