import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseRestAPIApi from '../apis/SupabaseRestAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ChatList from '../custom-files/ChatList';
import * as CustomCode from '../custom-files/CustomCode';
import getNameAvatarUrl from '../global-functions/getNameAvatarUrl';
import getRelativeTime from '../global-functions/getRelativeTime';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Circle,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const InboxScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getFormattedTime = dateStr => {
    const dateObj = new Date(dateStr);

    return `${dateObj.getHours()}:${dateObj.getMinutes()}`;
  };

  const isMyMessage = (Variables, sender_id) => {
    // console.log(Variables.UUID, sender_id)
    return Variables.UUID === sender_id;
  };

  const checkResponseError = err => {
    if (err) {
      // the user is not logged
      props.navigation.navigate('LoginScreen');
      // stop
      return true;
    }
    return false;
  };

  const getSenderFullName = sender => {
    return `${sender.firstName} ${sender.lastName}`;
  };

  const sendingDisabled = () => {
    return !newMessage.length || sending;
  };

  const { theme } = props;
  const { navigation } = props;

  const supabaseRestAPIDirectMessageAStudentPOST =
    SupabaseRestAPIApi.useDirectMessageAStudentPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CURRENT_SCREEN',
        value: 'INBOX',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [chattingFullName, setChattingFullName] = React.useState('');
  const [chattingUsername, setChattingUsername] = React.useState('');
  const [chatting_student_id, setChatting_student_id] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const [viewingChannelId, setViewingChannelId] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
      hasSafeArea={false}
      scrollable={false}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            flex: 1,
            flexDirection: 'row',
            marginBottom: 20,
            marginLeft: 16,
            marginTop: 20,
          },
          dimensions.width
        )}
      >
        {/* Sidebar */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Sidebar'],
            dimensions.width
          )}
        >
          {/* Profile */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '100%' },
              dimensions.width
            )}
          >
            {/* Upload avatar */}
            <>
              {Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  source={{
                    uri: `${getNameAvatarUrl(Constants['USER_DATA'])}`,
                  }}
                  size={60}
                />
              )}
            </>
            <>
              {!Constants['USER_DATA']?.profile_picture ? null : (
                <CircleImage
                  style={StyleSheet.applyWidth(
                    { height: 84, width: 84 },
                    dimensions.width
                  )}
                  size={60}
                  source={{ uri: `${Constants['USER_DATA']?.profile_picture}` }}
                />
              )}
            </>
            {/* User Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                  {
                    color: theme.colors.strong,
                    fontFamily: 'System',
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Laptop, value: 12 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['USER_DATA']?.firstName}{' '}
              {Constants['USER_DATA']?.lastName}
            </Text>
            <Spacer top={8} bottom={8} left={0} right={4} />
            <>
              {!Constants['USER_DATA']?.is_verified ? null : (
                <Icon
                  size={24}
                  name={'MaterialIcons/verified'}
                  color={theme.colors['Primary']}
                />
              )}
            </>
          </View>
          {/* new Nav with matching icon */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Learning Center Nav 7'],
              dimensions.width
            )}
          >
            {/* Home */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'HOME',
                  });
                  navigation.navigate('HomeScreen_0FrGgFCx');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'HOME') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Application / Roles */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'APPLICATION',
                  });
                  navigation.navigate('ApplicationScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'APPLICATION') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CheckDecagramOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Application / Roles'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Inbox Item */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INBOX',
                  });
                  navigation.navigate('InboxScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INBOX') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.MessageBadgeOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Inbox'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* My Learning Experiences */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'MY LXP',
                  });
                  navigation.navigate('MyExperiencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'MY_LXP') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.LaptopAccount}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Learning Experiences'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Learning Centers */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'LEARNING_CENTERS',
                  });
                  navigation.navigate('LearningCentersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === undefined) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Primary 20%'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.HomeCityOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Learning Centers'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Insights */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'INSIGHTS',
                  });
                  navigation.navigate('InsightsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'INSIGHTS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: theme.colors['Light'],
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.GoogleAnalytics}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Insights'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
            {/* Feedback */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://city-as-a-school.canny.io/feature-requests'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/message-processing-outline'}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback'}
                </Text>
              </View>
            </Touchable>
            {/* Marketing Support Nav */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://cityasaschool.notion.site/Marketing-Strategies-to-Grow-Your-Offerings-42c25ec6074d428194faf210e270d67a'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* Unselected */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 2,
                    flexDirection: 'row',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'AntDesign/adduser'} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Inter Medium 500 16pt'],
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                        marginLeft: 8,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Marketing Support'}
                </Text>
              </View>
            </Touchable>
            {/* Settings */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'CURRENT_SCREEN',
                    value: 'SETTINGS',
                  });
                  navigation.navigate('SettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Unselected */}
              <>
                {!(Constants['CURRENT_SCREEN'] !== 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
              {/* Selected */}
              <>
                {!(Constants['CURRENT_SCREEN'] === 'SETTINGS') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Primary 20%'],
                        },
                        borderRadius: 2,
                        flexDirection: 'row',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['Menu Icon'],
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CogOutline}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'Inter Medium 500 16pt'
                          ],
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginLeft: 8,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Panel */}
        <View
          style={StyleSheet.applyWidth(
            {
              height: '100%',
              marginLeft: 20,
              marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
              width: [
                { minWidth: Breakpoints.Mobile, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '72%' },
                { minWidth: Breakpoints.Desktop, value: '73%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Title */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Inter_700Bold',
                  fontSize: 30,
                }),
                dimensions.width
              )}
            >
              {'Inbox'}
            </Text>
            <Divider
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'],
                  {
                    marginBottom: { minWidth: Breakpoints.Desktop, value: 16 },
                    marginTop: { minWidth: Breakpoints.Desktop, value: 16 },
                  }
                ),
                dimensions.width
              )}
              color={theme.colors.divider}
            />
          </View>
          {/* Row */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                flexDirection: 'row',
                paddingTop: 20,
                width: { minWidth: Breakpoints.Desktop, value: '90%' },
              },
              dimensions.width
            )}
          >
            {/* left Panel */}
            <ScrollView
              style={StyleSheet.applyWidth(
                {
                  height: [
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Desktop, value: '75%' },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: 520 },
                    { minWidth: Breakpoints.Desktop, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                  ],
                },
                dimensions.width
              )}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                    { minWidth: Breakpoints.Desktop, value: 'stretch' },
                  ],
                  paddingRight: { minWidth: Breakpoints.Desktop, value: 16 },
                },
                dimensions.width
              )}
              showsVerticalScrollIndicator={true}
              bounces={true}
            >
              {/* Summary */}
              <SupabaseRestAPIApi.FetchGetMessageSummaryGET>
                {({ loading, error, data, refetchGetMessageSummary }) => {
                  const summaryData = data;
                  if (!summaryData || loading) {
                    return <ActivityIndicator />;
                  }

                  if (error) {
                    return (
                      <Text style={{ textAlign: 'center' }}>
                        There was a problem fetching this data
                      </Text>
                    );
                  }

                  return (
                    <>
                      {/* Empty Frame */}
                      <>
                        {summaryData?.length ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                width: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 100,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 20,
                                  fontWeight: '700',
                                },
                                dimensions.width
                              )}
                            >
                              {'No Messages'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* People */}
                      <FlatList
                        renderItem={({ item }) => {
                          const peopleData = item;
                          return (
                            <Touchable
                              onPress={() => {
                                try {
                                  setChatting_student_id(
                                    peopleData?.learner_id
                                  );
                                  setChattingFullName(
                                    getSenderFullName(peopleData?.sender)
                                  );
                                  setChattingUsername(
                                    peopleData?.sender?.username
                                  );
                                  setViewingChannelId(
                                    peopleData?.target_channel_id
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  width: {
                                    minWidth: Breakpoints.Desktop,
                                    value: '100%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/*  Container */}
                              <>
                                {!(
                                  peopleData?.target_channel_id !==
                                  viewingChannelId
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors['Grey Lines'],
                                        flexDirection: 'row',
                                        paddingBottom: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        ],
                                        paddingLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 12,
                                          },
                                        ],
                                        paddingRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 12,
                                          },
                                        ],
                                        paddingTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        ],
                                        width: {
                                          minWidth: Breakpoints.Desktop,
                                          value: '100%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Circle
                                      size={50}
                                      bgColor={theme.colors.light}
                                    >
                                      <>
                                        {!peopleData?.sender
                                          ?.profile_picture ? null : (
                                          <Image
                                            style={StyleSheet.applyWidth(
                                              { height: '100%', width: '100%' },
                                              dimensions.width
                                            )}
                                            source={{
                                              uri: `${peopleData?.sender?.profile_picture}`,
                                            }}
                                            resizeMode={'cover'}
                                          />
                                        )}
                                      </>
                                      <>
                                        {peopleData?.sender
                                          ?.profile_picture ? null : (
                                          <Icon
                                            size={24}
                                            name={'Ionicons/person'}
                                            color={theme.colors['Custom Color']}
                                          />
                                        )}
                                      </>
                                    </Circle>
                                    {/* Content */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 12 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Caption Row */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Full Name */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'Inter_500Medium',
                                              fontSize: 14,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {getSenderFullName(
                                            peopleData?.sender
                                          )}
                                        </Text>
                                        {/* username ago */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                fontFamily: 'Inter_400Regular',
                                                marginLeft: 4,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'@'}
                                          {peopleData?.sender?.username}
                                          {' · '}
                                          {getRelativeTime(
                                            peopleData?.message_sent_at
                                          )}
                                        </Text>
                                      </View>
                                      {/* last message */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Inter_400Regular',
                                              marginTop: 8,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {peopleData?.last_message}
                                      </Text>
                                    </View>
                                  </View>
                                )}
                              </>
                              {/*  Container Selected */}
                              <>
                                {!(
                                  peopleData?.target_channel_id ===
                                  viewingChannelId
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 2,
                                        borderColor: theme.colors['Primary'],
                                        flexDirection: 'row',
                                        paddingBottom: 18,
                                        paddingLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 12,
                                          },
                                        ],
                                        paddingRight: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 12,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                        ],
                                        paddingTop: 18,
                                        width: {
                                          minWidth: Breakpoints.Desktop,
                                          value: '100%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Circle
                                      size={50}
                                      bgColor={theme.colors.light}
                                    >
                                      <>
                                        {!peopleData?.sender
                                          ?.profile_picture ? null : (
                                          <Image
                                            style={StyleSheet.applyWidth(
                                              { height: '100%', width: '100%' },
                                              dimensions.width
                                            )}
                                            source={{
                                              uri: `${peopleData?.sender?.profile_picture}`,
                                            }}
                                            resizeMode={'cover'}
                                          />
                                        )}
                                      </>
                                      <>
                                        {peopleData?.sender
                                          ?.profile_picture ? null : (
                                          <Icon
                                            size={24}
                                            name={'Ionicons/person'}
                                            color={theme.colors['Custom Color']}
                                          />
                                        )}
                                      </>
                                    </Circle>
                                    {/* Content */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 12 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Caption Row */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Full Name */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'Inter_500Medium',
                                              fontSize: 14,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {getSenderFullName(
                                            peopleData?.sender
                                          )}
                                        </Text>
                                        {/* username ago */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              { marginLeft: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'@'}
                                          {peopleData?.sender?.username}
                                          {' · '}
                                          {getRelativeTime(
                                            peopleData?.message_sent_at
                                          )}
                                        </Text>
                                      </View>
                                      {/* last message */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            { marginTop: 8 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {peopleData?.last_message}
                                      </Text>
                                    </View>
                                  </View>
                                )}
                              </>
                            </Touchable>
                          );
                        }}
                        data={summaryData}
                        listKey={'f6PJ9Oja'}
                        keyExtractor={peopleData =>
                          peopleData?.target_channel_id
                        }
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                        horizontal={false}
                        numColumns={3}
                      />
                    </>
                  );
                }}
              </SupabaseRestAPIApi.FetchGetMessageSummaryGET>
            </ScrollView>
            {/* right Panel */}
            <>
              {!viewingChannelId ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 2,
                      height: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Desktop, value: '75%' },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  <Divider
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'],
                        {
                          width: {
                            minWidth: Breakpoints.Desktop,
                            value: '100%',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    color={theme.colors['Background']}
                  />
                  <KeyboardAwareScrollView
                    contentContainerStyle={StyleSheet.applyWidth(
                      {
                        paddingBottom: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 16,
                      },
                      dimensions.width
                    )}
                    showsVerticalScrollIndicator={true}
                    keyboardShouldPersistTaps={'never'}
                  >
                    {/* Messages */}
                    <SupabaseRestAPIApi.FetchGetTeacherStudentChatsGET
                      refetchInterval={3000}
                      channel_id={viewingChannelId}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetTeacherStudentChats,
                      }) => {
                        const messagesData = data;
                        if (!messagesData || loading) {
                          return <ActivityIndicator />;
                        }

                        if (error) {
                          return (
                            <Text style={{ textAlign: 'center' }}>
                              There was a problem fetching this data
                            </Text>
                          );
                        }

                        return (
                          <Utils.CustomCodeErrorBoundary>
                            <ChatList.Index
                              fetchData={messagesData}
                              Variables={Variables}
                              isMyMessage={isMyMessage}
                              getFormattedTime={getFormattedTime}
                              theme={props.theme}
                              senderFullName={chattingFullName}
                            />
                          </Utils.CustomCodeErrorBoundary>
                        );
                      }}
                    </SupabaseRestAPIApi.FetchGetTeacherStudentChatsGET>
                  </KeyboardAwareScrollView>
                  {/* Chat bar */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderColor: {
                          minWidth: Breakpoints.Desktop,
                          value: theme.colors['Grey Lines'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Desktop,
                          value: 12,
                        },
                        borderWidth: {
                          minWidth: Breakpoints.Desktop,
                          value: 1,
                        },
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 18,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setNewMessage(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input'],
                            {
                              backgroundColor: theme.colors['Divider'],
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'System',
                              },
                              fontWeight: {
                                minWidth: Breakpoints.Desktop,
                                value: '400',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        value={newMessage}
                        placeholder={' '}
                        autoCapitalize={'none'}
                        placeholderTextColor={theme.colors['Inactive Grey']}
                      />
                    </View>

                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setSending(true);
                            await supabaseRestAPIDirectMessageAStudentPOST.mutateAsync(
                              {
                                content: newMessage,
                                student_id: chatting_student_id,
                              }
                            );
                            setNewMessage('');
                            setSending(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { marginLeft: 16 },
                        dimensions.width
                      )}
                      disabled={sendingDisabled()}
                    >
                      <Icon
                        name={'MaterialCommunityIcons/send-circle-outline'}
                        size={40}
                        color={theme.colors['Primary']}
                      />
                    </Touchable>
                  </View>
                </View>
              )}
            </>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(InboxScreen);
